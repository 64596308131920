<template>
  <b-modal
    ref="delegateModal"
    :title="$t('delegate.title') + ':'"
    @ok.prevent="submitDelegate"
    @shown="createDelegateFormValidationContext"
    @hidden="destroyDelegateFormValidationContext"
  >
    <ValidationObserver ref="observer" slim>
      <b-form>
        <template v-if="contactsList && contactsList.length > 0">
          <b-form-group :label="$t('delegate.existing')">
            <multiselect
              v-model="selectedContact"
              :options="contactsList"
              track-by="email"
            >
              <template #option="{ option }">
                {{ option.first_name }} {{ option.last_name }}
                <br />
                <small class="text-muted">{{ option.email }}</small>
              </template>
              <template #singleLabel="{ option }">
                {{ option.first_name }} {{ option.last_name }}
                <br />
                <small class="text-muted">{{ option.email }}</small>
              </template>
            </multiselect>
          </b-form-group>
        </template>
        <template v-if="!selectedContact">
          <p v-if="contactsList && contactsList.length > 0" class="text-muted">
            - {{ $t('or') }} -
          </p>
          <b-form-row>
            <validating-group
              class="col-sm"
              :validation-context="validationContext"
              validation-path="first_name"
              :label="$t('first-name')"
              v-slot="{ state }"
            >
              <b-input v-model="form.first_name" :state="state" />
            </validating-group>
            <validating-group
              class="col-sm"
              :validation-context="validationContext"
              validation-path="last_name"
              :label="$t('last-name')"
              rules="required"
              v-slot="{ state }"
            >
              <b-input v-model="form.last_name" :state="state" />
            </validating-group>
          </b-form-row>
          <validating-group
            :validation-context="validationContext"
            validation-path="email"
            :label="$t('e-mail')"
            rules="required|email"
            v-slot="{ state }"
          >
            <b-input v-model="form.email" type="email" :state="state" />
          </validating-group>
        </template>
        <validating-group
          validation-label="Qualifications"
          :validation-context="validationContext"
          validation-path="qualifications"
          :rules="{ required: { allowFalse: false } }"
          v-slot="{ state }"
        >
          <b-form-checkbox :state="state" v-model="form.qualifications">
            {{ $t('delegate.confirmation') }}
          </b-form-checkbox>
        </validating-group>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import axios from 'axios';

import {
  CONTEXT_CREATE,
  CONTEXT_DESTROY,
  STORE_API_ERRORS,
} from '@gid/vue-common/store/validation.module/types';
import { filterNonInputErrors } from '@gid/vue-common/store/validation.module/utils';

const emptyData = {
  first_name: null,
  last_name: null,
  email: null,
  qualifications: false,
};
export default {
  data() {
    return {
      order_sfid: null,
      validationContext: 'delegate',
      contactsList: null,
      selectedContact: null,
      form: {
        ...emptyData,
      },
    };
  },
  methods: {
    show(order_sfid) {
      this.order_sfid = order_sfid;
      this.$refs.delegateModal.show();
    },
    submitDelegate() {
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          const data = this.selectedContact
            ? {
                ...this.selectedContact,
                qualifications: true,
              }
            : this.form;
          axios
            .post(`/api/partner/orders/${this.order_sfid}/delegate`, data)
            .then(() => {
              this.$refs.delegateModal.hide();
              this.$bvToast.toast(this.$t('delegate.success'), {
                title: this.$t('successful'),
                variant: 'success',
              });
              this.$emit('success');
            })
            .catch((error) => {
              let showError = true;
              if (error.response.status == 422) {
                this.$store.dispatch(`validation/${STORE_API_ERRORS}`, {
                  context: this.validationContext,
                  errors: error.response.data.errors,
                });
                const nonInputErrors = filterNonInputErrors(
                  error.response.data.errors,
                  Object.keys(this.form),
                );
                showError = Object.keys(nonInputErrors).length > 0;
              }

              if (showError) {
                this.$bvToast.toast(this.$t('delegate.error'), {
                  title: this.$t('_errors.general.title'),
                  variant: 'danger',
                });
              }
            });
        }
      });
    },
    createDelegateFormValidationContext() {
      this.fetchContacts();
      this.$store.dispatch(`validation/${CONTEXT_CREATE}`, {
        context: this.validationContext,
      });
    },
    destroyDelegateFormValidationContext() {
      this.form = { ...emptyData };
      this.selectedContact = null;
      this.$store.dispatch(`validation/${CONTEXT_DESTROY}`, {
        context: this.validationContext,
      });
    },
    fetchContacts() {
      axios
        .get(`/api/partner/account/contacts/for-job/${this.order_sfid}`)
        .then((response) => {
          this.contactsList = response.data
            .filter(({ email }) => email)
            .map(({ first_name, last_name, email }) => ({
              first_name,
              last_name,
              email,
            }));
        });
    },
  },
};
</script>
