import axios from 'axios';
import { COUNTS_REFRESH } from '@/store/sidebar.module';

export default {
  methods: {
    async acceptOffer(offer, date, accountId, toggleLoading) {
      let new_status;
      let job_sfid = offer.job.sfid;
      let inputs = {};
      if (date) {
        inputs['appointment_date'] = {
          start: date.start,
          end: date.end,
        };
        new_status = 'appointment confirmed';
      } else {
        new_status = 'accepted';
      }

      inputs.on_behalf_of_partner_id = accountId;
      toggleLoading();
      try {
        const response = await axios.post(`/api/partner/orders/set-status`, {
          preview: false,
          job_id: job_sfid,
          inputs,
          new_status,
        });

        let successMessage;
        if (date) {
          successMessage = response.data.__notifications_sent.any
            ? this.$t('accept-offer-success')
            : this.$t('accept-offer-success-no-email');
        } else {
          successMessage = this.$t('accept-offer-success-appointment', {
            link: `/order/${job_sfid}/`,
          });
        }
        this.successList.push(successMessage);
        if (this.offers) {
          this.offers = this.offers.filter(
            (offer) => offer.job.sfid != job_sfid,
          );
        }
        this.gaTrack(offer, date);
        this.$store.dispatch(COUNTS_REFRESH);
      } catch (e) {
        this.errorsList.push(this.$t('accept-offer-error'));
      }
      toggleLoading();
      this.shouldDisplayMessages = true;
      this.$refs.additionalOffers.init(job_sfid);
    },
    gaTrack(offer, date) {
      const dateCreated = this.$moment(offer.job.created_date);
      let dateCompare;

      if (date) {
        dateCompare = this.$moment(date.start);
        this.$gtag.event('Accept with appointment date', {
          event_category: 'Offer acceptance',
          event_label: dateCompare.diff(dateCreated, 'days'),
        });
      } else {
        dateCompare = this.$moment();
        this.$gtag.event('Accept without appointment date', {
          event_category: 'Offer acceptance',
          event_label: dateCompare.diff(dateCreated, 'days'),
        });
      }
    },
  },
};
