<template>
  <div id="app">
    <component :is="layout">
      <b-spinner v-if="hasAccess === null" variant="secondary" class="m-2" />
      <keep-alive v-else-if="hasAccess" include="orders">
        <router-view />
      </keep-alive>
      <div v-else class="text-center">
        <h4 class="m-5">{{ $t('no-access.title') }}</h4>
        <p>{{ $t('no-access.description') }}</p>
      </div>
    </component>

    <add-to-home-screen app-name="GID Partner" />
    <offline />
  </div>
</template>

<script>
import { checkRouteAccess } from '@gid/vue-common/store/auth.module';
import Auth from '@/views/layouts/Auth.vue';
import NoAuth from '@/views/layouts/NoAuth.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    'auth-layout': Auth,
    'no-auth-layout': NoAuth,
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'user']),
    layout() {
      const matchedRoutes = this.$route.matched.slice(0).reverse();
      const routeWithLayout = matchedRoutes.find((route) => route.meta.layout);
      return (
        (routeWithLayout ? routeWithLayout.meta.layout : 'auth') + '-layout'
      );
    },
    hasAccess() {
      return checkRouteAccess({
        route: this.$route,
        user: this.user,
        isAuthenticated: this.isAuthenticated,
      });
    },
  },
  data() {
    return {
      publishResizeEvents: false,
    };
  },
  methods: {
    handleRequest() {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      this.publishResizeEvents = params.get('resize');
    },
  },
  mounted() {
    if (this.publishResizeEvents) {
      let resizeScript = document.createElement('script');
      resizeScript.setAttribute(
        'src',
        'https://order-intake-forms.getitdone.rocks/js/iframeResizer.contentWindow.min.js',
      );
      document.head.appendChild(resizeScript);
    }
  },
  created() {
    this.handleRequest();
  },
};
</script>
