import moment from 'moment';
import { Module } from 'vuex-module-decorators';
import {
  CrudList,
  CrudListActionsEnum,
  CrudListFilters,
} from '@gid/vue-common/store/shared/crud-list.module';
import { AccountingDocument } from '@gid/models';

export interface CreditNoteFilters extends CrudListFilters {
  dateFrom: string | null;
  dateTo: string | null;
  docType: string;
  docNumber: string | null;
  targetType: string;
  status: string;
  search: string;
}

export const CreditNotesActionsEnum = { ...CrudListActionsEnum };

const moduleName = 'creditNotes';
@Module({ name: moduleName, namespaced: true })
export class CreditNotesModule extends CrudList<AccountingDocument> {
  crudEndpoint: string = '/documents-api/accounting/documents';
  filters: CreditNoteFilters = {
    skipPersist: false,
    dateFrom: null,
    dateTo: null,
    docNumber: null,
    status: '',
    docType: 'credit_note',
    targetType: 'partner',
    search: '',
  };
  pagination = {
    skipPersist: false,
    totalRows: 0,
    perPage: 25,
    currentPage: 1,
    currentPageCount: 0,
    numberOfPages: 1,
    sortBy: 'issueDate',
    sortDir: true,
  };

  store?: {
    getters?: {
      access_token?: string;
    };
  };

  get lastError(): any {
    return this.error ?? null;
  }
  get allItems(): AccountingDocument[] {
    return Object.values(this.items);
  }

  get currentPageItems(): AccountingDocument[] {
    return this.currentPage;
  }
}
export default CreditNotesModule;
