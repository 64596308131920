<template>
  <div class="page-container">
    <div class="mx-auto">
      <b-row class="justify-content-center">
        <b-col md="10" xl="5">
          <p class="paragraph font-weight-bold">
            Vielen Dank für Ihre Rückmeldung. Wir haben Sie in unserer Datenbank
            deaktiviert.
          </p>
          <p class="font-weight-bold paragraph">
            Sollten Sie jedoch lediglich für ein falsches Gewerk kontaktiert
            worden sein, bitte helfen Sie uns, es nächstes mal besser zu machen,
            indem Sie unsere Daten zu Ihrem Unternehmen aktualisieren.
          </p>
          <p class="font-weight-bold paragraph">
            Sie werden hiermit dann lediglich für Aufträge aus diesem/n
            Bereich/en kontaktiert
          </p>
          <p class="font-weight-bold paragraph">
            Hinterlegen Sie Ihre Qualifikationen
          </p>
          <b-row>
            <b-col md="6">
              <multiselect
                placeholder="Qualifizierung(en) auswählen"
                multiple
                v-model="selectedQualifications"
                :options="hwoAQualifications"
              />
            </b-col>
            <b-col style="margin: 10px 0" cols="12" class="d-flex">
              <b-button
                :disabled="selectedQualifications.length === 0"
                variant="primary"
                @click="updateQualifications"
              >
                Speichern
              </b-button>
              <b-icon-check
                v-if="qualificationUpdated"
                class="h1 m-0"
                style="color: #04a789"
              ></b-icon-check>
            </b-col>
          </b-row>

          <p class="font-weight-bold paragraph">
            Wollen Sie sich schonmal einen Zugang anlegen?
          </p>
          <partner-form
            v-if="refresh_token"
            :refresh_token="refresh_token"
            redirect_path="offers"
            @error="showError"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import PartnerForm from '@/components/PartnerAcceptanceForm.vue';
import { GENERATE_ACCESS_TOKEN } from '@gid/vue-common/store/auth.module/types';
import { AccountStatusEnum } from '@gid/models';
import axios from 'axios';
import { BIconCheck } from 'bootstrap-vue';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    BIconCheck,
    PartnerForm,
  },
  data() {
    return {
      selectedQualifications: [],
      hwoAQualifications: [],
      currentQualifications: null,
      refresh_token: null,
      qualificationUpdated: false,
    };
  },
  async mounted() {
    if (this.$route.query.refresh_token) {
      try {
        this.refresh_token = this.$route.query.refresh_token;
        await this.$store.dispatch(GENERATE_ACCESS_TOKEN, {
          refresh_token: this.$route.query.refresh_token,
        });

        await this.fetchAllQualifications();
        await this.fetchCurrentQualifications();
        await this.updateAccount({ status: AccountStatusEnum.NOT_INTERESTED });
      } catch (error) {
        this.showError('Something went wrong');
      }
    }
  },
  methods: {
    async fetchAllQualifications() {
      try {
        const { data } = await axios.get(
          '/api/partner/qualifications/all-values/',
        );

        if (!data['HwO_A'] || data['HwO_A'].length === 0)
          throw new Error('No HwO_A qualifications found');

        this.hwoAQualifications = data['HwO_A'].filter(
          (q) => !q.includes('keine'),
        );
      } catch (error) {
        this.showError(
          error?.response?.data?.msg ||
            error?.response?.data ||
            error?.response?.statusText,
        );
      }
    },
    async fetchCurrentQualifications() {
      try {
        const { data } = await axios.get('/api/partner/current-qualifications');

        this.selectedQualifications = data['hwo_a'];
        this.currentQualifications = data;
      } catch (error) {
        this.showError(
          error?.response?.data?.msg ||
            error?.response?.data ||
            error?.response?.statusText,
        );
      }
    },
    async updateQualifications() {
      try {
        await axios.post('/api/partner/qualifications/', {
          ...this.currentQualifications,
          hwo_a: this.selectedQualifications,
        });
        await this.updateAccount({ status: AccountStatusEnum.NEW });
        this.qualificationUpdated = true;
      } catch (error) {
        this.showError(
          error?.response?.data?.msg ||
            error?.response?.data ||
            error?.response?.statusText,
        );
      }
    },
    async updateAccount(payload) {
      try {
        await axios.patch(
          `/data-api/account/${this.$store.state.auth.user.account.id}`,
          payload,
        );
      } catch (error) {
        this.showError(
          error?.response?.data?.msg ||
            error?.response?.data ||
            error?.response?.statusText,
        );
      }
    },
    showError(error) {
      this.$bvToast.toast(error, {
        title: 'Error',
        variant: 'danger',
        noAutoHide: false,
      });
    },
  },
};
</script>

<style>
.page-container {
  max-width: 1440px;
  margin: auto;
  margin-top: 20px;
}
.paragraph {
  font-size: 18px;
}

@media screen and (max-width: 460px) {
  .paragraph {
    font-size: 16px;
  }
}
</style>
