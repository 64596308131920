<template>
  <div>
    <b-modal
      no-fade
      :visible="visible"
      modal-class="gid-onboarding-modal summary-modal"
      centered
      size="lg"
      @hide="onDialogHide"
    >
      <template #modal-header>
        <button :disabled="loading" class="close-btn" @click="$emit('cancel')">
          <BIconX />
        </button>
        <p class="title">{{ $t('onboarding.summary-step.title') }}</p>
        <p class="subtitle">
          {{ $t('onboarding.summary-step.subtitle') }}
        </p>
      </template>
      <div v-if="form[OnboardingStepEnum.QUALIFICATIONS]" class="data-section">
        <div class="title-container">
          <p class="title">{{ $t('onboarding.qualifications-step.title') }}</p>
          <button
            class="edit-btn"
            @click="$emit('edit', OnboardingStepEnum.QUALIFICATIONS)"
          >
            <BIconPencil />
          </button>
        </div>
        <div class="content-container">
          <div class="content-field">
            <p class="field-label">
              {{ $t('onboarding.qualifications-step.hwo-a') }}
            </p>
            <div
              v-if="form[OnboardingStepEnum.QUALIFICATIONS].hwoA.length > 0"
              class="chips-container"
            >
              <b-button
                v-for="(q, index) in form[OnboardingStepEnum.QUALIFICATIONS]
                  .hwoA"
                :key="index"
                size="sm"
                variant="primary"
                class="gid-btn gid-btn--primary chip-btn"
              >
                {{ q }}
              </b-button>
            </div>
            <p v-else class="no-data text-muted">
              {{ $t('onboarding.no-data') }}
            </p>
          </div>
          <div class="content-field">
            <p class="field-label">
              {{ $t('onboarding.qualifications-step.installer-id') }}
            </p>
            <p
              v-if="form[OnboardingStepEnum.QUALIFICATIONS].installerIdNumber"
              class="text-data"
            >
              {{ form[OnboardingStepEnum.QUALIFICATIONS].installerIdNumber }}
            </p>
            <p v-else class="no-data text-muted">
              {{ $t('onboarding.no-data') }}
            </p>
          </div>
          <div class="content-field">
            <p class="field-label">
              {{
                $t('onboarding.qualifications-step.register-network-operator')
              }}
            </p>
            <p
              v-if="form[OnboardingStepEnum.QUALIFICATIONS].networkOperator"
              class="text-data"
            >
              {{ form[OnboardingStepEnum.QUALIFICATIONS].networkOperator }}
            </p>
            <p v-else class="no-data text-muted">
              {{ $t('onboarding.no-data') }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="form[OnboardingStepEnum.ADDRESS]" class="data-section">
        <div class="title-container">
          <p class="title">{{ $t('onboarding.address-step.title') }}</p>
          <button
            class="edit-btn"
            @click="$emit('edit', OnboardingStepEnum.ADDRESS)"
          >
            <BIconPencil />
          </button>
        </div>
        <div class="content-container">
          <div class="content-field">
            <div v-if="form[OnboardingStepEnum.ADDRESS].street">
              <p class="text-data">
                {{ form[OnboardingStepEnum.ADDRESS].street }}
              </p>
              <p class="text-data">
                {{ form[OnboardingStepEnum.ADDRESS].postalCode }}
              </p>
              <p class="text-data">
                {{ form[OnboardingStepEnum.ADDRESS].city }}
              </p>
              <p class="text-data">{{ $t('country.germany') }}</p>
            </div>
            <p v-else class="no-data text-muted">
              {{ $t('onboarding.no-data') }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="form[OnboardingStepEnum.VEHICLE]" class="data-section">
        <div class="title-container">
          <p class="title">{{ $t('onboarding.vehicle-step.title') }}</p>
          <button
            class="edit-btn"
            @click="$emit('edit', OnboardingStepEnum.VEHICLE)"
          >
            <BIconPencil />
          </button>
        </div>
        <div class="content-container">
          <div class="content-field">
            <div
              v-if="form[OnboardingStepEnum.VEHICLE].transporter.length > 0"
              class="chips-container"
            >
              <b-button
                v-for="(q, index) in form[OnboardingStepEnum.VEHICLE]
                  .transporter"
                :key="index"
                size="sm"
                variant="primary"
                class="gid-btn gid-btn--primary chip-btn"
              >
                {{ q }}
              </b-button>
            </div>
            <p v-else class="no-data text-muted">
              {{ $t('onboarding.no-data') }}
            </p>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <b-button
          :disabled="loading"
          @click="$emit('prev')"
          variant="outline-light"
          class="gid-btn gid-btn--ghost"
        >
          {{ $t('onboarding.actions.back') }}
        </b-button>
        <b-button
          :disabled="loading"
          @click="$emit('submit')"
          variant="primary"
          class="gid-btn gid-btn--primary"
        >
          <b-spinner v-if="loading" small></b-spinner>
          {{ $t('onboarding.actions.submit') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BIconX, BIconPencil } from 'bootstrap-vue';
import { OnboardingStepEnum } from '@gid/models';

export default {
  components: {
    BIconX,
    BIconPencil,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      OnboardingStepEnum,
    };
  },
  methods: {
    onDialogHide(bvModalEvent) {
      if (bvModalEvent.trigger === 'backdrop') {
        bvModalEvent.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '@gid/vue-common/scss/_variables.scss';

::v-deep .summary-modal {
  .modal-content {
    .modal-header {
      margin-bottom: 1.3rem;

      .subtitle {
        max-width: 27rem;
      }
    }

    .modal-body {
      padding: 0 1.6rem;

      .data-section {
        border-bottom: 2px solid $gray-300;
        padding-bottom: 1.3rem;
        margin-bottom: 1.2rem;

        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;
        }

        .title-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.9rem;

          .title {
            font-weight: 500;
            font-size: 1.1rem;
            letter-spacing: 0.3rem;
            color: $primary-new;
            text-transform: uppercase;
            margin: 0;
          }

          .edit-btn {
            all: unset;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.1rem;
            cursor: pointer;
            color: $text-muted;
          }
        }

        .content-container {
          display: flex;
          flex-direction: column;
          gap: 1.3rem;

          .content-field {
            .field-label {
              color: $primary-new;
              margin-bottom: 0.3rem;
              font-weight: 400;
            }
          }

          .no-data {
            font-size: 1rem;
            margin: 0;
          }

          .chips-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 0.5rem;
          }

          .text-data {
            margin: 0;
          }
        }
      }
    }

    .modal-footer {
      margin-top: 1.3rem;
    }
  }
}
</style>
