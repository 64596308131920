<template>
  <div>
    <b-modal
      no-fade
      :visible="visible"
      content-class="qm-content"
      modal-class="gid-onboarding-modal qualifications-modal"
      centered
      size="lg"
      @hide="onDialogHide"
    >
      <template #modal-header>
        <button type="button" class="close-btn" @click="$emit('cancel')">
          <BIconX />
        </button>
        <p class="step-progress">
          {{
            $t('onboarding.progress', {
              step: stepIndex + 1,
              total: stepsCount,
            })
          }}
        </p>
        <p class="title">{{ $t('onboarding.qualifications-step.title') }}</p>
        <p class="subtitle">
          {{ $t('onboarding.qualifications-step.subtitle') }}
        </p>
      </template>
      <validation-observer ref="observer" slim v-slot="{ handleSubmit }">
        <b-form
          id="qualificationsForm"
          @submit.prevent="handleSubmit(validateAndNext)"
        >
          <validation-provider
            :name="$t('onboarding.qualifications-step.hwo-a')"
            rules=""
            v-slot="validationContext"
          >
            <b-form-group
              :label="$t('onboarding.qualifications-step.hwo-a')"
              label-class="field-label"
              label-cols-sm="3"
              :invalid-feedback="validationContext.errors[0]"
            >
              <multiselect
                :placeholder="$t('please-select')"
                multiple
                v-model="form.hwoA"
                :options="hwoAQualifications"
              />
            </b-form-group>
          </validation-provider>
          <b-form-group
            v-if="quickPickhwoA.length || loadingQualifications"
            :label="`${$t('quick-selection')}:`"
            label-class="qp-label"
            class="quick-pick-group"
            label-size="sm"
          >
            <template v-if="!loadingQualifications">
              <b-button
                v-for="(q, index) in quickPickhwoA"
                :key="index"
                size="sm"
                type="button"
                variant="primary"
                class="gid-btn gid-btn--primary chip-btn"
                @click="!form.hwoA.includes(q) && form.hwoA.push(q)"
              >
                {{ q }}
                <BIconPlus class="chip-icon" />
              </b-button>
            </template>
            <template v-else>
              <b-skeleton class="chip-skeleton" width="30%"></b-skeleton>
              <b-skeleton class="chip-skeleton" width="30%"></b-skeleton>
              <b-skeleton class="chip-skeleton" width="30%"></b-skeleton>
            </template>
          </b-form-group>
          <validation-provider
            :name="$t('onboarding.qualifications-step.installer-id')"
            rules=""
            v-slot="validationContext"
          >
            <b-form-group
              label-class="field-label"
              :label="$t('onboarding.qualifications-step.installer-id')"
              :invalid-feedback="validationContext.errors[0]"
            >
              <b-form-input
                trim
                v-model="form.installerIdNumber"
                @update="form.installerIdNumber = $event || null"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>
          <validation-provider
            :name="
              $t('onboarding.qualifications-step.register-network-operator')
            "
            rules=""
            v-slot="validationContext"
          >
            <b-form-group
              label-class="field-label"
              :label="
                $t('onboarding.qualifications-step.register-network-operator')
              "
              :invalid-feedback="validationContext.errors[0]"
            >
              <b-form-input
                trim
                v-model="form.networkOperator"
                @update="form.networkOperator = $event || null"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>

      <p class="description mt-4 text-muted skip-notice">
        {{ $t('onboarding.qualifications-step.skip-notice') }}
      </p>
      <template #modal-footer>
        <b-button
          type="button"
          @click="$emit('prev')"
          variant="outline-light"
          class="gid-btn gid-btn--ghost"
        >
          {{ $t('onboarding.actions.back') }}
        </b-button>
        <b-button
          form="qualificationsForm"
          type="submit"
          variant="primary"
          class="gid-btn gid-btn--primary"
        >
          {{ $t('onboarding.actions.continue') }}
        </b-button>
        <b-button
          type="button"
          @click="$emit('skip')"
          variant="outline-secondary"
          class="gid-btn"
        >
          {{ $t('onboarding.actions.skip') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { BIconX, BIconPlus } from 'bootstrap-vue';
import Multiselect from 'vue-multiselect';
import { handleFetchError } from '@gid/vue-common/utils';

export default {
  components: {
    BIconX,
    BIconPlus,
    Multiselect,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    stepIndex: Number,
    stepsCount: Number,
  },
  data() {
    return {
      loadingQualifications: false,
      hwoAQualifications: [],
    };
  },
  async created() {
    await this.fetchQualifications();
  },
  computed: {
    quickPickhwoA() {
      return [
        'Elektrotechniker',
        'Elektrisch unterwiesene Person',
        'Elektromaschinenbauer',
      ].filter((q) => this.hwoAQualifications.includes(q));
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      if (dirty || validated) {
        return valid ? null : false;
      }
      return null;
    },
    onDialogHide(bvModalEvent) {
      if (bvModalEvent.trigger === 'backdrop') {
        bvModalEvent.preventDefault();
      }
    },
    validateAndNext() {
      this.$emit('next');
    },
    async fetchQualifications() {
      try {
        this.loadingQualifications = true;

        const { data } = await axios.get(
          '/api/partner/qualifications/all-values/',
        );

        if (!data['HwO_A'] || data['HwO_A'].length === 0)
          throw new Error('No HwO_A qualifications found');

        this.hwoAQualifications = data['HwO_A'].filter(
          (q) => !q.includes('keine'),
        );
      } catch (error) {
        this.$bvToast.toast(
          handleFetchError(error) || this.$t('_errors.general.title'),
          {
            title: this.$t('_errors.general.title'),
            variant: 'danger',
            solid: true,
          },
        );
      } finally {
        this.loadingQualifications = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '@gid/vue-common/scss/_variables.scss';

::v-deep .qualifications-modal {
  .modal-content {
    .modal-header {
      .subtitle {
        max-width: 29rem;
      }
    }
  }
}
</style>
