<template>
  <ValidationObserver ref="observer" slim>
    <b-form @submit.prevent="submitForm">
      <b-row>
        <validating-group
          class="validating-group col-12 col-md-6"
          label="Vorname *"
          validation-label="Vorname"
          v-slot="{ clearErrors, state }"
          :validation-context="validation_context"
          rules="required"
        >
          <b-form-input
            class="m-auto rounded-3"
            v-model="form.vorname"
            type="text"
            name="Vorname"
            @input="form.vorname === '' && clearErrors(true)"
            :state="state"
          />
        </validating-group>
        <validating-group
          class="validating-group col-12 col-md-6"
          label="Nachname *"
          validation-label="Nachname"
          :validation-context="validation_context"
          v-slot="{ state }"
          rules="required"
        >
          <b-form-input
            class="m-auto rounded-3"
            v-model="form.nachname"
            type="text"
            name="Nachname"
            :state="state"
          />
        </validating-group>
      </b-row>
      <b-row>
        <validating-group
          class="validating-group col-12 col-md-10"
          label="eMail Adresse (sofern unterschiedlich von der Firmen email)"
          validation-label="eMail Adresse"
          :validation-context="validation_context"
          v-slot="{ state }"
          rules="email"
        >
          <b-form-input
            class="m-auto rounded-3"
            v-model="form.email"
            type="text"
            name="email"
            :state="state"
          />
        </validating-group>
      </b-row>
      <b-row>
        <validating-group
          class="validating-group col-12 col-md-6"
          label="Passwort*"
          validation-label="Passwort"
          :validation-context="validation_context"
          v-slot="{ state }"
          rules="required|password_regex|min:6"
        >
          <b-form-input
            class="m-auto rounded-3"
            v-model="form.password"
            type="password"
            name="Passwort"
            :state="state"
          />
        </validating-group>
      </b-row>
      <b-row>
        <validating-group
          class="validating-group col-12 col-md-6"
          label="Passwort Wiederholung*"
          validation-label="Passwort Wiederholung"
          :validation-context="validation_context"
          v-slot="{ state }"
          rules="required|confirmed:Passwort"
        >
          <b-form-input
            class="m-auto rounded-3"
            v-model="form.confirmPassword"
            type="password"
            name="confirmPassword"
            :state="state"
          />
        </validating-group>
      </b-row>
      <b-button variant="primary" type="submit" :disabled="loading">
        Zum Auftrag
      </b-button>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { AccountStatusEnum } from '@gid/models';
import PartnerPotentialOffersMixin from '@gid/vue-common/components/mixins/PartnerPotentialOffersMixin';
const VALIDATION_CONTEXT = 'cancelPotentialOffer';

export default {
  mixins: [PartnerPotentialOffersMixin],
  props: {
    refresh_token: {
      type: String,
      required: true,
    },
    job_id: String,
    redirect_path: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      validation_context: VALIDATION_CONTEXT,
      form: {
        nachname: '',
        vorname: '',
        email: '',
        password: '',
        confirmPassword: '',
      },
      loading: false,
      error: false,
    };
  },
  methods: {
    async submitForm() {
      try {
        const formIsValid = await this.$refs.observer.validate();
        if (formIsValid) {
          this.loading = true;
          await this.changePassword(this.form.password);
          const payload = {
            firstName: this.form.vorname,
            lastName: this.form.nachname,
            account: {
              id: this.$store.state.auth.user.account.id,
            },
          };

          if (this.form.email) payload.email = this.form.email;

          await this.updateContact(payload);
          if (this.redirect_path === 'offers')
            await this.updateAccount({ status: AccountStatusEnum.NEW });
          if (!this.error) this.afterFormSubmitted();
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
        this.$emit(
          'error',
          error?.response?.data?.msg || error?.response?.statusText,
        );
      }
    },
    redirect(contactProfiles) {
      if (
        contactProfiles.length == 1 &&
        contactProfiles.includes('accounting')
      ) {
        this.$router.push({ name: 'orders-completed' });
      } else {
        if (this.redirect_path === 'offer')
          this.$router.push({ name: 'offer', params: { job_id: this.job_id } });
        else if (this.redirect_path === 'offers')
          this.$router.push({ name: 'offers' });
      }
    },
  },
};
</script>
