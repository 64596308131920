<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(onAddPartnerExternalEid)">
        <validation-provider
          v-slot="{ errors, valid }"
          rules="max:64"
          name="Name"
        >
          <b-form-input
            :value="value"
            @input="$emit('input', $event)"
            :disabled="showLoader"
            :placeholder="$t('order-partner-eid-placeholder')"
          ></b-form-input>
          <b-form-invalid-feedback :state="valid">
            <span v-for="(error, index) in errors" :key="index">
              {{ error }}
            </span>
          </b-form-invalid-feedback>
        </validation-provider>
        <div class="my-2">
          <b-button type="submit" :disabled="showLoader || invalid" class="p-1">
            <b-spinner
              v-if="showLoader"
              class="gid-spinner--button mr-2"
            ></b-spinner>
            <i class="material-icons">done</i>
          </b-button>
          <b-button
            @click="onCancelPartnerExternalEid"
            :disabled="showLoader"
            class="p-1 mx-2"
          >
            <i class="material-icons">close</i>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    showLoader: Boolean,
  },
  methods: {
    onAddPartnerExternalEid() {
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.$emit('save', this.value);
        }
      });
    },
    onCancelPartnerExternalEid() {
      this.$emit('cancel');
    },
  },
};
</script>
