<template>
  <div class="order-eid-block">
    <span
      v-if="showAddPartnerButton"
      class="text-primary"
      role="button"
      @click="onAddAction"
    >
      {{ $t('add-partner-eid') }}
    </span>
    <span
      class="partner-ext-eid"
      v-b-tooltip.hover
      :title="order.job?.partner_eid"
    >
      <template v-if="!showPartnerEidEditForm">
        {{ order.job?.partner_eid }}
      </template>
      <i
        v-if="showPartnerEditButton"
        class="material-icons gid-brand-eid-edit"
        role="button"
        @click="onAddAction"
      >
        edit
      </i>
    </span>
    <OrderEidEdit
      v-if="showPartnerEidEditForm"
      v-model="partnerEid"
      :show-loader="showPartnerLoader"
      @save="(event) => onSaveAction(event)"
      @cancel="onCancelAction"
    ></OrderEidEdit>
  </div>
</template>

<script>
import OrderEidEdit from '../OrderEidEdit.vue';
import axios from 'axios';

export default {
  name: 'OrderEidAddEdit',
  components: {
    OrderEidEdit,
  },
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showPartnerEidEditForm: null,
      partnerExternalEid: '',
      showPartnerLoader: false,
      partnerEid: this.order.job.partner_eid,
    };
  },
  computed: {
    showAddPartnerButton() {
      return (
        !this.order.job?.partner_eid &&
        !this.showPartnerEidEditForm &&
        !['completed', 'paid', 'invoice sent'].includes(this.order.job?.status)
      );
    },
    showPartnerEditButton() {
      return (
        this.order.job?.partner_eid &&
        !this.showPartnerEidEditForm &&
        !['completed', 'paid', 'invoice sent'].includes(this.order.job?.status)
      );
    },
  },
  methods: {
    onAddAction(orderJob) {
      const partnerEid = orderJob?.partner_eid; // get current eid
      this.showPartnerEidEditForm = true;
      this.partnerExternalEid = partnerEid ?? '';
    },
    async onSaveAction() {
      const { sfid } = this.order.job;
      this.showPartnerLoader = true;
      try {
        // make req to save new partner-eid
        await axios.patch(`/data-api/entity/job/${sfid}/partner-eid`, {
          partnerEid: this.partnerEid,
        });
        this.order.job.partner_eid = this.partnerEid;
        this.onCancelAction();
      } catch (error) {
        console.log(error);
      }
      this.showPartnerLoader = false;
    },
    onCancelAction() {
      this.showPartnerEidEditForm = false;
    },
  },
};
</script>
<style scoped>
.partner-ext-eid {
  word-break: break-all;
}
.gid-brand-eid-edit {
  font-size: 1em;
}
</style>
