<template>
  <div>
    <template v-if="!plainLayout">
      <b-navbar
        class="card-header d-flex justify-content-center gid-bg-neutral gid-header"
      >
        <router-link
          to="/"
          class="d-flex flex-wrap justify-content-center align-items-center"
        >
          <a href="https://www.getitdone.rocks/" target="_blank">
            <img src="@/assets/gid-logo.svg" alt="GID" class="gid-logo my-2" />
          </a>
          <b-badge
            v-if="environment != 'production'"
            class="ml-2"
            variant="warning"
          >
            {{ environment }}
          </b-badge>
        </router-link>
        <refresh-button v-if="updateExists" :onRefresh="refreshApp" />
      </b-navbar>
    </template>
    <div class="px-3">
      <slot />
    </div>
    <template v-if="!plainLayout">
      <div
        class="d-flex flex-wrap flex-row-reverse align-items-center gid-footer-info"
      >
        <footer-info />
        <a
          href="https://www.getitdone.rocks/service-partner-agb"
          class="shadow btn rounded-pill btn-primary gid-terms-note fixed-element"
          target="_blank"
        >
          {{ $t('tandc.heading') }}
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import FooterInfo from '@/components/FooterInfo.vue';
import { mapGetters } from 'vuex';
import update from '@gid/vue-common/components/mixins/update';
import { BIconArrowClockwise } from 'bootstrap-vue';

export default {
  mixins: [update],
  components: {
    BIconArrowClockwise, // eslint-disable-line
    FooterInfo,
  },
  computed: {
    ...mapGetters(['environment']),
    plainLayout() {
      return this.$route.query.plain === 'true';
    },
  },
};
</script>

<style lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.gid-logo {
  height: 2.4rem;
}

.gid-header {
  &.navbar {
    position: sticky;
    top: 0;
    flex-wrap: nowrap;
    z-index: 1032;
    padding: 0.7rem 1rem;
    gap: 0.5rem 1rem;
  }
}

@include media-breakpoint-down(sm) {
  .gid-logo {
    max-width: 12rem;
    width: 100%;
  }
}
</style>
