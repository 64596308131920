<template>
  <div>
    <b-modal
      no-fade
      :visible="visible"
      modal-class="gid-onboarding-modal welcome-modal"
      centered
      size="lg"
      @hide="onDialogHide"
    >
      <template #modal-header>
        <button class="close-btn" @click="$emit('cancel')">
          <BIconX />
        </button>
        <p class="title">{{ $t('onboarding.welcome-step.title') }}</p>
      </template>
      <p class="description mt-4">
        {{ $t('onboarding.welcome-step.description-1') }}
      </p>
      <ul class="description mt-4 pl-4">
        <li>{{ $t('onboarding.welcome-step.qualifications') }}</li>
        <li>{{ $t('onboarding.welcome-step.address') }}</li>
        <li>{{ $t('onboarding.welcome-step.vehicle-type') }}</li>
      </ul>
      <p class="description mt-4">
        {{ $t('onboarding.welcome-step.description-3') }}
      </p>
      <p class="description mt-5 text-muted skip-notice">
        {{ $t('onboarding.welcome-step.skip-notice') }}
      </p>
      <template #modal-footer>
        <b-button
          @click="$emit('cancel')"
          variant="outline-light"
          class="gid-btn gid-btn--ghost"
        >
          {{ $t('onboarding.actions.cancel') }}
        </b-button>
        <b-button
          @click="$emit('next')"
          variant="primary"
          class="gid-btn gid-btn--primary"
        >
          {{ $t('onboarding.actions.start') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BIconX } from 'bootstrap-vue';

export default {
  components: {
    BIconX,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onDialogHide(bvModalEvent) {
      if (bvModalEvent.trigger === 'backdrop') {
        bvModalEvent.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '@gid/vue-common/scss/_variables.scss';

::v-deep .welcome-modal {
  .modal-content {
    .modal-header {
      .title {
        font-size: 1.75rem;
      }
    }

    .modal-body {
      padding: 0 4rem;
      max-width: 35rem;

      .description {
        font-size: 1rem;
      }

      .skip-notice {
        font-size: 0.75rem;
      }
    }
  }
}
</style>
