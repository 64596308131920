import { JobStatusEnum } from '@gid/models';
import { mapGetters } from 'vuex';

export default {
  compouted: {
    ...mapGetters(['contactProfiles']),
  },
  methods: {
    canDelegate(order) {
      return (order) => {
        const allowedContactProfiles = ['admin', 'disposition'];
        const hasContactProfile = this.contactProfiles.some((p) =>
          allowedContactProfiles.includes(p),
        );

        const statusesNotAllowed = [JobStatusEnum.PROPOSAL_APPROVAL_PENDING];

        return (
          hasContactProfile && !statusesNotAllowed.includes(order.job.status)
        );
      };
    },
  },
};
