var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('b-row',[_c('b-col',[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('company-documents'))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t('company-page.payment.documents.explanation'))+":")])])],1),_c('b-row',[_c('b-col',[_c('b-table',{staticClass:"doc-table",attrs:{"striped":"","busy":_vm.loading,"sort-by":"type","sort-desc":false,"sort-compare-locale":_vm.locale,"items":_vm.formattedDocs,"fields":_vm.tableFields},scopedSlots:_vm._u([{key:"cell(path)",fn:function(data){return [_c('a',{attrs:{"href":_vm._f("jwtToken")(`/api/media/file/${data.item.path}`),"target":"_blank","rel":"noopener noreferer"}},[_vm._v(" "+_vm._s(`${_vm.$t('company-page.documents.document')} ${data.index + 1}`)+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-row justify-content-start align-items-center",staticStyle:{"gap":"1rem"}},[_c('b-button',{attrs:{"variant":"outline-secondary","size":"sm"},on:{"click":() =>
                  _vm.prepareModal('update', {
                    id: data.item.id,
                    name: `${_vm.$t('company-page.documents.document')} ${
                      data.index + 1
                    }`,
                  })}},[_c('b-icon-pen-fill')],1),_c('b-button',{attrs:{"variant":"outline-danger","size":"sm"},on:{"click":() =>
                  _vm.prepareModal('delete', {
                    id: data.item.id,
                    name: `${_vm.$t('company-page.documents.document')} ${
                      data.index + 1
                    }`,
                  })}},[_c('b-icon-trash-fill')],1)],1)]}}])})],1)],1),(_vm.errors && _vm.errors.length > 0)?_c('b-row',{staticClass:"mb-4"},[_c('b-col',[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"0.5rem"}},_vm._l((_vm.errors),function(error,i){return _c('small',{key:i,staticClass:"text-danger"},[_vm._v(" "+_vm._s(error)+" ")])}),0)])],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":() => _vm.prepareModal('create')}},[_vm._v(" "+_vm._s(_vm.$t('company-page.documents.add-document'))+" ")])],1)],1),_c('validation-observer',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('b-modal',{attrs:{"centered":"","cancel-title":_vm.$t('cancel'),"ok-title":_vm.modalOkTitle,"title":_vm.modalTitle},on:{"hidden":_vm.resetForm,"ok":function($event){$event.preventDefault();return handleSubmit(_vm.handleModalOk)}},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('b-form',[_c('validation-provider',{attrs:{"name":_vm.$t('company-page.documents.document-type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('company-page.documents.document-type'),"invalid-feedback":validationContext.errors[0]}},[_c('b-form-select',{attrs:{"state":_vm.getValidationState(validationContext),"options":_vm.docOptions},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('company-page.documents.lifetime-start'),"rules":_vm.isDateRequired ? 'required' : '',"custom-messages":{
            required: _vm.$t('company-page.documents.error.provide-start-date'),
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('company-page.documents.lifetime-start')}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-button',{attrs:{"aria-label":"Clear date","variant":"outline-danger"},on:{"click":function($event){_vm.form.lifetimeStart = null}}},[_c('b-icon-x')],1)]},proxy:true}],null,true)},[_c('b-form-datepicker',{attrs:{"label-no-date-selected":_vm.$t('company-page.documents.no-start-date'),"locale":_vm.locale},model:{value:(_vm.form.lifetimeStart),callback:function ($$v) {_vm.$set(_vm.form, "lifetimeStart", $$v)},expression:"form.lifetimeStart"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('company-page.documents.lifetime-end'),"rules":_vm.isDateRequired ? 'required' : '',"custom-messages":{
            required: _vm.$t('company-page.documents.error.provide-end-date'),
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('company-page.documents.lifetime-end')}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-button',{attrs:{"aria-label":"Clear date","variant":"outline-danger"},on:{"click":function($event){_vm.form.lifetimeEnd = null}}},[_c('b-icon-x')],1)]},proxy:true}],null,true)},[_c('b-form-datepicker',{attrs:{"label-no-date-selected":_vm.$t('company-page.documents.no-expiration-date'),"locale":_vm.locale},model:{value:(_vm.form.lifetimeEnd),callback:function ($$v) {_vm.$set(_vm.form, "lifetimeEnd", $$v)},expression:"form.lifetimeEnd"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),(_vm.modalMode === 'create')?_c('validation-provider',{attrs:{"name":_vm.$t('company-page.documents.document-file'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('company-page.documents.document-file')}},[_c('files-selector',{attrs:{"result-as-array":true,"maxFiles":1},model:{value:(_vm.form.files),callback:function ($$v) {_vm.$set(_vm.form, "files", $$v)},expression:"form.files"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t('company-page.documents.correctness-confirmation'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',[_c('b-form-checkbox',{attrs:{"value":new Date(),"unchecked-value":null,"state":validationContext.errors[0] ? false : null},model:{value:(_vm.form.confirmedAt),callback:function ($$v) {_vm.$set(_vm.form, "confirmedAt", $$v)},expression:"form.confirmedAt"}},[_vm._v(" "+_vm._s(_vm.$t('company-page.documents.confirm-correctness'))+" ")])],1)]}}],null,true)})],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }