<template>
  <div>
    <span v-if="account">
      <b-button
        v-if="accounts && showSwitchAccountButton"
        v-b-modal.change-account
      >
        {{ $t('change-account') }}
      </b-button>
      <br />
      {{ accountLabel(account) }}
    </span>
    <span v-else>-</span>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    showSwitchAccountButton: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    accountId: {
      type: String,
      required: true,
      validator: function (value) {
        return value.length === 18;
      },
    },
    addressLookup: {
      type: Array,
      required: false,
      default: function () {
        return ['shipping_address', 'billing_address'];
      },
      validator: function (value) {
        return (
          value.length == 2 &&
          value.every((x) =>
            ['shipping_address', 'billing_address'].includes(x),
          )
        );
      },
    },
    truncateAt: {
      type: Number,
      required: false,
      default: function () {
        return null;
      },
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      accounts: (state) => state.auth.accounts,
    }),
    isMasterAccountId() {
      return this.accountId === this.user.account.id;
    },
    isChildAccountId() {
      return this.accountId in this.user.children_accounts;
    },
    account() {
      if (this.isMasterAccountId) {
        return {
          id: this.user.account.id,
          name: this.user.account.name,
          country: this.getCountryFromAddressLookup(this.user.account),
          city: this.getCityFromAddressLookup(this.user.account),
        };
      } else if (this.isChildAccountId) {
        return {
          id: this.getChildAccount(this.accountId).id,
          name: this.getChildAccount(this.accountId).name,
          country: this.getCountryFromAddressLookup(
            this.getChildAccount(this.accountId),
          ),
          city: this.getCityFromAddressLookup(
            this.getChildAccount(this.accountId),
          ),
        };
      } else {
        return {};
      }
    },
  },
  methods: {
    accountLabel(account) {
      let label = `${account.name}`;
      if (
        (this.user.is_master_account && this.isChildAccountId) ||
        this.accounts?.length > 1
      ) {
        label = `${account.name}: ${account.city}`;
      }
      if (this.truncateAt && this.truncateAt < label.length) {
        return `${label.substring(0, this.truncateAt)}...`;
      } else if (
        !this.truncateAt ||
        (this.truncateAt && this.truncateAt >= label.length)
      ) {
        return label;
      }
    },
    getChildAccount(accountId) {
      return this.user.children_accounts[accountId];
    },
    getCountryFromAddressLookup(account) {
      const countries = this.addressLookup.map(
        (x) => `'${account[x].country}'` || null,
      );
      return eval(countries.join(' || '));
    },
    getCityFromAddressLookup(account) {
      const cities = this.addressLookup
        .map((x) => account[x].city)
        .filter(Boolean);
      return cities.find((c) => !!c) || null;
    },
  },
};
</script>
