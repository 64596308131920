import { mapState } from 'vuex';

export default {
  props: {
    type: String,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    waiting() {
      return this.type === 'waiting';
    },
    past() {
      return this.type === 'completed';
    },
    finalConfirmation() {
      return this.type === 'final-confirmation';
    },
    customerSignature() {
      return this.type === 'customer-signature';
    },
    hasAccountColumn() {
      return (
        (this.user && this.user.is_partner_admin_and_master_account) ||
        this.user.is_company_owner_and_master_account
      );
    },
    ordersGrid() {
      if (this.hasAccountColumn && !this.waiting && !this.past) {
        return {
          account: { lg: '2' },
          order: { lg: '2' },
          date: { lg: '2' },
          customer: { lg: '2' },
          status: { lg: '2' },
          action: { lg: '2' },
        };
      }

      if (!this.waiting && !this.past) {
        return {
          order: { lg: '2' },
          date: { lg: '2' },
          customer: { lg: '2' },
          status: { lg: '2' },
          action: { lg: '2' },
        };
      }

      if (this.hasAccountColumn) {
        return {
          account: { lg: '2' },
          order: { lg: '2' },
          date: { lg: '3' },
          customer: { lg: '3' },
          action: { lg: '2' },
        };
      }

      return {
        order: { lg: '3' },
        date: { lg: '3' },
        customer: { lg: '3' },
        action: { lg: '3' },
      };
    },
  },
};
