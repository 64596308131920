import Api from '@gid/vue-common/api';

const API_PATH = '/api/partner/jobs-count';

// Actions
export const COUNTS_REFRESH = 'countsRefresh';

// Mutations
export const COUNTS_SET_ALL = 'sidebarSetAll';
export const COUNTS_SET_SINGLE = 'sidebarSetSingle';

export default {
  state: {
    counts: [
      'final-confirmation',
      'at-work',
      'offers',
      'open',
      'scheduled',
      'waiting',
    ].reduce(
      (acc, type) => ({
        ...acc,
        [type]: { total: null, danger: 0, warning: 0 },
      }),
      {},
    ),
  },
  mutations: {
    [COUNTS_SET_ALL](state, counts) {
      state.counts = counts;
    },
    [COUNTS_SET_SINGLE](state, { type, count }) {
      if (state.counts[type]) {
        state.counts[type].total = count;
      }
    },
  },
  actions: {
    [COUNTS_REFRESH]({ commit }) {
      Api.get(API_PATH, {
        autoCancelScope: API_PATH,
      }).then((response) => {
        commit(COUNTS_SET_ALL, response.data);
      });
    },
  },
};
