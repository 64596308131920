<template>
  <div>
    <b-modal
      no-fade
      :visible="visible"
      modal-class="ob-success-modal"
      centered
      size="lg"
      @hide="onDialogHide"
    >
      <template #modal-header>
        <BIconCheckCircle class="check-icon" />
        <p class="title">{{ $t('onboarding.success-dialog.message') }}</p>
      </template>
      <template #modal-footer>
        <b-button
          @click="$emit('ok')"
          variant="primary"
          class="gid-btn gid-btn--primary"
        >
          {{ $t('onboarding.actions.ok') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BIconCheckCircle } from 'bootstrap-vue';

export default {
  components: {
    BIconCheckCircle,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onDialogHide(bvModalEvent) {
      if (bvModalEvent.trigger === 'backdrop') {
        bvModalEvent.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '@gid/vue-common/scss/_variables.scss';

::v-deep .ob-success-modal {
  .modal-content {
    * {
      font-family: 'Roboto', sans-serif;
    }

    width: auto;
    margin: 0 auto;

    .modal-header {
      padding: 1.4rem 1.2rem 1rem;
      flex-direction: column;
      border-bottom: 0;

      .check-icon {
        display: block;
        max-width: 6.25rem;
        width: 100%;
        height: auto;
        margin: 1rem auto 0;
        color: $primary-new;
      }

      .title {
        margin: 1.5rem auto 0;
        font-weight: 500;
        font-size: 1.5rem;
        letter-spacing: 0.3rem;
        color: $primary-new;
        text-transform: uppercase;
        text-align: center;
        max-width: 30rem;
      }
    }

    .modal-body {
      display: none;
    }

    .modal-footer {
      border-top: 0;
      padding: 1rem 1.2rem 1rem;
      justify-content: center;
      gap: 0.8rem;
    }
  }
}
</style>
