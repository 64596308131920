<template>
  <ValidationObserver ref="observer" slim>
    <b-form
      class="gid-form-wrapper mt-5 mx-auto gid-bg-neutral"
      novalidate
      @submit.prevent
    >
      <h1 class="heading">{{ $t('login') }}</h1>

      <validating-group
        v-for="(input, index) in updatedInputFields"
        :key="index"
        class="validating-group w-100"
        :label="$t(input.label)"
        :rules="input.rules"
        :validation-context="validation_context"
        mode="passive"
        v-slot="{ clearErrors, state, errors }"
      >
        <b-input-group
          :class="`field-wrapper ${
            errors?.length ? 'is-invalid border-danger' : ''
          }`"
        >
          <b-form-input
            v-model="$data[input.name]"
            @input="$data[input.name] === '' && clearErrors(true)"
            :placeholder="input?.placeholder ? $t(input.placeholder) : ''"
            :name="input.name"
            :type="input.type"
            :class="`field ${input.name === 'password' ? 'password' : ''}`"
            :state="state"
          />
          <button
            v-if="input.name === 'password'"
            type="button"
            @click="toggleShowPassword"
            class="bg-none bg-white border-left-0 pr-3"
          >
            <template v-if="showPassword">
              <BIconEyeSlash />
            </template>
            <template v-else>
              <BIconEye />
            </template>
          </button>
        </b-input-group>
      </validating-group>

      <b-col class="p-0">
        <b-row class="justify-content-end mb-2">
          <router-link to="/request-password-reset" class="forgot-ps-link">
            {{ $t('forgot-password') }}?
          </router-link>
        </b-row>
        <b-row>
          <b-form-checkbox v-model="saveUser" switch size="lg" class="checkbox">
            {{ $t('remember-me') }}
          </b-form-checkbox>
        </b-row>
      </b-col>

      <div v-if="error" class="invalid-credentials text-danger w-100">
        {{ $t(`_errors.${error}`, { email }) }}
      </div>

      <b-col class="gid-dark-theme p-0">
        <b-button
          class="login-btn mb-2"
          variant="primary"
          type="submit"
          @click="login"
        >
          <b-spinner v-if="loading" class="mr-2 gid-spinner--button" />
          {{ $t('login') }}
        </b-button>
        <p class="no-account-wrapper">
          {{ $t('no-account') }}
          <a
            href="https://www.getitdone.rocks/service-partner"
            target="_blank"
            rel="noreferrer"
          >
            {{ $t('register-here') }}
          </a>
        </p>
      </b-col>
    </b-form>
  </ValidationObserver>
</template>

<script>
import {
  LOGIN_WITH_PASSWORD,
  GENERATE_ACCESS_TOKEN,
} from '@gid/vue-common/store/auth.module/types';
import { BIconEye, BIconEyeSlash } from 'bootstrap-vue';

const VALIDATION_CONTEXT = 'login';

export default {
  components: {
    BIconEye,
    BIconEyeSlash,
  },
  async created() {
    if (this.$route.query.token) {
      try {
        await this.$store.dispatch(GENERATE_ACCESS_TOKEN, {
          refresh_token: this.$route.query.token,
        });
        this.afterLogin();
      } catch (error) {
        this.handleError(error);
      }
    }
  },
  data() {
    return {
      validation_context: VALIDATION_CONTEXT,
      showPassword: false,
      email: null,
      password: null,
      saveUser: true,
      error: null,
      loading: false,
      inputFields: [
        {
          name: 'email',
          label: 'e-mail',
          rules: 'required|email',
          placeholder: 'e-mail-example',
          type: 'email',
        },
        {
          name: 'password',
          label: 'password',
          placeholder: 'password',
          rules: 'required',
          type: this.showPassword ? 'text' : 'password',
        },
      ],
    };
  },
  computed: {
    updatedInputFields() {
      return this.inputFields.map((input) => {
        if (input.name === 'password') {
          return {
            ...input,
            type: this.showPassword ? 'text' : 'password',
          };
        }
        return input;
      });
    },
  },
  methods: {
    async login() {
      const path = this.$router.resolve({ name: 'login' }).href;
      const absoluteUrl = new URL(path, window.location.href).href;
      const formIsValid = await this.$refs.observer.validate();

      if (formIsValid) {
        this.loading = true;
        try {
          await this.$store.dispatch(LOGIN_WITH_PASSWORD, {
            email: this.email,
            password: this.password,
            role: 'partner',
            link: absoluteUrl,
            saveUser: this.saveUser,
          });
          this.afterLogin();
        } catch (error) {
          this.handleError(error);
        } finally {
          this.loading = false;
        }
      }
    },
    afterLogin() {
      if (this.$store.state.auth.user) {
        this.redirect(this.$store.state.auth.user.profiles);
      } else {
        // Wait until user info is available to descide
        // where to redirect to, based on profiles
        const unwatch = this.$store.watch(
          (state) => state.auth.user,
          (user) => {
            if (user) {
              this.redirect(user.profiles);
              unwatch();
            }
          },
        );
      }
    },
    redirect(contactProfiles) {
      if (
        contactProfiles.length == 1 &&
        contactProfiles.includes('accounting')
      ) {
        this.$router.push({ name: 'orders-completed' });
      } else {
        this.$router.push({ name: 'offers' });
      }
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    handleError(error) {
      if (error.response) {
        if (error.response.status === 401) {
          if (
            error.response.data.status === 'invalid_password' ||
            error.response.data.status === 'invalid_user'
          ) {
            this.error = error.response.data.status;
          } else {
            this.showToast(this.$t('_errors.user_or_password.details'));
          }
        } else if (error.response.status === 422) {
          this.showToast(this.$t('_errors.user_or_password.details'));
        } else {
          this.showToast(this.$t('_errors.server.details'));
        }
      } else if (error.request) {
        this.showToast(this.$t('_errors.network.details'));
      } else {
        this.showToast(error.message);
      }
    },
    showToast(message) {
      this.$bvToast.toast(message, {
        title: 'ERROR',
        variant: 'danger',
        noAutoHide: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@gid/vue-common/scss/_variables.scss';
@import '@/assets/scss/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.gid-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  max-width: 26rem;
  padding: 1.5rem 1.2rem;
  border-radius: 0.0625rem;
  border: 0.1px solid $black-transparent;
  width: 100%;

  & .heading {
    text-transform: uppercase;
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: 0.3rem;
  }

  & * {
    margin: 0;
  }

  & .validating-group {
    ::v-deep legend {
      text-align: start;
      padding: 0;
    }

    & .field-wrapper {
      transition: box-shadow 0.1s ease;
      border: 1px solid $black-transparent;
      border-radius: 0.375rem;
      overflow: hidden;
      width: 100%;

      &:focus-within {
        border-radius: 0.375rem;
        box-shadow: $box-shadow-primary;
      }

      & .field {
        border: none;

        &.password {
          &:focus {
            box-shadow: none;
          }

          & + button {
            border: none;
          }
        }
      }

      &.is-invalid {
        &:focus-within {
          box-shadow: $box-shadow-danger;
        }
      }
    }
  }

  & .checkbox {
    ::v-deep label {
      font-size: 0.875rem;

      &::before {
        position: absolute;
        top: 0.09rem;
      }
      &::after {
        position: absolute;
        top: 0.19rem;
      }
    }
  }

  & .login-btn {
    font-size: 1.15rem;
    display: block;
    margin: 0 auto;
  }

  & .invalid-credentials {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 0.875rem;
  }

  & .no-account-wrapper {
    font-size: 0.7rem;
    margin-top: 1rem;
    text-align: center;

    & a {
      color: $black;
      text-decoration: underline;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  & .forgot-ps-link {
    border: 0;
    background: none;
    font-size: 0.875rem;
    color: $black;
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }

  ::v-deep .invalid-feedback {
    text-align: start;
  }
}
</style>
