
import { Prop, Vue, Component } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  name: 'CrCollapse',
  computed: mapGetters(['locale']),
})
export default class CrCollapse extends Vue {
  @Prop({ default: false }) extended!: boolean;
  @Prop() cancellations!: any[];

  get cancellationCategories() {
    return [
      ...new Set(
        this.cancellations?.map((cancellation) => cancellation.reason_category),
      ),
    ];
  }

  getCancellationCategoryByLocale(category: string) {
    const parsedCategory = category
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s]+/g, '-')
      .replace(/[/]+/g, '_')
      .toLowerCase();
    return this.$t(`change-categories.${parsedCategory}`);
  }
}
