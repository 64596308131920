<template>
  <b-row
    v-if="products.length && !!products.find((p) => p.supplier == 'partner')"
    class="border-bottom py-3"
  >
    <b-col cols="12" lg="4" class="text-muted">
      {{ $t('order-details.products.list') }}:
    </b-col>
    <b-col cols="12" lg="8" class="d-flex justify-content-between">
      <a href="#" @click.prevent="downloadProductsListPdf">
        {{ $t('order-details.products.download') }}
      </a>
      <b-modal
        size="lg"
        ref="productsModal"
        :title="$t('order-details.products.product-list-view')"
      >
        <b-table hover :items="this.products" :fields="fields">
          <template #cell(supplier)="{ item }">
            <span class="text-capitalize">{{ item.supplier }}</span>
          </template>
        </b-table>
      </b-modal>
      <b-button
        size="sm"
        variant="outline-primary"
        @click="showProductsModal"
        class="text-uppercase py-0"
      >
        {{ $t('more') }}
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import axios from 'axios';
export default {
  props: {
    products: {
      type: Array,
      required: true,
    },
    orderSfid: {
      type: String,
      required: true,
    },
    context: {
      type: String,
      default: 'offers',
    },
  },
  data() {
    return {
      fields: [
        { key: 'item_id', label: 'SKU' },
        { key: 'name', label: 'Product Name' },
        { key: 'quantity', label: 'Quanity' },
        { key: 'supplier', label: 'Supplier' },
      ],
    };
  },
  methods: {
    showProductsModal() {
      this.$refs['productsModal'].show();
    },
    async downloadProductsListPdf() {
      const res = await axios.get(
        `/api/partner/${this.context}/${this.orderSfid}/product-list`,
        { responseType: 'arraybuffer' },
      );
      const blob = new Blob([res.data], { type: 'application/pdf' });
      const fileUrl = URL.createObjectURL(blob);
      window.open(fileUrl);
    },
  },
};
</script>
