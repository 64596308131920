<template>
  <div>
    <b-card class="my-3">
      <p class="lead">{{ $t('badge.title') }}:</p>
      <p class="card-text">{{ $t('badge.instructions_ahead') }}</p>
    </b-card>
    <b-card class="my-3">
      <div v-html="badgeCode"></div>
      <pre>
        {{ badgeCode }}
      </pre>
      <div class="mt-3">
        <b-button-group>
          <b-button
            v-for="(el, index) in sizes"
            variant="outline-secondary"
            class="px-2"
            :key="index"
            @click="setSize(el.size)"
          >
            {{ el.text }}
          </b-button>
        </b-button-group>
      </div>
      <div class="mt-3">
        <b-button
          variant="outline-primary"
          v-clipboard="() => badgeCode"
          v-clipboard:success="clipboardSuccessHandler"
        >
          {{ clipboardInstructions }}
        </b-button>
      </div>
    </b-card>

    <b-card>
      <div class="card-text">
        {{ $t('badge.instructions_below.intro') }}
        <ul>
          <li>{{ $t('badge.instructions_below.step1') }}</li>
          <li>{{ $t('badge.instructions_below.step2') }}</li>
          <li>{{ $t('badge.instructions_below.step3') }}</li>
          <li>{{ $t('badge.instructions_below.step4') }}</li>
          <li>{{ $t('badge.instructions_below.step5') }}</li>
        </ul>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      copied: false,
      size: '175',
      sizes: [
        {
          text: this.$i18n.t('badge.sizes.small.text'),
          size: this.$i18n.t('badge.sizes.small.width'),
        },
        {
          text: this.$i18n.t('badge.sizes.medium.text'),
          size: this.$i18n.t('badge.sizes.medium.width'),
        },
        {
          text: this.$i18n.t('badge.sizes.big.text'),
          size: this.$i18n.t('badge.sizes.big.width'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['environment']),
    badgeCode() {
      return `<a href="https://www.getitdone.rocks" target="_blank">
        <img width="${this.size}" src="${this.badgeURL}?0=${
        this.user.account_tracking_id
      }" alt="${this.$i18n.t('badge.alt', {
        partner_name: this.user.account.name,
        partner_city: this.user.account.shipping_address.city,
      })}" />
      </a>`;
    },
    badgeURL() {
      if (this.environment === 'production') {
        return 'https://api.getitdone.rocks/v2/partner/badge.svg';
      }
      return '/api/partner/badge.svg';
    },
    clipboardInstructions() {
      return this.copied
        ? this.copySuccess
        : this.$i18n.t('badge.copy-to-clipboard');
    },
    copySuccess() {
      return this.$i18n.t('badge.copy.success');
    },
    user() {
      const user = this.$store.getters.user;
      if (user.account) {
        return user;
      }
      return {
        account_tracking_id: '',
        account: {
          name: '',
          shipping_address: {
            city: '',
          },
        },
      };
    },
  },
  methods: {
    clipboardSuccessHandler() {
      this.copied = true;
    },
    setSize(value) {
      this.size = value;
    },
  },
  watchers: {},
};
</script>
