<template>
  <div>
    <component
      :is="getStepComponent()"
      v-if="currentStep !== null"
      :visible="stepDialog"
      :form="getStepForm()"
      :steps-count="steps.length"
      :step-index="currentStepIndex"
      :loading="loading"
      @cancel="promptCancelOnboarding"
      @prev="prevStep"
      @next="nextStep"
      @skip="skipStep()"
      @submit="saveOnboarding"
      @edit="currentStep = $event"
    />
    <SuccessDialog :visible="successDialog" @ok="refresh" />
    <CancelDialog
      :loading="loading"
      :visible="cancelDialog"
      :showSkip="hasAnyData"
      @cancel="cancelOnboarding"
      @continue="continueOnboarding"
      @saveAndCancel="saveOnboarding"
    />
  </div>
</template>

<script>
import { OnboardingStepEnum } from '@gid/models';
import { mapGetters } from 'vuex';
import { CondOperator, RequestQueryBuilder } from '@dataui/crud-request';
import axios from 'axios';
import WelcomeStep from './WelcomeStep.vue';
import QualificationsStep from './QualificationsStep.vue';
import AddressStep from './AddressStep.vue';
import VehicleStep from './VehicleStep.vue';
import SummaryStep from './SummaryStep.vue';
import SuccessDialog from './SuccessDialog.vue';
import CancelDialog from './CancelDialog.vue';
import { cloneDeep } from 'lodash';
import { handleFetchError } from '@gid/vue-common/utils';
import { CountryCodeEnum, CountryEnum } from '@gid/models';

const STEPS_ORDER = [
  OnboardingStepEnum.QUALIFICATIONS,
  OnboardingStepEnum.ADDRESS,
  OnboardingStepEnum.VEHICLE,
];

const STEP_FORM_MAP = {
  [OnboardingStepEnum.QUALIFICATIONS]: 'qualificationsForm',
  [OnboardingStepEnum.ADDRESS]: 'addressForm',
  [OnboardingStepEnum.VEHICLE]: 'vehicleForm',
  [OnboardingStepEnum.SUMMARY]: 'summaryData',
};

export default {
  components: {
    WelcomeStep,
    QualificationsStep,
    AddressStep,
    VehicleStep,
    SummaryStep,
    SuccessDialog,
    CancelDialog,
  },
  data() {
    return {
      steps: [],
      currentStep: null,
      cancelDialog: false,
      stepDialog: true,
      successDialog: false,
      qualificationsForm: {
        hwoA: [],
        installerIdNumber: null,
        networkOperator: null,
      },
      addressForm: {
        street: null,
        postalCode: null,
        city: null,
      },
      vehicleForm: {
        transporter: [],
      },
      originalData: {},
      saveMode: 'partial',
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['user']),
    hasAnyData() {
      return this.steps
        .map((step) => Object.values(this[STEP_FORM_MAP[step]] || {}))
        .flat()
        .some((value) =>
          Array.isArray(value) ? value.length > 0 : value != null,
        );
    },
    summaryData() {
      return this.steps.reduce(
        (acc, step) =>
          STEP_FORM_MAP[step]
            ? { ...acc, [step]: this[STEP_FORM_MAP[step]] }
            : acc,
        {},
      );
    },
    currentStepIndex() {
      return this.steps.indexOf(this.currentStep);
    },
  },
  async created() {
    await this.fetchCompletedSteps();
    await this.fetchOnboardingData();
  },
  methods: {
    getStepComponent() {
      switch (this.currentStep) {
        case OnboardingStepEnum.WELCOME:
          return WelcomeStep;
        case OnboardingStepEnum.QUALIFICATIONS:
          return QualificationsStep;
        case OnboardingStepEnum.ADDRESS:
          return AddressStep;
        case OnboardingStepEnum.VEHICLE:
          return VehicleStep;
        case OnboardingStepEnum.SUMMARY:
          return SummaryStep;
        default:
          return null;
      }
    },
    getStepForm() {
      return this[STEP_FORM_MAP[this.currentStep]] ?? null;
    },
    async fetchCompletedSteps() {
      const query = RequestQueryBuilder.create({
        fields: ['completedOnboardingSteps'],
      }).query();

      this.loading = true;
      const { data } = await axios.get(`/data-api/partner?${query}`);
      this.loading = false;

      this.steps = STEPS_ORDER.filter(
        (step) => !data[0].completedOnboardingSteps.includes(step),
      );

      if (this.steps.length > 0) {
        this.currentStep = OnboardingStepEnum.WELCOME;
      }
    },
    async fetchOnboardingData() {
      if (this.steps.length === 0) {
        return;
      }

      const fields = [];
      if (this.steps.includes(OnboardingStepEnum.QUALIFICATIONS)) {
        fields.push('hwoA', 'installerIdNumber', 'networkOperator');
      }

      if (this.steps.includes(OnboardingStepEnum.ADDRESS)) {
        fields.push(
          'shippingAddress.street',
          'shippingAddress.postalCode',
          'shippingAddress.city',
        );
      }

      if (this.steps.includes(OnboardingStepEnum.VEHICLE)) {
        fields.push('transporter');
      }

      const query = RequestQueryBuilder.create({
        fields,
      }).query();

      this.loading = true;
      const { data } = await axios.get(`/data-api/partner?${query}`);
      this.loading = false;

      const {
        hwoA,
        installerIdNumber,
        networkOperator,
        transporter,
        shippingAddress,
        ...rest
      } = data[0];

      this.qualificationsForm = {
        installerIdNumber: installerIdNumber ?? null,
        networkOperator: networkOperator ?? null,
        hwoA: hwoA ? hwoA.split(';') : [],
      };
      this.addressForm = {
        street: shippingAddress?.street ?? null,
        postalCode: shippingAddress?.postalCode ?? null,
        city: shippingAddress?.city ?? null,
      };
      this.vehicleForm = {
        transporter: transporter ? transporter.split(';') : [],
      };

      this.steps.forEach((step) => {
        if (STEP_FORM_MAP[step]) {
          this.originalData[step] = cloneDeep(this[STEP_FORM_MAP[step]]);
        }
      });
    },
    promptCancelOnboarding() {
      this.stepDialog = false;
      this.cancelDialog = true;
    },
    continueOnboarding() {
      this.cancelDialog = false;
      this.stepDialog = true;
    },
    cancelOnboarding() {
      this.cancelDialog = false;
      this.stepDialog = false;
    },
    async saveOnboarding() {
      const data = {};

      if (this.steps.includes(OnboardingStepEnum.QUALIFICATIONS)) {
        data.hwoA = this.qualificationsForm.hwoA.join(';');
        data.installerIdNumber = this.qualificationsForm.installerIdNumber;
        data.networkOperator = this.qualificationsForm.networkOperator;
      }

      if (this.steps.includes(OnboardingStepEnum.ADDRESS)) {
        data.shippingAddress = {
          street: this.addressForm.street,
          postalCode: this.addressForm.postalCode,
          city: this.addressForm.city,
          countryCode: CountryCodeEnum.GERMANY,
          country: CountryEnum.GERMANY,
        };
      }

      if (this.steps.includes(OnboardingStepEnum.VEHICLE)) {
        data.transporter = this.vehicleForm.transporter.join(';');
      }

      try {
        this.loading = true;
        const { data: updatedData } = await axios.post(
          `/data-api/partner/${this.user?.account.id}/onboarding`,
          data,
        );

        if (
          STEPS_ORDER.every((step) =>
            updatedData.completedOnboardingSteps.includes(step),
          )
        ) {
          this.saveMode = 'full';
        } else {
          this.saveMode = 'partial';
        }
        this.cancelDialog = false;
        this.stepDialog = false;
        this.successDialog = true;
      } catch (error) {
        this.$bvToast.toast(
          handleFetchError(error) || this.$t('_errors.general.title'),
          {
            title: this.$t('_errors.general.title'),
            variant: 'danger',
            solid: true,
          },
        );
      } finally {
        this.loading = false;
      }
    },
    skipStep() {
      this[STEP_FORM_MAP[this.currentStep]] = cloneDeep(
        this.originalData[this.currentStep],
      );
      this.nextStep();
    },
    nextStep() {
      this.currentStep =
        this.steps[this.currentStepIndex + 1] ?? OnboardingStepEnum.SUMMARY;
    },
    prevStep() {
      if (this.currentStep === OnboardingStepEnum.SUMMARY) {
        this.currentStep = this.steps[this.steps.length - 1] ?? null;
      } else if (this.currentStepIndex === 0) {
        this.currentStep = OnboardingStepEnum.WELCOME;
      } else {
        this.currentStep = this.steps[this.currentStepIndex - 1] ?? null;
      }
    },
    refresh() {
      if (this.saveMode === 'partial') {
        this.successDialog = false;
      } else if (this.saveMode === 'full') {
        window.location.reload();
      }
    },
  },
};
</script>
