<template>
  <b-row class="bg-light border-bottom py-3 align-items-center">
    <template v-if="type === 'order-intake-forms'">
      <b-col cols="12" lg="12" class="slot-wrapper position-relative">
        <slot />
      </b-col>
    </template>
    <template v-else>
      <b-col cols="12" lg="3" class="text-muted mb-2 mb-lg-0">
        {{ autoTitle }}:
      </b-col>
      <b-col cols="12" lg="9" class="slot-wrapper">
        <slot />
      </b-col>
    </template>
  </b-row>
</template>

<script>
export default {
  props: {
    inputName: String,
    title: String,
    type: {
      type: String,
      required: false,
    },
  },
  computed: {
    autoTitle() {
      const titleTerm = this.title ? this.title : this.inputName;
      return this.$t(`inputs.headings.${titleTerm}`);
    },
  },
};
</script>

<style lang="scss" scoped>
div.slot-wrapper {
  background: #f8f9fa;
}
</style>
