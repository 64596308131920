<template>
  <div>
    <b-modal
      no-fade
      :visible="visible"
      modal-class="gid-onboarding-modal address-modal"
      centered
      size="lg"
      @hide="onDialogHide"
    >
      <template #modal-header>
        <button
          :disabled="loadingAction"
          type="button"
          class="close-btn"
          @click="$emit('cancel')"
        >
          <BIconX />
        </button>
        <p class="step-progress">
          {{
            $t('onboarding.progress', {
              step: stepIndex + 1,
              total: stepsCount,
            })
          }}
        </p>
        <p class="title">{{ $t('onboarding.address-step.title') }}</p>
        <p class="subtitle">{{ $t('onboarding.address-step.subtitle') }}</p>
      </template>
      <p class="description mb-4">
        {{ $t('onboarding.address-step.description') }}
      </p>
      <validation-observer ref="observer" slim v-slot="{ handleSubmit }">
        <b-form
          id="addressForm"
          @submit.prevent="handleSubmit(validateAndNext)"
        >
          <validation-provider
            :name="$t('onboarding.address-step.street')"
            rules="required"
            v-slot="validationContext"
          >
            <b-form-group
              label-class="field-label"
              label-cols-sm="3"
              :label="$t('onboarding.address-step.street')"
              :invalid-feedback="validationContext.errors[0]"
            >
              <b-form-input
                trim
                v-model="form.street"
                @update="form.street = $event || null"
                placeholder="Beispielstrasse 45"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>
          <validation-provider
            :name="$t('onboarding.address-step.postal-code')"
            rules="required|integer|max:5"
            v-slot="validationContext"
          >
            <b-form-group
              label-class="field-label"
              label-cols-sm="3"
              :label="$t('onboarding.address-step.postal-code')"
              :invalid-feedback="validationContext.errors[0]"
            >
              <b-form-input
                trim
                v-model="form.postalCode"
                @update="form.postalCode = $event || null"
                placeholder="12345"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>
          <validation-provider
            :name="$t('onboarding.address-step.city')"
            rules="required"
            v-slot="validationContext"
          >
            <b-form-group
              label-class="field-label"
              label-cols-sm="3"
              :label="$t('onboarding.address-step.city')"
              :invalid-feedback="validationContext.errors[0]"
            >
              <b-form-input
                trim
                v-model="form.city"
                @update="form.city = $event || null"
                :placeholder="$t('onboarding.address-step.city')"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
      <div class="text-danger api-error mt-4" v-if="apiError">
        {{ apiError }}
      </div>
      <template #modal-footer>
        <b-button
          :disabled="loadingAction"
          type="button"
          @click="$emit('prev')"
          variant="outline-light"
          class="gid-btn gid-btn--ghost"
        >
          {{ $t('onboarding.actions.back') }}
        </b-button>
        <b-button
          :disabled="loadingAction"
          form="addressForm"
          type="submit"
          variant="primary"
          class="gid-btn gid-btn--primary"
        >
          <b-spinner v-if="loadingAction" small></b-spinner>
          {{ $t('onboarding.actions.continue') }}
        </b-button>
        <b-button
          :disabled="loadingAction"
          type="button"
          @click="$emit('skip')"
          variant="outline-secondary"
          class="gid-btn"
        >
          {{ $t('onboarding.actions.skip') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { BIconX } from 'bootstrap-vue';
import { handleFetchError } from '@gid/vue-common/utils';
import { CountryCodeEnum } from '@gid/models';

export default {
  components: {
    BIconX,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    stepIndex: Number,
    stepsCount: Number,
  },
  data() {
    return {
      loadingAction: false,
      apiError: null,
    };
  },
  computed: {},
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      if (dirty || validated) {
        return valid ? null : false;
      }
      return null;
    },
    onDialogHide(bvModalEvent) {
      if (bvModalEvent.trigger === 'backdrop') {
        bvModalEvent.preventDefault();
      }
    },
    async validateAndNext() {
      this.loadingAction = true;

      try {
        const { data } = await axios.post(
          '/data-api/geocode/validate-address',
          {
            ...this.form,
            countryCode: CountryCodeEnum.GERMANY.toLowerCase(),
          },
        );
        if (!data) {
          throw new Error(this.$t('onboarding.errors.invalid-address'));
        }
        this.$emit('next');
      } catch (error) {
        this.apiError = handleFetchError(error);
      } finally {
        this.loadingAction = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '@gid/vue-common/scss/_variables.scss';

::v-deep .address-modal {
  .modal-content {
    .modal-header {
      .subtitle {
        max-width: 29rem;
      }
    }
  }
}
</style>
