<template>
  <b-modal
    id="change-account"
    title="Change Account"
    ok-only
    ok-variant="secondary"
    ok-title="Cancel"
  >
    <div v-for="a in accounts" :key="a.user.account.id">
      <b-button @click="changeAccount(a.access_token)" class="mt-2">
        {{ changeAccountLabel(a) }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import { STORE_ACCESS_TOKEN } from '@gid/vue-common/store/auth.module/types';

export default {
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      accounts: (state) => state.auth.accounts,
    }),
  },
  methods: {
    changeAccount(access_token) {
      this.$store.dispatch(STORE_ACCESS_TOKEN, access_token);
      this.$bvModal.hide('change-account');
      this.$router.go();
    },
    changeAccountLabel(account) {
      const acc = account.user.account;
      return `${acc.name.replace(/\(Standort:.*?\)/g, '').trim()}: ${
        acc.shipping_address.city
      }`;
    },
  },
};
</script>
