
import VueRouter from 'vue-router';
import { Component, Vue, Prop } from 'vue-property-decorator';
import axios from 'axios';

@Component
export default class CustomerUnreachableModal extends Vue {
  @Prop({ required: true }) order!: any;
  @Prop() isOrderDetails?: boolean;

  acceptedModalWarning = false;
  unreachableLoading = false;

  get modalId() {
    return `customer-unreachable-${this.order.sfid}`;
  }

  async markCustomerUnreachable(jobApiId: string) {
    this.unreachableLoading = true;
    try {
      await axios.post(`/jobs-api/status-rules/job/${jobApiId}/apply-event`, {
        type: 'CUSTOMER_UNREACHABLE',
      });

      this.$bvModal.hide(this.modalId);

      if (!this.isOrderDetails) {
        // @ts-ignore: Object is possibly 'null'.
        this.$parent.$emit('removed', jobApiId);
      }
    } catch (error) {
      console.error(error);
    }
    this.unreachableLoading = false;
  }
}
