<template>
  <div class="gid-api-response-wrapper">
    <template v-if="emailWasSent !== null">
      <ResendEmail :emailWasSent="emailWasSent">
        <BIconCheckCircle
          v-if="emailWasSent"
          class="api-response-icon text-primary"
        />
        <BIconExclamationCircle v-else class="api-response-icon text-warning" />
      </ResendEmail>
    </template>
    <template v-else>
      <h1 :class="isSuccess ? 'text-primary' : ''">
        {{ $t(`signup-form.api-response.${apiResponse.msg}.heading`) }}
      </h1>
      <p class="px-5">
        {{ $t(`signup-form.api-response.${apiResponse.msg}.paragraph-1`) }}
      </p>
      <template v-if="isSuccess">
        <BIconCheckCircle class="api-response-icon text-primary" />
      </template>
      <p>
        {{ $t(`signup-form.api-response.${apiResponse.msg}.paragraph-2`) }}
        <br />
        {{ $t(`signup-form.api-response.${apiResponse.msg}.paragraph-3`) }}
      </p>
      <template v-if="isSuccess">
        <b-link href="/login" target="_blank">
          <b-button variant="primary">
            {{ $t('signup-form.api-response.success.login') }}
          </b-button>
        </b-link>
        <p>
          <b-spinner variant="primary" v-if="isLoading" />
          <template v-else>
            {{ $t('signup-form.api-response.success.email-not-received') }}?
            <button
              @click="handleResendEmail"
              class="resend-btn bg-transparent border-0 text-primary"
            >
              {{ $t('signup-form.api-response.success.resend-email') }}
            </button>
          </template>
        </p>
      </template>
      <template v-if="!isSuccess">
        <i18n path="signup-form.api-response.duplicate.paragraph-3" tag="p">
          <template v-slot:phoneNumber>
            <a href="tel:030549071240">030 549 071 240</a>
          </template>
          <template v-slot:email>
            <a href="mailto:partner@getitdone.rocks">partner@getitdone.rocks</a>
          </template>
        </i18n>
        <BIconExclamationCircle class="api-response-icon text-warning" />
      </template>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import { BIconCheckCircle, BIconExclamationCircle } from 'bootstrap-vue';
import ResendEmail from './ResendEmail.vue';

export default {
  components: {
    BIconCheckCircle,
    BIconExclamationCircle,
    ResendEmail,
  },
  props: {
    apiResponse: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      emailWasSent: null,
    };
  },
  computed: {
    isSuccess() {
      return this.apiResponse?.msg === 'success';
    },
  },
  methods: {
    async handleResendEmail() {
      try {
        this.isLoading = true;

        const result = await axios.post('/api/partner/sign-up/resend-email', {
          partner_account_id: this.apiResponse?.account_id,
          partner_contact_id: this.apiResponse?.contact_id,
        });

        if (result.data.msg === 'success') {
          this.emailWasSent = true;
        }
        if (result.data.msg === 'failed') {
          this.emailWasSent = false;
        }
      } catch (error) {
        console.error(error);
        this.emailWasSent = false;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.gid-api-response-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1.25rem;
  max-width: 27rem;
  margin: 0 auto;

  & .api-response-icon {
    display: block;
    max-width: 7.5rem;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  & .resend-btn {
    margin: 0;
    padding: 0;
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
