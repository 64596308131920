var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.inputFields),function(input,index){return _c('validating-group',{key:index,attrs:{"label-cols-sm":"3","validation-label":_vm.$t(input.label || input.name),"label":`${_vm.$t(input.label || input.name)}${
      (input.rules?.required || input.rules?.includes('required')) && '*'
    }`,"validation-context":_vm.validation_context,"validation-path":input.name,"rules":input.rules,"mode":_vm.getInputValidationMode(input)},scopedSlots:_vm._u([{key:"default",fn:function({ clearErrors, state, errors }){return [_c('b-input-group',{class:`gid-field-wrapper ${
        errors?.length ? 'is-invalid border-danger' : ''
      }`,scopedSlots:_vm._u([{key:"append",fn:function(){return undefined},proxy:true}],null,true)},[(input.select)?_c('b-form-select',{attrs:{"state":state,"options":input.select},on:{"input":function($event){_vm.form[input.name] === '' && clearErrors(true);
          _vm.$emit('handleFormValueUpdate', $event, input.name);}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t('no-selection')))])]},proxy:true}],null,true),model:{value:(_vm.form[input.name]),callback:function ($$v) {_vm.$set(_vm.form, input.name, $$v)},expression:"form[input.name]"}}):_vm._e(),_c('b-form-input',{class:`border-0 ${
          !input.name.startsWith('password') ? '' : 'field password'
        }`,attrs:{"state":state,"type":_vm.getInputType(input.name, input.type),"placeholder":input?.placeholder &&
          _vm.$t(
            `signup-form.placeholder.${input.placeholder.key}`,
            input.placeholder?.props,
          )},on:{"input":function($event){_vm.form[input.name] === '' && clearErrors(true);
          _vm.$emit('handleFormValueUpdate', $event, input.name);}},model:{value:(_vm.form[input.name]),callback:function ($$v) {_vm.$set(_vm.form, input.name, $$v)},expression:"form[input.name]"}}),(input.name.startsWith('password'))?_c('button',{staticClass:"bg-none bg-white border-0 rounded-right pr-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleShowPassword(input.name)}}},[(_vm.showPassword(input.name))?[_c('BIconEyeSlash')]:[_c('BIconEye')]],2):_vm._e()],1)]}}],null,true)})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }