<template>
  <div class="mb-3">
    <validating-group
      :validation-label="$t('accept-tos')"
      :validation-context="validation_context"
      :validation-path="`tos`"
      :rules="{
        required: {
          allowFalse: ['adelmann'].includes(form.referral_source),
        },
      }"
    >
      <b-form-checkbox
        v-model="form.accept_tos"
        @input="$emit('handleFormValueUpdate', $event, 'accept_tos')"
      >
        <i18n path="accept-tos-text" tag="span">
          <template #link>
            <a
              href="https://www.getitdone.rocks/service-partner-agb"
              target="_blank"
            >
              {{ $t('accept-tos') }}
            </a>
          </template>
        </i18n>
      </b-form-checkbox>
      <b-form-checkbox
        v-model="form.accept_marketing_consent"
        @input="
          $emit('handleFormValueUpdate', $event, 'accept_marketing_consent')
        "
        class="mt-2"
      >
        <i18n path="accept-marketing-consent-text" tag="span" />
      </b-form-checkbox>
    </validating-group>
  </div>
</template>

<script>
export default {
  props: {
    validation_context: String,
    form: Object,
  },
};
</script>
