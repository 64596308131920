<template>
  <b-modal
    ref="modal"
    :title="$t('nearby-offers.title')"
    size="lg"
    body-class="py-0"
    :ok-only="!showAcceptAll"
    :ok-variant="showAcceptAll ? 'primary' : 'secondary'"
    :ok-disabled="acceptAllInProgress"
    :cancel-title="$t('close')"
    @hidden="onHidden"
    @ok="onOk"
  >
    <template #modal-ok>
      <b-spinner
        v-if="acceptAllInProgress"
        class="gid-spinner--button mr-2"
      ></b-spinner>
      {{ $t(showAcceptAll ? 'nearby-offers.accept-all' : 'close') }}
    </template>
    <b-row
      v-for="(offer, index) in nearbyOffers"
      :key="index"
      :class="['py-2', { 'border-top': index > 0 }]"
    >
      <b-col md="7">
        {{ offer.opportunity.name }}
        <br />
        {{ offer.job.name }}
        <br />
        <a
          target="_blank"
          :href="`https://maps.google.com/?q=${offer.shipping_address.city}+${offer.shipping_address.postal_code}`"
        >
          {{ offer.shipping_address.postal_code }}
          {{ offer.shipping_address.city }}
        </a>
        <br />
        {{
          $t('nearby-offers.distance', {
            job_name: offer.ref_job.name,
            distance: offer.distance_from_ref_job,
          })
        }}
      </b-col>
      <b-col md="5" class="text-right">
        {{ offer.job.price_purchasing | currency }}
        <b-button
          size="sm"
          class="mr-2"
          :to="{ name: 'offer', params: { job_id: offer.job.sfid } }"
          target="_blank"
        >
          {{ $t('details') }}
        </b-button>
        <span v-if="offer.accepted" class="text-primary">
          ✓ {{ $t('accepted') }}
        </span>
        <accept-job
          v-else
          size="sm"
          :offer="offer"
          :loading="offer.acceptLoading"
          @accept="onAccept"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import AcceptJob from '@/components/AcceptJob.vue';

export default {
  components: {
    AcceptJob,
  },
  data() {
    return {
      nearbyOffers: [],
      pendingOffers: [],
    };
  },
  computed: {
    ...mapGetters(['user', 'tandCalreadySigned']),
    allAccepted() {
      return !this.nearbyOffers.some(({ accepted }) => !accepted);
    },
    showAcceptAll() {
      return !this.allAccepted && !this.user?.is_master_account;
    },
    acceptAllInProgress() {
      return this.pendingOffers.length > 0;
    },
  },
  methods: {
    async init(acceptedOffer) {
      const { data: nearbyOffers } = await axios.get(
        `/api/partner/offers/${acceptedOffer}/nearby`,
      );
      this.nearbyOffers.push(
        ...nearbyOffers
          .filter(
            (offer) =>
              !this.nearbyOffers.some((o) => o.job.sfid == offer.job.sfid),
          )
          .map((offer) => ({
            ...offer,
            accepted: false,
            acceptLoading: false,
            distance_from_ref_job: (offer.distance_from_ref_job / 1000).toFixed(
              2,
            ),
          })),
      );
      this.nearbyOffers.forEach((offer) => {
        if (offer.job.sfid == acceptedOffer) {
          offer.accepted = true;
        }
      });
      if (this.nearbyOffers.length > 0) {
        this.$refs.modal.show();
      }
      this.acceptPendingOffer();
    },
    onAccept(offer, dateSelected, accountId) {
      const toggleLoading = () => {
        offer.acceptLoading = !offer.acceptLoading;
      };
      const accept = () => {
        this.$emit('accept', offer, dateSelected, accountId, toggleLoading);
      };
      accept();
    },
    onHidden() {
      this.nearbyOffers = [];
    },
    onOk(event) {
      if (this.showAcceptAll) {
        event.preventDefault();
        this.startAcceptAll();
      }
    },
    startAcceptAll() {
      this.pendingOffers = this.nearbyOffers.filter(
        ({ accepted }) => !accepted,
      );
      this.acceptPendingOffer();
    },
    acceptPendingOffer() {
      const offer = this.pendingOffers.shift();
      if (offer) {
        this.onAccept(offer, null, this.user?.account.id);
      }
    },
  },
};
</script>
