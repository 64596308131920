<template>
  <div>
    <validating-group
      v-for="(input, index) in inputFields"
      :key="index"
      label-cols-sm="3"
      :validation-label="$t(input.label || input.name)"
      :label="`${$t(input.label || input.name)}${
        (input.rules?.required || input.rules?.includes('required')) && '*'
      }`"
      :validation-context="validation_context"
      :validation-path="input.name"
      :rules="input.rules"
      :mode="getInputValidationMode(input)"
      v-slot="{ clearErrors, state, errors }"
    >
      <b-input-group
        :class="`gid-field-wrapper ${
          errors?.length ? 'is-invalid border-danger' : ''
        }`"
      >
        <b-form-select
          v-if="input.select"
          v-model="form[input.name]"
          @input="
            form[input.name] === '' && clearErrors(true);
            $emit('handleFormValueUpdate', $event, input.name);
          "
          :state="state"
          :options="input.select"
        >
          <template #first>
            <option :value="null" disabled>{{ $t('no-selection') }}</option>
          </template>
        </b-form-select>
        <b-form-input
          v-model="form[input.name]"
          @input="
            form[input.name] === '' && clearErrors(true);
            $emit('handleFormValueUpdate', $event, input.name);
          "
          :state="state"
          :type="getInputType(input.name, input.type)"
          :class="`border-0 ${
            !input.name.startsWith('password') ? '' : 'field password'
          }`"
          :placeholder="
            input?.placeholder &&
            $t(
              `signup-form.placeholder.${input.placeholder.key}`,
              input.placeholder?.props,
            )
          "
        />
        <button
          v-if="input.name.startsWith('password')"
          type="button"
          @click="toggleShowPassword(input.name)"
          class="bg-none bg-white border-0 rounded-right pr-3"
        >
          <template v-if="showPassword(input.name)">
            <BIconEyeSlash />
          </template>
          <template v-else>
            <BIconEye />
          </template>
        </button>
        <template #append></template>
      </b-input-group>
    </validating-group>
  </div>
</template>

<script>
import { BIconEye, BIconEyeSlash } from 'bootstrap-vue';

const PW_MIN_LENGTH = 6;

export default {
  components: {
    BIconEye,
    BIconEyeSlash,
  },
  props: {
    validation_context: String,
    form: Object,
  },
  created() {
    Object.keys(this.$route.query).map((param) => {
      if (typeof this.form[param] !== 'undefined') {
        this.form[param] = this.$route.query[param];
      }
    });
  },
  data() {
    return {
      show_password: false,
      show_password_repeat: false,
      password_match: null,
      inputFields: [
        {
          name: 'first_name',
          label: 'first-name',
          rules: 'required|alpha',
        },
        {
          name: 'last_name',
          label: 'last-name',
          rules: 'required|alpha',
        },
        {
          name: 'phone',
          rules: 'required|phone_regex',
          placeholder: { key: 'phone' },
        },
        {
          name: 'email',
          label: 'e-mail',
          rules: 'required|email',
          type: 'email',
          placeholder: { key: 'email' },
        },
        {
          name: 'company_name',
          label: 'company',
          rules: 'required',
          placeholder: { key: 'company_name' },
        },
        {
          name: 'password',
          label: 'password',
          rules: 'required|password_regex|min:6',
          type: this.show_password ? 'text' : 'password',
          placeholder: { key: 'password', props: { minLength: PW_MIN_LENGTH } },
        },
        {
          name: 'password_repeat',
          label: 'repeat-password',
          rules: 'required|confirmed:password',
          type: this.show_password_repeat ? 'text' : 'password',
          placeholder: { key: 'password', props: { minLength: PW_MIN_LENGTH } },
        },
      ],
    };
  },
  methods: {
    getInputType(inputName, inputType) {
      if (['password', 'password_repeat'].includes(inputName)) {
        return this[`show_${inputName}`] ? 'text' : 'password';
      }
      return inputType || 'text';
    },
    getInputValidationMode(input) {
      let mode = 'passive';
      if (this.form[input.name]?.length) {
        mode = 'lazy';
      }
      return mode;
    },
    toggleShowPassword(inputName) {
      this[`show_${inputName}`] = !this[`show_${inputName}`];
    },
    showPassword(inputName) {
      return (
        (inputName === 'password' && this.show_password) ||
        (inputName.endsWith('repeat') && this.show_password_repeat)
      );
    },
  },
};
</script>

<style lang="scss">
@import '@gid/vue-common/scss/_variables.scss';
@import '@/assets/scss/_variables.scss';

.gid-field-wrapper {
  transition: box-shadow 0.1s ease;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem;
  overflow: hidden;
  width: 100%;

  &:focus-within {
    border-radius: 0.375rem;
    box-shadow: $box-shadow-primary;
  }

  & .field {
    &.password {
      border: none;

      &:focus {
        box-shadow: none;
      }

      & + button {
        border: none;
      }
    }
  }

  &.is-invalid {
    &:focus-within {
      box-shadow: $box-shadow-danger;
    }
  }
}
</style>
