<template>
  <div class="page-container">
    <div v-if="offer && !error" class="mx-auto">
      <b-row class="justify-content-center">
        <b-col md="10" xl="5">
          <p class="paragraph font-weight-bold">
            Vielen Dank für Ihr Interesse, es geht um folgende Aufträge:
          </p>
          <p class="font-weight-bold paragraph">
            {{ offer.is_installation ? 'Installation' : 'Precheck' }} Auftrag
            zur Umsetzung bei unserem Kunden (PLZ
            {{ offer.customer.shipping_address.postal_code }} Customer)
          </p>
          <b-row
            class="mb-2 mb-md-0"
            v-for="(service, index) in offer.services"
            :key="index"
          >
            <b-col cols="12" md="6">
              {{ service.description.de }}
            </b-col>
            <b-col cols="3" md="2">
              {{ service.quantity }}
              {{ service.unit_measure === 'Count' ? '' : service.unit_measure }}
              ×
            </b-col>
            <b-col cols="3" md="2">€{{ service.price_purchasing }}</b-col>
            <b-col class="font-weight-bold" cols="3" md="2">
              €{{ service.price_purchasing_sum }}
            </b-col>
          </b-row>
          <b-row class="justify-content-end font-weight-bold h5">
            <b-col cols="4" md="2" class="text-right">Price :</b-col>
            <b-col cols="5" md="2">
              €{{ offer.job.service_price_purchasing_sum }}
            </b-col>
          </b-row>
          <br />
          <p class="font-weight-bold paragraph">
            Um Zugang zum Auftrag zu bekommen, legen Sie bitte einen User und
            Passwort für Ihre Firma {{ company_name }} an:
          </p>
          <partner-form
            v-if="refresh_token"
            :refresh_token="refresh_token"
            :job_id="job_id"
            redirect_path="offer"
            @error="showToast"
          />
        </b-col>
      </b-row>
    </div>
    <div v-if="error">
      <partner-update-job-taken-form
        :refresh_token="refresh_token"
        redirect_path="offers"
        @error="showToast"
      />
    </div>
  </div>
</template>

<script>
import { GENERATE_ACCESS_TOKEN } from '@gid/vue-common/store/auth.module/types';
import axios from 'axios';
import PartnerForm from '@/components/PartnerAcceptanceForm.vue';
import PartnerUpdateJobTakenForm from '@/components/PartnerUpdateJobTakenForm.vue';

export default {
  components: {
    PartnerForm,
    PartnerUpdateJobTakenForm,
  },
  props: {
    job_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      refresh_token: null,
      offer: null,
      company_name: '',
      error: false,
    };
  },
  async mounted() {
    if (this.$route.query.refresh_token) {
      try {
        await this.$store.dispatch(GENERATE_ACCESS_TOKEN, {
          refresh_token: `${this.$route.query.refresh_token}`,
        });

        this.refresh_token = this.$route.query.refresh_token;
      } catch (error) {
        console.log('error', error);
      }

      this.loadData();
    }
  },
  methods: {
    loadData() {
      axios
        .get(`/api/partner/offers/${this.job_id}`)
        .then((response) => {
          this.offer = response.data;
        })
        .catch(() => {
          this.error = true;
        });
    },
    showToast(message) {
      this.$bvToast.toast(message, {
        title: 'ERROR',
        variant: 'danger',
        noAutoHide: false,
      });
    },
    handleAuthChange() {
      if (
        this.$store.state.auth &&
        this.$store.state.auth.user &&
        this.$store.state.auth.user.account
      ) {
        this.company_name = this.$store.state.auth.user.account.name;
      }
    },
  },
  watch: {
    '$store.state.auth': {
      handler: 'handleAuthChange',
      deep: true,
    },
  },
};
</script>

<style>
.page-container {
  max-width: 1440px;
  margin: auto;
  margin-top: 20px;
}
.paragraph {
  font-size: 18px;
}

@media screen and (max-width: 460px) {
  .paragraph {
    font-size: 16px;
  }
}
</style>
