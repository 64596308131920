<template>
  <div class="gid-search">
    <b-icon-search class="gid-search__icon" />
    <b-form-input
      type="search"
      v-model="search"
      :placeholder="$t('main-search-label')"
      class="rounded-pill gid-search__input"
      @focus="onFocus"
      ref="searchInput"
      autocomplete="off"
    />
    <div
      v-if="search && hasFocus"
      class="border shadow bg-white rounded gid-search__results"
    >
      <div v-if="loading" class="text-center p-2">
        <b-spinner variant="secondary" class="gid-spinner--button"></b-spinner>
      </div>
      <template v-else>
        <div v-for="(item, index) in navItemsFiltered" :key="index">
          <b-link
            :to="item.route"
            active-class=""
            class="p-2 bg-light d-flex align-items-center sticky-top"
          >
            <b-icon v-if="!item.externalIcon" :icon="item.icon" class="mr-1" />
            <component
              v-else
              class="bi bi-icon bi-custom-icon mr-1"
              :is="item.icon"
            />
            {{ $t(item.i18nKey) }}
            <b-badge pill variant="primary" class="ml-auto">
              {{ item.results.count }}
            </b-badge>
          </b-link>
          <b-link
            v-for="job in item.results.items"
            :key="job.id"
            :to="job.route"
            class="d-block p-2 text-primary"
          >
            {{ job.name }}
          </b-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { debounce } from 'lodash';
import { checkRouteAccess } from '@gid/vue-common/store/auth.module';
import {
  BIcon,
  BIconPauseCircle,
  BIconCalendar2Check,
  BIconCheck2Circle,
  BIconTools,
  BIconHourglassSplit,
  BIconCalendarX,
  BIconStar,
  BIconCashStack,
  BIconBuilding,
  BIconSearch,
} from 'bootstrap-vue';
import { NAV_ITEMS } from '../views/layouts/Auth.vue';
import SignatureIcon from '../assets/icons/signature-icon.svg?component';
import { mapGetters } from 'vuex';

export default {
  components: {
    SignatureIcon,
    BIcon,
    BIconPauseCircle, // eslint-disable-line
    BIconCalendar2Check, // eslint-disable-line
    BIconCheck2Circle, // eslint-disable-line
    BIconTools, // eslint-disable-line
    BIconCalendarX, // eslint-disable-line
    BIconStar, // eslint-disable-line
    BIconCashStack, // eslint-disable-line
    BIconBuilding, // eslint-disable-line
    BIconHourglassSplit, // eslint-disable-line
    BIconSearch,
  },
  data() {
    return {
      navItems: JSON.parse(JSON.stringify(NAV_ITEMS)),
      search: null,
      loading: false,
      results: null,
      hasFocus: false,
      throttledSearch: debounce(this.doSearch, 400),
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'user']),
    navItemsFiltered() {
      return this.navItems
        .filter((item) => {
          // exclude irrelevant routes
          if (['offers', 'invoices'].includes(item.route.name)) {
            return false;
          }
          // exclude order types with no results
          if (!(this.results?.[this.jobTypeFromRoute(item.route)]?.count > 0)) {
            return false;
          }
          // exclude routes without access
          const resolved = this.$router.resolve(item.route);
          if (resolved) {
            return checkRouteAccess({
              route: resolved.route,
              user: this.user,
              isAuthenticated: this.isAuthenticated,
            });
          } else {
            return false;
          }
        })
        .map((navItem) => {
          const jobType = this.jobTypeFromRoute(navItem.route);
          const results = this.results[jobType];
          let routeName = 'order';
          if (jobType === 'completed') {
            routeName = 'order-complete';
          } else if (jobType === 'final-confirmation') {
            routeName = 'order-final-confirmation';
          } else if (jobType === 'customer-signature') {
            routeName = 'order-customer-signature';
          }
          results.items = results.items.map((job) => ({
            ...job,
            test: 'test',
            route: {
              name: routeName,
              params: {
                job_id: job.id,
                type: jobType,
              },
            },
          }));
          const mapped = {
            ...navItem,
            results,
          };
          mapped.route.query = {
            search: this.search,
          };
          return mapped;
        });
    },
  },
  watch: {
    search() {
      this.throttledSearch();
    },
  },
  methods: {
    doSearch() {
      if (this.search) {
        this.loading = true;
        axios
          .get(`/api/partner/jobs-search/${this.search}`)
          .then((response) => {
            this.results = response.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    jobTypeFromRoute(route) {
      let jobType = route.name;
      if (route.params?.type) {
        jobType += `-${route.params.type}`;
      }
      return jobType.substring(7);
    },
    onFocus() {
      this.hasFocus = true;
      window.addEventListener('click', this.looseFocus);
    },
    looseFocus(event) {
      if (event.target != this.$refs.searchInput.$el) {
        window.removeEventListener('click', this.looseFocus);
        this.hasFocus = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@gid/vue-common/scss/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.gid-search {
  position: relative;

  .bi-custom-icon {
    max-width: 1.25rem;
    width: 100%;
    height: 1.25rem;
  }

  &__icon {
    position: absolute;
    margin: 0.75rem 0 0 0.75rem;
  }

  & &__input {
    padding-left: 2rem;
  }

  &__results {
    position: absolute;
    z-index: $zindex-popover;
    top: 3.3rem;
    width: calc(100% - 1rem);
    max-height: 30rem;
    overflow: auto;
  }
}
</style>
