<template>
  <div>
    <div class="sticky-top float-right pt-2 pl-2 gid-results-count__wrapper">
      <div class="rounded-pill gid-results-count">
        <span :class="{ 'gid-results-count--loading': loading }">
          <template v-if="totalRows !== null">
            {{ $t('results') }}: {{ orders.length }} / {{ totalRows || 0 }}
          </template>
          <BIconThreeDots v-else />
        </span>
      </div>
    </div>
    <b-button
      v-if="!past && !waiting && !customerSignature"
      pill
      variant="light"
      class="float-right text-primary border border-primary"
      style="margin-top: 0.5rem"
      :href="allProtocolsUrl"
      target="_blank"
      @click="gaBulkDownload"
      :disabled="!hasOrdersWithProtocols"
    >
      <BIconFileEarmarkText />
      {{ $t('orders-list.all-protocols') }}
    </b-button>
    <div class="clearfix"></div>
    <div class="my-3">
      <h5 v-if="!customerSignature">{{ $t('search-orders') }}</h5>
      <h5 v-else>{{ $t('request-customer-signature') }}</h5>
      <b-input-group>
        <b-form-input
          v-model="search"
          :placeholder="
            customerSignature
              ? $t('enter-orders-search-customer-signature')
              : $t('enter-orders-search')
          "
          debounce="400"
          @change="gaTrackSearch"
        />
        <b-input-group-append v-if="search">
          <b-button variant="outline-secondary" @click="search = null">
            &times;
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <quick-search
        v-if="!customerSignature"
        class="overflow-auto text-nowrap"
        v-model="filterAdvancedSearch"
        :filter-combinations="filterCombinations"
      />
      <p class="text-muted border border-primary mb-0 mt-3 p-3" v-else>
        {{ $t('customer-signature-quality') }}
      </p>
    </div>

    <b-container fluid class="border" ref="wrapper">
      <b-row class="py-4 d-none d-lg-flex border-bottom text-muted">
        <b-col v-if="hasAccountColumn" :lg="ordersGrid.account.lg">
          Account
        </b-col>
        <b-col>{{ $t('ordernum-order') }}</b-col>
        <b-col>{{ $t('order-partner-eid') }}</b-col>
        <b-col>
          <b-button
            variant="link"
            class="p-0 text-decoration-none text-muted"
            @click="order('appointment_date')"
          >
            {{ $t('date-and-time') }}
            <b-icon-arrow-down-up
              v-if="sortBy !== 'appointment_date'"
              style="opacity: 0.3"
            />
            <b-icon-arrow-down v-else-if="sortDir" />
            <b-icon-arrow-up v-else />
          </b-button>
        </b-col>
        <b-col class="text-left">
          {{ $t('customer-address-phone') }}
        </b-col>
        <b-col v-if="ordersGrid.status">
          <b-button
            variant="link"
            class="p-0 text-decoration-none text-muted"
            @click="order('days_since_status')"
          >
            {{ $t('status') }}
            <b-icon-arrow-down-up
              v-if="sortBy !== 'days_since_status'"
              style="opacity: 0.3"
            />
            <b-icon-arrow-down v-else-if="sortDir" />
            <b-icon-arrow-up v-else />
          </b-button>
        </b-col>
        <b-col>
          {{ $t(past ? 'status' : 'action') }}
        </b-col>
      </b-row>
      <div v-if="!loading && orders.length == 0">
        <p class="text-muted text-center m-5">{{ $t('orders-list-empty') }}</p>
      </div>
      <order-in-list
        v-for="(order, index) in orders"
        :key="index"
        :order="order"
        :type="type"
        :statuses="statuses"
        :displayAccount="hasAccountColumn"
        :class="{ 'border-bottom': loading || index != orders.length - 1 }"
        @delegate="$refs.delegate.show(order.job.sfid)"
        @removed="jobRemoved(index)"
      />

      <template v-if="loading">
        <order-skeleton
          v-for="i in loading"
          :key="`skeleton-${i}`"
          :type="type"
          :class="{ 'border-bottom': i != loading }"
        />
      </template>
    </b-container>

    <delegate-order ref="delegate" @success="loadData" />
  </div>
</template>

<script>
import axios from 'axios';
import Api from '@gid/vue-common/api';
import _ from 'lodash';

import { mapState, mapGetters } from 'vuex';
import { getSalutationIntro } from '@/app/utils';
import { COUNTS_SET_SINGLE, COUNTS_REFRESH } from '@/store/sidebar.module';
import InfiniteScrollMixin from '@gid/vue-common/components/mixins/InfiniteScrollMixin';

import DelegateOrder from './DelegateOrder';
import OrderInList from './OrderInList';
import OrderSkeleton from './OrderSkeleton';
import QuickSearch from '@gid/vue-common/components/filters/QuickSearch.vue';
import { JobTypeEnum, AccountStatusEnum } from '@gid/models';
import {
  BIconArrowUp,
  BIconArrowDown,
  BIconArrowDownUp,
  BIconFileEarmarkText,
  BIconThreeDots,
} from 'bootstrap-vue';

export default {
  name: 'orders',
  mixins: [InfiniteScrollMixin],
  components: {
    DelegateOrder,
    OrderInList,
    OrderSkeleton,
    QuickSearch,
    BIconArrowUp,
    BIconArrowDown,
    BIconArrowDownUp,
    BIconFileEarmarkText,
    BIconThreeDots,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      orders: [],
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      statuses: {},
      loading: 0,
      hasOrdersWithProtocols: false,
      dismissCountDown: 0,
      sortBy: null,
      sortDir: true,
      filterAdvancedSearch: {},
      filterCombinations: [
        {
          text: this.$t('filters.precheck'),
          type: 'job_type',
          value: JobTypeEnum.PRECHECK,
        },
        {
          text: this.$t('filters.remote-precheck'),
          type: 'job_type',
          value: 'rpr',
        },
        {
          text: this.$t('filters.onsite-precheck'),
          type: 'job_type',
          value: 'onsitepr',
        },
        {
          text: this.$t('filters.installation'),
          type: 'job_type',
          value: JobTypeEnum.INSTALLATION,
        },
        {
          text: this.$t('remote-job'),
          type: 'job_type',
          value: JobTypeEnum.REMOTE,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['locale', 'access_token', 'user']),
    fullName() {
      const { first_name, last_name } = this.user?.contact;
      return first_name ? `${first_name} ${last_name}` : last_name;
    },
    past() {
      return this.type === 'completed';
    },
    customerSignature() {
      return this.type === 'customer-signature';
    },
    waiting() {
      return this.type === 'waiting';
    },
    titleI18nKey() {
      return (this.type || 'open') + '-orders';
    },
    hasAccountColumn() {
      return (
        this.user?.is_partner_admin_and_master_account ||
        this.user?.is_company_owner_and_master_account
      );
    },
    ordersGrid() {
      if (!this.waiting && !this.past && this.hasAccountColumn) {
        return {
          account: { lg: '2' },
          order: { lg: '2' },
          date: { lg: '2' },
          customer: { lg: '2' },
          status: { lg: '2' },
          action: { lg: '2' },
        };
      }

      if (!this.waiting && !this.past) {
        return {
          order: { lg: '3' },
          date: { lg: '2' },
          customer: { lg: '2' },
          status: { lg: '2' },
          action: { lg: '3' },
        };
      }

      if (this.hasAccountColumn) {
        return {
          account: { lg: '2' },
          order: { lg: '2' },
          date: { lg: '3' },
          customer: { lg: '3' },
          action: { lg: '2' },
        };
      }
      return {
        order: { lg: '3' },
        date: { lg: '3' },
        customer: { lg: '3' },
        action: { lg: '3' },
      };
    },
    allProtocolsUrl() {
      let params = new URLSearchParams({
        type: this.type,
        jwt: this.access_token,
      });
      if (this.search) {
        params.append('search', this.search);
      }
      if (Object.keys(this.filterAdvancedSearch).length > 0) {
        params.append('search_json', JSON.stringify(this.filterAdvancedSearch));
      }
      return `/api/partner/orders/protocols?${params}`;
    },
  },
  watch: {
    titleI18nKey(value) {
      this.$store.commit('title', this.$t(value));
    },
    type() {
      this.defaultOrder();
      this.loadData();
    },
    search() {
      this.loadData();
    },
    filterAdvancedSearch() {
      this.loadData();
    },
    $route() {
      this.setSearchFromRoute();
    },
    totalRows(count) {
      if (
        count !== null &&
        !this.search &&
        Object.keys(this.filterAdvancedSearch).length == 0
      ) {
        this.$store.commit(COUNTS_SET_SINGLE, { type: this.type, count });
      }
    },
  },
  methods: {
    order(field) {
      this.sortBy = field;
      this.sortDir = !this.sortDir;
      this.loadData();
    },
    defaultOrder() {
      if (['scheduled', 'at-work'].includes(this.type)) {
        this.sortBy = 'appointment_date';
        this.sortDir = false;
      } else if (this.type == 'open') {
        this.sortBy = 'days_since_status';
        this.sortDir = true;
      } else {
        this.sortBy = null;
      }
    },
    jobRemoved(index) {
      this.$delete(this.orders, index);
      this.$store.dispatch(COUNTS_REFRESH);
    },
    loadData(append = false) {
      if (!append) {
        this.totalRows = null;
        this.orders = [];
      }
      if (this.totalRows !== null && this.orders.length >= this.totalRows) {
        return;
      }
      this.loading = (this.totalRows ?? this.perPage) - this.orders.length;
      if (this.loading > this.perPage) {
        this.loading = this.perPage;
      }

      let params = {
        type: this.type,
        page_size: this.perPage,
        page_number: this.currentPage - 1,
        sort_by: this.sortBy,
        sort_dir: this.sortDir ? 'desc' : 'asc',
        search: this.search,
        excluding: this.orders.map(({ api_id }) => api_id),
      };
      if (this.filterAdvancedSearch) {
        params = {
          ...params,
          search_json: JSON.stringify(this.filterAdvancedSearch),
        };
      }
      Api.post(`/api/partner/orders`, params, {
        autoCancelScope: `/api/partner/orders`,
      }).then((response) => {
        const setData = (invoiceResponse = null) => {
          const orders = response.data.data;
          if (invoiceResponse && invoiceResponse.data.length) {
            const invoicesByJobId = _.groupBy(
              invoiceResponse.data,
              (e) => e.job,
            );
            for (const order of orders) {
              order.invoices = invoicesByJobId[order.job.sfid];
            }
          }
          this.totalRows =
            this.orders.length + response.data.pagination.total_count;
          this.orders.push(...orders);
          this.loading = 0;
          this.hasOrdersWithProtocols =
            response.data.extra.protocol_visible.length > 0;
        };
        if (this.past) {
          const orderIds = response.data.data.map(
            (jobView) => jobView.job.sfid,
          );
          if (orderIds.length) {
            axios
              .get(`/api/partner/invoices`, {
                params: {
                  job: orderIds,
                },
              })
              .then((invoicesResponse) => {
                setData(invoicesResponse.data);
              });
          } else {
            setData();
          }
        } else {
          setData();
        }
      });
    },
    gaTrackSearch() {
      this.$gtag.event('As you type', {
        event_category: 'Search',
        event_label: this.search,
      });
    },
    gaBulkDownload() {
      const { first_name, last_name } = this.user?.contact;
      this.$gtag.event('Collective pdf download', {
        event_category: 'Opportunity',
        event_label: `${first_name} ${last_name}`,
      });
    },
    getSalutationIntro,
    setSearchFromRoute() {
      if (this.$route.query.search && this.$route.query.search != this.search) {
        this.search = this.$route.query.search;
      }
    },
  },
  created() {
    this.setSearchFromRoute();
    this.defaultOrder();
    if (
      ![
        AccountStatusEnum.ACTIVE,
        AccountStatusEnum.BEGINNER_ONBOARDING,
        AccountStatusEnum.REPEATED_ONBOARDING,
      ].includes(this.user?.account.account_status)
    ) {
      this.$router.push('/offers');
    } else {
      this.loadData();
      axios.get(`/api/partner/orders/statuses`).then((response) => {
        let newStatuses = {};
        response.data.forEach((status) => {
          newStatuses[status.job_status] = status.partner_name[this.locale];
        });
        this.statuses = newStatuses;
      });
    }
  },
};
</script>
