import { Module } from 'vuex-module-decorators';
import { CrudList } from '@gid/vue-common/store/shared/crud-list.module';
import { PartnerCompanyDocTypesEnum, PartnerCompanyDocs } from '@gid/models';

const moduleName = 'companyDocs';
@Module({ name: moduleName, namespaced: true })
export class CompanyDocsModule extends CrudList<PartnerCompanyDocs> {
  crudEndpoint = '/data-api/partner-company-docs';

  get missingDocumentErrors() {
    const errors: any[] = [];
    if (
      this.allItems.some(
        ({ lifetimeStart, lifetimeEnd }) =>
          (lifetimeStart && new Date(lifetimeStart) > new Date()) ||
          (lifetimeEnd && new Date(lifetimeEnd) < new Date()),
      )
    ) {
      errors.push({ name: 'company-page.documents.error.expired-documents' });
    }

    const availableTypes = this.allItems.map(({ type }) => type);
    if (
      !availableTypes.includes(PartnerCompanyDocTypesEnum.LIABILITY_INSURANCE)
    ) {
      errors.push({
        name: 'company-page.documents.error.missing-document',
        type: `company-page.documents.types.${PartnerCompanyDocTypesEnum.LIABILITY_INSURANCE}`,
      });
    }
    if (!availableTypes.includes(PartnerCompanyDocTypesEnum.TRADE_LICENSE)) {
      errors.push({
        name: 'company-page.documents.error.missing-document',
        type: `company-page.documents.types.${PartnerCompanyDocTypesEnum.TRADE_LICENSE}`,
      });
    }
    if (
      !availableTypes.includes(PartnerCompanyDocTypesEnum.TAX_EXEMPTION_ORDER)
    ) {
      errors.push({
        name: 'company-page.documents.error.missing-document',
        type: `company-page.documents.types.${PartnerCompanyDocTypesEnum.TAX_EXEMPTION_ORDER}`,
      });
    }

    return errors;
  }
}
export default CompanyDocsModule;
