<template>
  <div>
    <h1 class="text-primary text-uppercase">
      {{
        $t(
          `signup-form.api-response.email-sent-${
            emailWasSent ? 'success' : 'error'
          }.heading`,
        )
      }}
    </h1>
    <p class="my-5">
      {{
        $t(
          `signup-form.api-response.email-sent-${
            emailWasSent ? 'success' : 'error'
          }.paragraph-1`,
        )
      }}
    </p>
    <slot />
    <i18n
      v-if="!emailWasSent"
      path="signup-form.api-response.email-sent-error.paragraph-2"
      tag="p"
      class="mt-5"
    >
      <template v-slot:phoneNumber>
        <a href="tel:030549071240">030 549 071 240</a>
      </template>
      <template v-slot:email>
        <a href="mailto:partner@getitdone.rocks">partner@getitdone.rocks</a>
      </template>
    </i18n>
  </div>
</template>

<script>
export default {
  props: {
    emailWasSent: Boolean,
  },
};
</script>
