<template>
  <b-row class="justify-content-center">
    <b-col cols="12" md="8" lg="5">
      <p>{{ $t('job-taken-form.ups') }}</p>
      <br />
      <p>
        {{ $t('job-taken-form.description-1') }}
      </p>
      <br />
      <p>
        {{ $t('job-taken-form.description-2') }}
      </p>
      <br />
      <p>
        <i18n path="job-taken-form.description-3" tag="span">
          <template #phone>
            <a href="tel://+4930577113980">030 577 113 980</a>
          </template>
          <template #mail>
            <a :href="`mailto:partner-onboarding@getitdone.rocks`">
              partner-onboarding@getitdone.rocks
            </a>
          </template>
        </i18n>
      </p>
      <br />
      <Signup :isUpdate="true" @submit="submitForm" />
    </b-col>
  </b-row>
</template>

<script>
import Signup from '@/views/auth/Signup.vue';
import { AccountStatusEnum } from '@gid/models';
import PartnerPotentialOffersMixin from '@gid/vue-common/components/mixins/PartnerPotentialOffersMixin';

export default {
  mixins: [PartnerPotentialOffersMixin],
  components: {
    Signup,
  },
  props: {
    redirect_path: {
      type: String,
      required: true,
    },
    refresh_token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: false,
    };
  },
  methods: {
    async submitForm(data) {
      try {
        await this.changePassword(data.password);
        const payload = {
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          account: {
            id: this.$store.state.auth.user.account.id,
          },
        };
        const sanitizedNumber = data.phone.replace(/\s+/g, '');

        await this.updateContact(payload);
        await this.updateAccount({
          status: AccountStatusEnum.NEW,
          name: data.company_name,
          phone: sanitizedNumber,
          hasAcceptedMarketingConsent: data.accept_marketing_consent,
          hasAcceptedTerms: data.accept_tos,
        });
        if (!this.error) this.afterFormSubmitted();
      } catch (error) {
        this.error = true;
        this.$emit(
          'error',
          error?.response?.data?.msg || error?.response?.statusText,
        );
      }
    },
    redirect(contactProfiles) {
      if (
        contactProfiles.length == 1 &&
        contactProfiles.includes('accounting')
      ) {
        this.$router.push({ name: 'orders-completed' });
      } else {
        this.$router.push({ name: 'offers' });
      }
    },
  },
};
</script>
