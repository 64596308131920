var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company-container"},[_c('h2',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t('company-page.heading')))]),_c('b-row',{staticClass:"my-3 d-flex justify-content-space-evenly"},_vm._l((_vm.items),function(item){return _c('b-col',{key:item.route,staticClass:"my-2 d-flex justify-content-center text-center",attrs:{"cols":"5","md":"4","lg":"3"}},[_c('router-link',{attrs:{"to":`/company/${item.route}`}},[_c('div',{staticClass:"card-container text-center d-flex flex-column justify-content-center align-items-center"},[_c('div',{staticClass:"w-100 card bg-primary justify-content-center text-white border-0"},[_c('img',{attrs:{"src":item.iconRoute}})]),_c('p',{staticClass:"text-body mt-3"},[_vm._v(" "+_vm._s(_vm.$t(`company-page.${item.name}`))+" "),(
                item.badge && (_vm.displayMissingDataBadge || _vm.hasMissingDocuments)
              )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm.$t(
                  `account-missing-${
                    _vm.displayMissingDataBadge ? 'activation' : 'docs'
                  }`,
                )}},[_c('b-badge',{staticClass:"mx-2",attrs:{"variant":item.badge.variant}},[_vm._v(" "+_vm._s(_vm.$t(item.badge.text))+" ")])],1):_vm._e()])])])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }