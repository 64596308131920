import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import { storeCommon, storeCommonInit } from '@gid/vue-common/store';
import CreditNotesModule from './credit-notes.module';
import SidebarModule from './sidebar.module';
import CompanyDocsModule from './company-docs.module';
import opportunities_module from '@gid/vue-common/store/opportunities.module';

Vue.use(Vuex);

const defaultTitle = 'GID Partner Portal';
const store = new Vuex.Store({
  modules: {
    ...storeCommon.modules,
    opportunities: opportunities_module,
    creditNotes: CreditNotesModule,
    sidebar: SidebarModule,
    companyDocs: CompanyDocsModule,
  },
  plugins: [...storeCommon.plugins],
  state: {
    title: defaultTitle,
    serviceQualis: [],
    acceptedServices: [],
  },
  mutations: {
    title(state, title) {
      if (title) {
        state.title = title;
        document.title = `${defaultTitle} - ${title}`;
      } else {
        state.title = defaultTitle;
        document.title = defaultTitle;
      }
    },

    setAcceptedServices(state, payload) {
      state.acceptedServices = [...payload];
    },
  },
});

storeCommonInit(store);

function unique(a, key) {
  let seen = new Set();
  return a.filter((item) => {
    let k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
}

export default store;
