<template>
  <div class="gid-tabs__wrapper">
    <router-view />
  </div>
</template>

<style lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.gid-tabs {
  &__wrapper {
    max-width: 56rem;
    margin: 0 auto;
    padding-top: $grid-gutter-width;
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width;
    }
  }
}
</style>

<script>
export default {
  computed: {
    plainLayout() {
      return this.$route.query.plain === 'true';
    },
  },
};
</script>
