<template>
  <b-row class="border-bottom py-4">
    <b-col cols="12" lg="4" class="my-2 my-lg-0">
      <div>{{ offer.job.name }}</div>
      <div>
        {{ offer.opportunity.name }} (
        <!-- <s
          class="text-muted mr-1"
          v-if="firstCancelationWithPriceChange"
        >
          {{
          firstCancelationWithPriceChange.old_price_purchasing | currency
          }}
        </s> -->
        {{ offer.job.service_price_purchasing_sum | currency }}
        )
      </div>
      <b-badge v-if="offer.is_remote">{{ $t('remote-job') }}</b-badge>
      <div>
        in
        <a
          target="_blank"
          :href="`https://maps.google.com/?q=${offer.customer.shipping_address.city}+${offer.customer.shipping_address.postal_code}`"
        >
          {{ offer.customer.shipping_address.postal_code }}
          {{ offer.customer.shipping_address.city }}
        </a>
      </div>
    </b-col>
    <b-col cols="12" lg="4" class="my-2 my-lg-0">
      <fieldset v-if="offer.job.dates && offer.job.dates.length">
        <legend>
          <div class="d-block d-lg-none">{{ $t('possible-appointments') }}</div>
        </legend>
        <div v-for="(date, index) in offer.job.dates" :key="index">
          <b-form-radio
            type="radio"
            :name="offer.job.name"
            :value="index"
            v-model="dateSelected"
          >
            <strong>{{ date.start | moment(' dd, L') }},</strong>
            <span>{{ date.start | moment('H:mm') }}</span>
            -
            <strong v-if="isMultidayPeriod(date.start, date.end)">
              {{ date.end | moment(' dd, L') }},
            </strong>
            <span>{{ date.end | moment('H:mm') }}</span>
          </b-form-radio>
          <hr class="mt-1 mb-2" v-if="index != offer.job.dates.length - 1" />
        </div>
      </fieldset>
      <i v-else>
        {{
          $t(
            offer.job.days_until_finalization_sla >= 1
              ? 'accept-offer-sla'
              : 'accept-offer-sla-expired',
            {
              days: offer.job.days_until_finalization_sla,
            },
          )
        }}
      </i>
    </b-col>
    <b-col cols="12" lg="4" class="my-2 my-lg-0 d-flex align-items-center">
      <accept-job
        :offer="this.offer"
        :dateSelected="this.dateSelected"
        :loading="acceptLoading"
        @accept="onAccept"
      />
      <b-button
        variant="outline-primary"
        :to="{ name: 'offer', params: { job_id: offer.job.sfid } }"
        class="text-uppercase ml-2"
      >
        {{ $t('details') }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import PriceHasChanged from '@gid/vue-common/components/mixins/PriceHasChanged.js';
import AcceptJob from '@/components/AcceptJob.vue';
import { mapGetters } from 'vuex';

export default {
  mixins: [PriceHasChanged],
  components: {
    AcceptJob,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dateSelected: null,
      signatureData: '',
      acceptLoading: false,
    };
  },
  computed: {
    ...mapGetters(['tandCalreadySigned']),
  },
  methods: {
    getPriceChangeConfig() {
      return {
        job_view: this.offer,
        price_purchasing: true,
      };
    },
    onAccept(offer, dateSelected, accountId) {
      const toggleLoading = () => {
        this.acceptLoading = !this.acceptLoading;
      };
      const accept = () => {
        this.$emit('accept', offer, dateSelected, accountId, toggleLoading);
      };

      accept();
    },
    accept() {
      this.$emit(
        'accept',
        this.onAcceptData.offer,
        this.onAcceptData.dateSelected,
        this.onAcceptData.accountId,
        this.onAcceptData.toggleLoading,
      );
    },
    isMultidayPeriod(start, end) {
      return !this.$moment(start).isSame(end, 'day');
    },
  },
};
</script>
