<template>
  <div>
    <pre v-if="error">{{ error }}</pre>
    <b-spinner v-else />
  </div>
</template>

<script>
import axios from 'axios';

import { STORE_ACCESS_TOKEN } from '@gid/vue-common/store/auth.module/types';

export default {
  props: {
    id: String,
  },
  data() {
    return {
      error: null,
    };
  },
  created() {
    if (this.id) {
      axios
        .get(`/api/partner/access/${this.id}`)
        .then((response) => {
          const { access_token, order_id } = response.data;
          this.$store.commit(STORE_ACCESS_TOKEN, {
            access_token: access_token,
          });
          this.$router.push({
            name: 'order-single',
            params: { job_id: order_id },
          });
        })
        .catch((error) => {
          this.error = error;
        });
    }
  },
};
</script>
