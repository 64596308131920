<template>
  <div>
    <b-breadcrumb
      v-if="$route.name != 'company-home'"
      class="p-0 mt-3 bg-transparent"
      :items="breadcrumbs"
    ></b-breadcrumb>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  computed: {
    breadcrumbs() {
      return this.$route.matched.map((route) => ({
        text: this.$t(route.meta.title.i18nKey),
        to: { name: route.name },
      }));
    },
  },
};
</script>
