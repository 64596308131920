<template>
  <div
    v-if="variant === CustomerSignatureStatusVariantEnum.PROMPT"
    class="d-flex align-items-center customer-signature-notice"
    style="gap: 2rem"
  >
    <template v-if="isCustomerSignatureMissing">
      <b-button
        :to="customerSignaturePath"
        variant="outline-warning"
        class="btn-customer-signature"
      >
        {{ $t('order-details.customer-signature.prompt-missing') }}
      </b-button>
      <p class="text-muted m-0">
        {{ $t('order-details.customer-signature.prompt-notice-missing') }}
      </p>
    </template>
    <b-button
      v-else-if="isCustomerSignatureTaken"
      disabled
      variant="outline-primary"
    >
      {{ $t('order-details.customer-signature.prompt-taken') }}
    </b-button>
    <template v-else>
      <b-button disabled variant="outline-danger">
        {{ $t('order-details.customer-signature.reason-declined') }}
      </b-button>
      <p v-if="comment" class="text-muted m-0">{{ comment }}</p>
    </template>
  </div>
  <div
    v-else-if="variant === CustomerSignatureStatusVariantEnum.REASON"
    class="d-flex align-items-center customer-signature-notice"
    style="gap: 2rem"
  >
    <template v-if="isCustomerSignatureMissing">
      <b-button variant="primary" v-b-modal="'customer-signature-modal'">
        {{ $t('order-details.customer-signature.reason-missing') }}
      </b-button>
      <p class="text-muted m-0">
        {{ $t('order-details.customer-signature.reason-notice-missing') }}
      </p>
      <b-modal
        id="customer-signature-modal"
        centered
        ok-only
        :ok-disabled="!declineReason || loading"
        @ok="declineCustomerSignature"
      >
        <template #modal-title>
          <div class="d-flex align-items-center">
            <b-icon
              variant="danger"
              class="h3 m-0 mr-2"
              icon="exclamation-triangle-fill"
            />
            {{ $t('customer-signature-modal.title') }}
          </div>
        </template>
        <template #modal-ok>
          <b-spinner v-if="loading" small></b-spinner>
          {{ $t('customer-signature-modal.ok') }}
        </template>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio
            size="lg"
            v-for="reason in declineReasonCategories"
            :key="reason"
            v-model="selectedDeclineReasonCategory"
            :aria-describedby="ariaDescribedby"
            name="customer-signature-reason"
            :value="reason"
          >
            {{ $t(`customer-signature-modal.reasons.${reason}`) }}
          </b-form-radio>
        </b-form-group>
        <b-form-textarea
          v-if="
            selectedDeclineReasonCategory ===
            CustomerSignatureReasonEnum.OTHER_REASON_PARTNER
          "
          v-model="otherDeclineReason"
          :placeholder="
            $t(`customer-signature-modal.reasons.other_reason_partner`)
          "
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </b-modal>
    </template>
    <b-button
      v-else-if="isCustomerSignatureTaken"
      disabled
      variant="outline-primary"
    >
      {{ $t('order-details.customer-signature.reason-taken') }}
    </b-button>
    <template v-else>
      <b-button disabled variant="outline-danger">
        {{ $t('order-details.customer-signature.reason-declined') }}
      </b-button>
      <p v-if="comment" class="text-muted m-0">{{ comment }}</p>
    </template>
  </div>
</template>

<script>
import {
  JobStatusEnum,
  UserRoleEnum,
  CustomerSignatureStatusEnum,
  CustomerSignatureReasonEnum,
} from '@gid/models';
import { CommentActionTypeEnum } from '@gid/models/dist/entities/comment';
import axios from 'axios';
import { BIcon, BIconExclamationTriangleFill } from 'bootstrap-vue';
import { CondOperator, RequestQueryBuilder } from '@dataui/crud-request';

export const CustomerSignatureStatusVariantEnum = Object.freeze({
  PROMPT: 'prompt',
  REASON: 'reason',
});

export default {
  components: {
    BIcon,
    // eslint-disable-next-line vue/no-unused-components
    BIconExclamationTriangleFill,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: CustomerSignatureStatusVariantEnum.PROMPT,
    },
  },
  data() {
    return {
      selectedDeclineReasonCategory: null,
      declineReasonCategories: [
        CustomerSignatureReasonEnum.MISSING_CUSTOMER,
        CustomerSignatureReasonEnum.CUSTOMER_REFUSED,
        CustomerSignatureReasonEnum.OTHER_REASON_PARTNER,
      ],
      otherDeclineReason: '',
      comment: '',
      loading: false,
      CustomerSignatureStatusVariantEnum,
      CustomerSignatureReasonEnum,
    };
  },
  computed: {
    isCustomerSignatureMissing() {
      return [CustomerSignatureStatusEnum.MISSING].includes(
        this.order.job.customer_signature,
      );
    },
    isCustomerSignatureTaken() {
      return (
        this.order.job.customer_signature === CustomerSignatureStatusEnum.TAKEN
      );
    },
    declineReason() {
      if (
        this.selectedDeclineReasonCategory ===
        CustomerSignatureReasonEnum.OTHER_REASON_PARTNER
      ) {
        return this.otherDeclineReason;
      } else if (this.selectedDeclineReasonCategory) {
        return this.$t(
          `customer-signature-modal.reasons.${this.selectedDeclineReasonCategory}`,
        );
      }
      return '';
    },
    customerSignaturePath() {
      return {
        name: 'order-customer-signature',
        params: { type: 'customer-signature', job_id: this.order.sfid },
        query: {
          redirect: true,
          type: this.$router.currentRoute.params.type,
          name: this.$router.currentRoute.name,
        },
      };
    },
  },
  async created() {
    this.loadComment();
  },
  methods: {
    async loadComment() {
      const queryString = RequestQueryBuilder.create({
        filter: [
          {
            field: `jobApiId`,
            operator: CondOperator.EQUALS,
            value: this.order.job.api_id,
          },
          {
            field: 'actionType',
            operator: CondOperator.EQUALS,
            value: CommentActionTypeEnum.CUSTOMER_SIGNATURE,
          },
        ],
        sort: [{ field: 'createdAt', order: 'DESC' }],
        limit: 1,
      }).query();

      const res = await axios.get(`/data-api/comment?${queryString}`);
      this.comment = res.data[0]?.message || '';
    },
    async declineCustomerSignature(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.loading = true;
      try {
        const setStatusRes = await axios.post(
          `/api/partner/orders/set-status`,
          {
            preview: false,
            job_id: this.order.sfid,
            new_status: this.order.job.status,
            inputs: {
              customer_signature: CustomerSignatureStatusEnum.DECLINED,
              customer_signature_reason: this.selectedDeclineReasonCategory,
            },
          },
        );
        this.$emit('signatureDeclined', setStatusRes.data);
        const commentRes = await axios.post(`/data-api/comment`, {
          actionType: CommentActionTypeEnum.CUSTOMER_SIGNATURE,
          jobApiId: this.order.job.api_id,
          message: this.declineReason,
          visibleForRoles: [
            UserRoleEnum.ADMIN,
            UserRoleEnum.CUSTOMER,
            UserRoleEnum.PARTNER,
          ],
        });
        this.comment = commentRes.data.message;
        this.$nextTick(() => {
          this.$bvModal.hide('customer-signature-modal');
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.customer-signature-notice {
  .btn-customer-signature {
    white-space: nowrap;
    color: #f98747;
    border-color: #f98747;

    &:hover {
      background-color: #f98747;
      color: #fff;
    }
  }
}
</style>
