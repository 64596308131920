<template>
  <div>
    <template v-if="variants.includes('picture')">
      <h5>{{ $t('upload-picture') }}</h5>
      <files-selector
        @input="selectedFilesBefore = $event"
        :result-as-array="true"
        :maxFiles="1"
        :ga="gaBefore"
      />
    </template>
    <template v-if="variants.includes('picture_before_after')">
      <h5>{{ $t('upload-picture-before-start') }}</h5>
      <files-selector
        @input="selectedFilesBefore = $event"
        :result-as-array="true"
        :maxFiles="1"
        :ga="gaBefore"
      />
      <h5>{{ $t('upload-picture-after-finishing') }}</h5>
      <files-selector
        @input="selectedFilesAfter = $event"
        :result-as-array="true"
        :maxFiles="1"
        :ga="gaAfter"
      />
    </template>
    <div v-if="protocolSignatureEnabled" v-show="!useUpload">
      <signature
        v-model="signatureData"
        :input-name="inputName"
        :signer-name="customerName"
        :place-of-signature="placeOfSignature"
        :ga="ga"
      />
      <p v-if="variants.includes('protocol')" class="my-2">{{ $t('or') }}</p>
    </div>
    <template v-if="variants.includes('protocol')">
      <b-form-checkbox v-if="protocolSignatureEnabled" v-model="useUpload">
        <h5>{{ $t('upload-signed-protocol') }}</h5>
      </b-form-checkbox>
      <h5 v-else>{{ $t('upload-signed-protocol') }}</h5>
      <div v-show="useUpload || !protocolSignatureEnabled">
        <p v-if="showProtocolInfo">
          {{ $t(`upload-signed-protocol-info.${documentationTypeId}`) }}
        </p>
        <files-selector
          @input="selectedFilesProtocols = $event"
          :result-as-array="true"
          :ga="gaProtocols"
        />
      </div>
    </template>
    <ValidationProvider
      ref="provider"
      mode="passive"
      :name="autoTitle"
      :vid="inputName"
      :rules="validationRules"
      v-slot="{ errors }"
      slim
    >
      <b-form-invalid-feedback :state="compoundState(errors)">
        {{ compoundFeedback(errors) }}
      </b-form-invalid-feedback>
    </ValidationProvider>
  </div>
</template>

<script>
import BaseInput from '@gid/vue-common/components/inputs/mixins/BaseInput';
import FilesSelector from '@gid/vue-common/components/inputs/FilesSelector.vue';
import ValidatingInput from '@gid/vue-common/components/mixins/ValidatingInput';
import Signature from '@gid/vue-common/components/inputs/Signature.vue';

export default {
  mixins: [BaseInput, ValidatingInput],
  components: {
    FilesSelector,
    Signature,
  },
  props: {
    placeOfSignature: String,
    variants: Array,
    ga: Object,
    documentationType: String,
    inputName: String,
    customerName: String,
    title: String,
  },
  data() {
    return {
      didInit: false,
      selectedFilesBefore: [],
      selectedFilesAfter: [],
      selectedFilesProtocols: [],
      signatureData: [],
      useUpload: false,
    };
  },
  watch: {
    selectedFilesBefore() {
      this.emitChange(
        this.selectedFilesBefore.concat(this.selectedFilesAfter),
        'picture_before_after',
      );
    },
    selectedFilesAfter() {
      this.emitChange(
        this.selectedFilesBefore.concat(this.selectedFilesAfter),
        'picture_before_after',
      );
    },
    selectedFilesProtocols() {
      this.emitChange(this.selectedFilesProtocols, 'protocol');
    },
    signatureData() {
      this.emitChange(this.signatureData, 'protocol_signature');
    },
    useUpload(value) {
      if (value) {
        this.emitChange(this.selectedFilesProtocols, 'protocol');
      } else {
        this.emitChange(this.signatureData, 'protocol_signature');
      }
    },
  },
  computed: {
    autoTitle() {
      const titleTerm = this.title || this.inputName;
      return this.$t(`inputs.headings.${titleTerm}`);
    },
    currentDate() {
      return this.$moment().format('L');
    },
    showProtocolInfo() {
      return this.documentationTypeId !== null;
    },
    documentationTypeId() {
      switch (this.documentationType) {
        case 'Bilder und Abnahmeprotokoll':
          return 'image-and-protocol';
        case 'Abnahmeprotokoll':
          return 'protocol';
        default:
          return null;
      }
    },
    protocolSignatureEnabled() {
      return this.variants.includes('protocol_signature');
    },
    gaBefore() {
      return !this.ga
        ? null
        : {
            imagesType: 'before',
            opportunityName: this.ga.opportunityName,
          };
    },
    gaAfter() {
      return !this.ga
        ? null
        : {
            imagesType: 'after',
            opportunityName: this.ga.opportunityName,
          };
    },
    gaProtocols() {
      return !this.ga
        ? null
        : {
            imagesType: 'protocol',
            opportunityName: this.ga.opportunityName,
          };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.didInit = true;
    });
  },
  methods: {
    emitChange(files, variant) {
      const value =
        files && files.length > 0
          ? {
              files: files,
              variant: variant,
            }
          : null;
      this.commitOrEmit(value);
      this.defaultClearValidation();
      if (this.didInit) {
        this.$refs.provider.validate(value);
      }
    },
  },
};
</script>
