<template>
  <div>
    <b-modal
      no-fade
      :visible="visible"
      modal-class="ob-cancel-modal"
      centered
      size="lg"
      @hide="onDialogHide"
    >
      <template #modal-header>
        <p class="title">
          {{ $t('onboarding.cancel-dialog.title') }}
        </p>
      </template>
      <p class="description">
        {{ $t('onboarding.cancel-dialog.description') }}
      </p>
      <template #modal-footer>
        <b-button
          @click="$emit('cancel')"
          variant="outline-light"
          class="gid-btn gid-btn--ghost"
          :disabled="loading"
        >
          {{ $t('onboarding.actions.cancel') }}
        </b-button>
        <b-button
          @click="$emit('continue')"
          variant="primary"
          class="gid-btn gid-btn--primary"
          :disabled="loading"
        >
          {{ $t('onboarding.actions.continue') }}
        </b-button>
        <b-button
          v-if="showSkip"
          @click="$emit('saveAndCancel')"
          variant="outline-secondary"
          class="gid-btn"
          :disabled="loading"
        >
          <b-spinner v-if="loading" small></b-spinner>
          {{ $t('onboarding.actions.save-and-cancel') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    showSkip: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onDialogHide(bvModalEvent) {
      if (bvModalEvent.trigger === 'backdrop') {
        bvModalEvent.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ob-cancel-modal {
  .modal-content {
    * {
      font-family: 'Roboto', sans-serif;
    }

    width: auto;
    margin: 0 auto;

    .modal-header {
      border-bottom: 0;
      padding: 1.4rem 1.2rem;

      .title {
        margin: 0;
        font-weight: 500;
        font-size: 1.1rem;
      }
    }

    .modal-body {
      padding: 0 1.2rem;
      max-width: 35rem;

      .description {
        margin: 0;
      }
    }

    .modal-footer {
      border-top: 0;
      padding: 2rem 1.2rem 1rem;
      justify-content: center;
      gap: 0.8rem;
    }
  }
}
</style>
