<template>
  <b-dropdown
    v-if="
      hasMoreThanOneAccountToAcceptOffer || !canAccountAcceptOffer(user.account)
    "
    variant="primary"
    toggle-class="text-uppercase"
    :disabled="loading || !canAcceptOffer"
    :size="size"
  >
    <template #button-content>
      <b-spinner v-if="loading" class="gid-spinner--button mr-2"></b-spinner>
      {{ $t('accept-offer') }}
    </template>
    <template v-if="canAccountAcceptOffer(user.account)">
      <b-dropdown-item @click="accept(user.account.id)" variant="primary">
        <account-display :accountId="user.account.id" :truncateAt="24" />
      </b-dropdown-item>
      <b-dropdown-divider v-if="childrenAccountsThatCanAcceptOffer.length" />
    </template>
    <b-dropdown-item
      v-for="child in childrenAccountsThatCanAcceptOffer"
      @click="accept(child.id)"
      :key="child.id"
    >
      <account-display :accountId="child.id" :truncateAt="24"></account-display>
    </b-dropdown-item>
  </b-dropdown>
  <b-button
    v-else
    variant="primary"
    :size="size"
    class="text-uppercase"
    @click="accept(user.account.id)"
    :disabled="loading || !canAcceptOffer"
  >
    <b-spinner v-if="loading" class="gid-spinner--button mr-2"></b-spinner>
    {{ $t('accept-offer') }}
  </b-button>
</template>

<script>
import { mapState } from 'vuex';
import AccountDisplay from '@/components/AccountDisplay.vue';

export default {
  components: {
    AccountDisplay,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
    dateSelected: {
      type: String,
    },
    size: {
      type: String,
      default: 'md',
    },
    loading: Boolean,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    canAcceptOffer() {
      return this.offer.job.dates?.length != 0
        ? this.dateSelected !== null
        : true;
    },
    childrenAccountsThatCanAcceptOffer() {
      return Object.values(this.user.children_accounts).filter((child) =>
        this.canAccountAcceptOffer(child),
      );
    },
    hasMoreThanOneAccountToAcceptOffer() {
      return this.offer.matched_partner_sfids.length > 1;
    },
  },
  methods: {
    canAccountAcceptOffer(account) {
      return (
        this.canAcceptOffer &&
        this.offer.matched_partner_sfids.some((sfid) => sfid == account.id)
      );
    },
    accept(accountId) {
      this.$emit(
        'accept',
        this.offer,
        this.dateSelected !== null
          ? this.offer.job.dates?.[this.dateSelected]
          : null,
        accountId ? accountId : this.user.account.id,
      );
    },
  },
};
</script>
