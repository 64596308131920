<template>
  <div>
    <b-navbar
      toggleable="lg"
      type="dark"
      :variant="navbarVariant"
      class="gid-header"
      ref="mainHeader"
    >
      <back-button class="" />
      <router-link class="text-center" to="/">
        <img src="@/assets/gid-logo.svg" alt="GID" class="gid-header__logo" />
        <b-badge
          v-if="environment != 'production'"
          class="ml-2"
          variant="warning"
        >
          {{ environment }}
        </b-badge>
      </router-link>
      <search class="ml-5 gid-header__search" />
      <b-button
        v-if="!updateExists"
        aria-label="Toggle navigation"
        @click="sidebarToggle"
        variant="primary"
        class="gid-navigation-toggler"
      >
        <BIconList />
      </b-button>
      <refresh-button v-if="updateExists" :onRefresh="refreshApp" />
    </b-navbar>
    <div class="gid-mobile-search sticky-top mb-n2 py-2 px-2">
      <search />
    </div>
    <div class="gid-content">
      <div
        :class="[
          'border-right',
          'gid-sidebar',
          { 'gid-sidebar-closed': !sidebarOpen },
        ]"
      >
        <div>
          <button
            class="material-icons ml-auto p-3 border-0 bg-transparent d-block d-lg-none"
            @click="sidebarClose"
          >
            close
          </button>
          <b-navbar-nav class="py-2">
            <b-nav-item
              v-for="(item, index) in navItemsFiltered"
              :key="index"
              :to="item.route"
              link-classes="px-3 d-flex justify-content-between align-items-start"
              @click="sidebarClose"
            >
              <div class="d-flex justify-content-between align-items-start">
                <b-icon
                  v-if="!item.externalIcon"
                  :icon="item.icon"
                  :variant="
                    item.route.name == 'orders-waiting-action' ? 'danger' : ''
                  "
                />
                <component
                  v-else
                  class="bi bi-icon bi-custom-icon"
                  :is="item.icon"
                />
                <span class="ml-2">{{ $t(item.i18nKey) }}</span>
              </div>
              <span
                v-if="!isNullOrUndefined(item.total)"
                class="position-relative badge-light badge-pill border"
              >
                {{ item.total }}
                <span
                  v-if="item.dot"
                  :class="`bg-${item.dot} rounded-circle gid-dot`"
                ></span>
              </span>
            </b-nav-item>
          </b-navbar-nav>
        </div>
        <AccountDropUp class="gid-sidebar-dropup" />
      </div>
      <b-container fluid>
        <slot />
        <div class="gid-footer-info d-flex align-items-center">
          <footer-info />
          <a
            href="https://www.getitdone.rocks/service-partner-agb"
            class="shadow btn rounded-pill btn-primary gid-terms-note"
            target="_blank"
          >
            {{ $t('tandc.heading') }}
          </a>
        </div>

        <AccountDropUp class="gid-sticky-dropup" />
      </b-container>
    </div>
    <Onboarding />
    <ChangeAccountDialog />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import { checkRouteAccess } from '@gid/vue-common/store/auth.module';
import update from '@gid/vue-common/components/mixins/update';
import { COUNTS_REFRESH } from '@/store/sidebar.module';
import { isNullOrUndefined } from '@gid/models/dist/transformers/common';

import FooterInfo from '@/components/FooterInfo.vue';
import AccountDisplay from '@/components/AccountDisplay.vue';
import ChangeAccountDialog from '@/components/ChangeAccountDialog.vue';
import Search from '@/components/Search.vue';
import { AccountStatusEnum } from '@gid/models/dist/entities/account-status-enum';
import { CrudListActionsEnum } from '@gid/vue-common/store/shared/crud-list.module';
import {
  BIcon,
  BIconCalendar2Check,
  BIconCalendarX,
  BIconCashStack,
  BIconCheck2Circle,
  BIconPauseCircle,
  BIconArrowClockwise,
  BIconHourglassSplit,
  BIconStar,
  BIconList,
  BIconTools,
  BIconExclamationTriangle,
} from 'bootstrap-vue';
import CompanyDocsModule from '../../store/company-docs.module';
import SignatureIcon from '../../assets/icons/signature-icon.svg?component';
import Onboarding from '@/components/onboarding/Onboarding.vue';
import AccountDropUp from '@/components/AccountDropUp.vue';

export const NAV_ITEMS = [
  {
    route: { name: 'offers' },
    i18nKey: 'new-offers',
    icon: 'star',
  },
  {
    route: { name: 'orders-waiting' },
    i18nKey: 'waiting-orders',
    icon: 'pause-circle',
  },
  {
    route: { name: 'orders-waiting-action' },
    i18nKey: 'waiting-action-orders',
    icon: 'pause-circle',
  },
  {
    route: { name: 'orders', params: { type: 'open' } },
    i18nKey: 'open-orders',
    icon: 'calendar-x',
  },
  {
    route: { name: 'orders', params: { type: 'scheduled' } },
    i18nKey: 'scheduled-orders',
    icon: 'calendar2-check',
  },
  {
    route: { name: 'orders', params: { type: 'at-work' } },
    i18nKey: 'at-work-orders',
    icon: 'tools',
  },
  {
    route: {
      name: 'orders-final-confirmation',
    },
    i18nKey: 'final-confirmation-orders',
    icon: 'hourglass-split',
  },
  {
    route: { name: 'orders-completed' },
    i18nKey: 'completed-orders',
    icon: 'check2-circle',
  },
  {
    route: { name: 'invoices' },
    i18nKey: 'invoices.name',
    icon: 'cash-stack',
  },
  {
    route: {
      name: 'orders-customer-signature',
    },
    i18nKey: 'customer-signature-orders',
    icon: 'signature-icon',
    externalIcon: true,
  },
];

export default {
  mixins: [update],
  components: {
    SignatureIcon,
    FooterInfo,
    AccountDisplay,
    ChangeAccountDialog,
    AccountDropUp,
    BIcon,
    BIconList,
    BIconPauseCircle, // eslint-disable-line
    BIconCalendar2Check, // eslint-disable-line
    BIconCheck2Circle, // eslint-disable-line
    BIconTools, // eslint-disable-line
    BIconCalendarX, // eslint-disable-line
    BIconStar, // eslint-disable-line
    BIconCashStack, // eslint-disable-line
    BIconArrowClockwise, // eslint-disable-line
    BIconHourglassSplit, // eslint-disable-line
    Search,
    BIconExclamationTriangle,
    Onboarding,
  },
  data() {
    return {
      navItems: JSON.parse(JSON.stringify(NAV_ITEMS)),
      sidebarOpen: false,
      sidebarRefreshTimer: null,
      headerObserver: null,
    };
  },
  computed: {
    ...mapState({
      accounts: (state) => state.auth.accounts,
      counts: (state) => state.sidebar.counts,
    }),
    ...mapGetters(['environment', 'user', 'isAuthenticated']),
    companyDocsStore() {
      return getModule(CompanyDocsModule, this.$store);
    },
    navbarVariant() {
      if (this.user?.profiles?.includes('readonly')) {
        return 'readonly';
      } else if (this.environment === 'production') {
        return 'light';
      } else {
        return 'staging';
      }
    },
    navItemsFiltered() {
      const navItems = this.navItems.filter((item) => {
        if (
          !this.counts.waiting.total &&
          item.route.name === 'orders-waiting'
        ) {
          return false;
        }
        if (
          !this.counts['waiting-action']?.total &&
          item.route.name === 'orders-waiting-action'
        ) {
          return false;
        }
        const resolved = this.$router.resolve(item.route);
        if (resolved) {
          return checkRouteAccess({
            route: resolved.route,
            user: this.user,
            isAuthenticated: this.isAuthenticated,
          });
        } else {
          return false;
        }
      });
      navItems.forEach((item) => {
        if (item.route.name === 'offers') {
          item.total = this.counts.offers.total;
          if (this.counts.offers.total > 0) {
            item.dot = 'primary';
          }
        } else if (item.route.name === 'orders') {
          item.total = this.counts[item.route.params.type].total;
          if (this.counts[item.route.params.type].danger > 0) {
            item.dot = 'danger';
          } else if (this.counts[item.route.params.type].warning > 0) {
            item.dot = 'warning';
          }
        } else if (item.route.name === 'orders-final-confirmation') {
          item.total = this.counts['final-confirmation'].total;
          if (item.total) {
            item.dot = 'danger';
          }
        } else if (item.route.name === 'orders-waiting') {
          item.total = this.counts.waiting.total;
        } else if (item.route.name === 'orders-waiting-action') {
          item.total = this.counts?.['waiting-action']?.total;
          if (item.total > 0) {
            item.dot = 'danger';
          }
        }
      });
      return navItems;
    },
  },
  methods: {
    sidebarToggle() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    sidebarClose() {
      this.sidebarOpen = false;
    },
    isNullOrUndefined,
    initHeaderResizeObserver() {
      const header = this.$refs.mainHeader;

      this.headerObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.target === header.$el) {
            document.documentElement.style.setProperty(
              '--header-height',
              `${entry.borderBoxSize[0].blockSize}px`,
            );
          }
        }
      });

      this.headerObserver.observe(header.$el);
    },
  },
  created() {
    if (this.user?.account?.account_status == AccountStatusEnum.NEW) {
      this.$router.push({ name: 'offers' });
    }
    this.$store.dispatch(COUNTS_REFRESH);
    this.companyDocsStore[CrudListActionsEnum.ITEMS_FETCH]({ query: {} });
  },
  mounted() {
    this.sidebarRefreshTimer = setInterval(() => {
      if (document.visibilityState == 'visible') {
        this.$store.dispatch(COUNTS_REFRESH);
      }
    }, 1000 * 60 * 6);
    this.initHeaderResizeObserver();
  },
  beforeDestroy() {
    this.headerObserver?.disconnect();
  },
  destroyed() {
    clearInterval(this.sidebarRefreshTimer);
  },
};
</script>

<style lang="scss">
@import '@gid/vue-common/scss/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

:root {
  --header-height: 55px;
}

.gid-sidebar {
  position: fixed;
  z-index: $zindex-fixed + 1;
  left: 0;
  top: 0;
  bottom: 0;
  background: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  margin-top: var(--header-height);

  a:not(.router-link-active) {
    color: inherit;
    &:hover {
      color: $primary;
    }
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    transition: 0.3s transform;
    margin-top: 0;

    &-closed {
      transform: translateX(-100%);
    }

    .gid-sidebar-dropup {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    width: 240px;
  }

  .nav-link {
    position: relative;

    &.router-link-active {
      background: $gray-200;
    }
    .badge-pill {
      line-height: 1.5rem;
      font-size: 0.9em;
    }
    .gid-dot {
      position: absolute;
      top: 0rem;
      right: -0.3rem;
      width: 0.7rem;
      height: 0.7rem;
    }
  }
}

.gid-header {
  &.navbar {
    position: sticky;
    top: 0;
    flex-wrap: nowrap;
    z-index: 1032;
    padding: 0.7rem 1rem;
    gap: 0.5rem 1rem;
  }

  .gid-navigation-toggler {
    padding: 0.1rem 0.5rem;
    font-size: 1.25rem;
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &__logo {
    max-width: 12rem;
    width: 100%;
    height: auto;
  }

  &__search {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.bi-custom-icon {
  max-width: 1.25rem;
  width: 100%;
  height: 1.25rem;
}

.gid-mobile-search {
  display: none;
  background-color: white;

  &.sticky-top {
    top: calc(var(--header-height));
  }

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.gid-footer-info {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: $zindex-fixed;
  gap: 0.5rem;
}

.gid-content {
  .gid-sticky-dropup {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
  }

  .gid-terms-note {
    right: 13.5rem;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 240px;
  }
  @include media-breakpoint-down(md) {
    .gid-sticky-dropup {
      display: flex;
    }

    & {
      padding-bottom: 3.7rem;
    }

    .gid-footer-info,
    .gid-footer-info.d-flex {
      display: none;
    }
  }
}
</style>
