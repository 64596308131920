<template>
  <b-row class="py-4">
    <b-col
      v-if="hasAccountColumn"
      :lg="ordersGrid.account.lg"
      class="my-1 my-lg-0"
    >
      <b-skeleton></b-skeleton>
    </b-col>
    <b-col :lg="ordersGrid.order.lg" class="my-1 my-lg-0">
      <b-skeleton></b-skeleton>
      <b-skeleton width="70%"></b-skeleton>
    </b-col>
    <b-col :lg="ordersGrid.date.lg" class="my-1 my-lg-0">
      <b-skeleton></b-skeleton>
    </b-col>
    <b-col :lg="ordersGrid.customer.lg" class="my-1 my-lg-0">
      <b-skeleton></b-skeleton>
      <b-skeleton width="80%"></b-skeleton>
    </b-col>
    <b-col
      :lg="ordersGrid.status.lg"
      v-if="ordersGrid.status"
      class="my-1 my-lg-0"
    >
      <b-skeleton></b-skeleton>
    </b-col>
    <b-col :lg="ordersGrid.action.lg" class="my-1 my-lg-0">
      <b-skeleton type="button" width="100%"></b-skeleton>
    </b-col>
  </b-row>
</template>

<script>
import OrderInListMixin from './OrderInListMixin';

export default {
  mixins: [OrderInListMixin],
};
</script>
