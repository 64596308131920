
import axios from 'axios';
import _ from 'lodash';
import { Component, Ref, Vue } from 'vue-property-decorator';
import Documents from './Documents.vue';
import { AccountStatusEnum } from '@gid/models';

interface Form {
  iban: string;
  bic: string;
  taxId: string;
  vatId: string;
}

enum CompanyTypeEnum {
  SOLE_PROPRIETOR = 'Einzelunternehmer',
  GBR = 'GbR',
}

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: { Documents: Documents },
})
export default class PaymentView extends Vue {
  @Ref('observer') readonly observer: any;
  context = 'partner';

  initialForm: Form = {
    iban: '',
    bic: '',
    taxId: '',
    vatId: '',
  };
  form: Form = {
    iban: '',
    bic: '',
    taxId: '',
    vatId: '',
  };
  disabled = {
    iban: false,
    bic: false,
    taxId: false,
    vatId: false,
  };
  errors: string[] = [];
  success = false;
  loading = false;
  prevStatus = '';
  status = '';

  async beforeRouteLeave(to, from, next) {
    const h = this.$createElement;

    const titleVNode = h('div', {
      domProps: { innerHTML: this.$t('qualifications.save-before-leaving') },
    });
    const messageVNode = h('div', { class: ['foobar'] }, [
      h('p', { class: ['text-center'] }, [
        `${this.$t('qualifications.leave-message')}`,
      ]),
    ]);
    if (!_.isEqual(this.initialForm, this.form)) {
      const result = await this.$bvModal.msgBoxConfirm([messageVNode], {
        title: [titleVNode],
        cancelTitle: `${this.$t('qualifications.cancel')}`,
        buttonSize: 'sm',
        centered: true,
        size: 'sm',
      });
      if (result) {
        this.onSubmit();
        next();
      }
    }
    next();
  }

  get dirtyForm() {
    return JSON.stringify(this.initialForm) != JSON.stringify(this.form);
  }

  get user() {
    return this.$store.state.user;
  }
  get accessToken() {
    return this.$store.state.auth.access_token;
  }
  get shouldRequireIdCard() {
    return (
      this.user?.account?.company_type == CompanyTypeEnum.SOLE_PROPRIETOR ||
      this.user?.account?.company_type == CompanyTypeEnum.GBR
    );
  }

  get isAccountActive() {
    return [
      AccountStatusEnum.ACTIVE,
      AccountStatusEnum.BEGINNER_ONBOARDING,
      AccountStatusEnum.REPEATED_ONBOARDING,
    ].includes(this.account.account_status);
  }

  get account() {
    return this.$store.state.auth.user.account;
  }

  get allInfoProvided() {
    return this.form.iban && this.form.bic && this.form.vatId;
  }

  async mounted() {
    this.form.iban = this.account?.iban;
    this.initialForm.iban = this.account?.iban;
    this.disabled.iban = Boolean(this.form.iban) || false;
    this.form.bic = this.account?.bic || '';
    this.initialForm.bic = this.account?.bic || '';
    this.disabled.bic = Boolean(this.form.bic) || false;
    this.form.taxId = this.account?.taxId || '';
    this.initialForm.taxId = this.account?.taxId || '';
    this.disabled.taxId = Boolean(this.form.taxId) || false;
    this.form.vatId = this.account?.vatId || '';
    this.initialForm.vatId = this.account?.vatId || '';
    this.disabled.vatId = Boolean(this.form.vatId) || false;
    this.success = false;
    this.prevStatus = this.account.account_status;
  }

  prepareChange(name) {
    this.disabled[name] = false;
  }
  async onSubmit() {
    if (
      !Object.values(this.observer.errors).reduce(
        (acc, curr: any) => acc + curr.length,
        0,
      )
    ) {
      this.loading = true;
      const { data } = await axios.patch(
        `/data-api/partner/${this.account.id}`,
        {
          ...this.form,
        },
      );

      this.success = true;
      this.loading = false;
      this.status = data.status;
      this.disabled.iban = Boolean(this.form.iban) || false;
      this.disabled.bic = Boolean(this.form.bic) || false;
      this.disabled.taxId = Boolean(this.form.taxId) || false;
      this.disabled.vatId = Boolean(this.form.vatId) || false;
      this.initialForm = this.form;
    }
  }
}
