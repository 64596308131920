<template>
  <div ref="list">
    <div
      class="alert alert-danger alert-dismissible fade show"
      role="alert"
      v-if="errorMessages.length"
    >
      <ul>
        <li v-for="(message, index) in errorMessages" :key="index">
          <span v-html="message"></span>
        </li>
      </ul>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div
      class="alert alert-warning alert-dismissible fade show"
      role="alert"
      v-if="warningMessages.length"
    >
      <ul>
        <li v-for="(message, index) in warningMessages" :key="index">
          <span v-html="message"></span>
        </li>
      </ul>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div
      class="alert alert-success alert-dismissible fade show"
      role="alert"
      v-if="successMessages.length"
    >
      <ul>
        <li v-for="(message, index) in successMessages" :key="index">
          <span v-html="message"></span>
        </li>
      </ul>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'messages-list',
  props: {
    id: {
      type: String,
      required: false,
    },
    errorMessages: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    warningMessages: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    successMessages: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  watch: {
    errorMessages() {
      this.scrollIntoView();
    },
    warningMessages() {
      this.scrollIntoView();
    },
    successMessages() {
      this.scrollIntoView();
    },
  },
  methods: {
    scrollIntoView() {
      this.$refs.list.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth',
      });
    },
  },
};
</script>
