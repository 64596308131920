import Vue from 'vue';
import Router from 'vue-router';
import AuthWrapper from '@/views/auth/Wrapper.vue';
import Badge from '@/views/Badge.vue';
import Login from '@/views/auth/Login.vue';
import NotFound from '@/views/NotFound.vue';
import PotentialOffer from '@/views/PotentialOffer.vue';
import CancelOfferSubscription from '@/views/CancelOfferSubscription.vue';
import Offer from '@/views/offers/Offer.vue';
import Offers from '@/views/offers/Offers.vue';
import Order from '@/views/orders/Order.vue';
import OrderAccess from '@/views/orders/OrderAccess';
import OrderComplete from '@/views/orders/OrderComplete.vue';
import OrderCustomerSignature from '@/views/orders/OrderCustomerSignature.vue';
import Orders from '@/views/orders/Orders.vue';
import Signup from '@/views/auth/Signup.vue';
import Success from '@/views/auth/Success.vue';
import JobCreate from '@gid/vue-common/components/JobFollowup.vue';
import InvoicesList from '@/views/InvoicesList.vue';
import RequestPasswordReset from '@gid/vue-common/views/RequestPasswordReset.vue';
import Qualifications from '@/views/company/Qualifications.vue';
import Company from '@/views/company/Company.vue';
import Home from '@/views/company/Home.vue';
import Payment from '@/views/company/Payment.vue';
import ChangePassword from '@gid/vue-common/views/ChangePassword.vue';
import MetabaseEmbed from '@gid/vue-common/components/MetabaseEmbed';
import { AccountStatusEnum } from '@gid/models';
Vue.use(Router);

/* Routes definition specification
 *
 * For each route access is defined via the meta setting
 * `auth`, which could contain `contactProfiles`, `accountProfiles` and `accountStatuses`.
 * If `auth` is present, the user must be authenticated.
 * `contactProfiles` recognizes the following values:
 * - true: requires authenticated user without contact profile restrictions
 * - array of strings: requires authenticated with one of the specified contact profiles
 */

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/offers/',
    },
    {
      path: '/login/',
      pathToRegexpOptions: { strict: true },
      meta: { layout: 'no-auth', title: { i18nKey: 'login' } },
      component: AuthWrapper,
      children: [
        { path: '', name: 'login', component: Login },
        { path: 'success/', name: 'login-success', component: Success },
      ],
    },
    {
      path: '/signup/',
      meta: { layout: 'no-auth', title: { i18nKey: 'signup' } },
      component: AuthWrapper,
      children: [{ path: '', component: Signup }],
    },
    {
      path: '/request-password-reset/',
      meta: { layout: 'no-auth', title: { i18nKey: 'password-reset.title' } },
      component: RequestPasswordReset,
      props: {
        role: 'partner',
      },
    },
    {
      path: '/change-password/',
      name: 'change-password',
      meta: { layout: 'no-auth', title: { i18nKey: 'change-password.title' } },
      component: ChangePassword,
      props: {
        role: 'partner',
      },
    },
    {
      path: '/potential-offer/:job_id/',
      name: 'potential-offer',
      meta: {
        layout: 'no-auth',
        title: { i18nKey: 'potential-offer.title' },
      },
      component: PotentialOffer,
      props: true,
    },
    {
      path: '/cancel-offer-subscription/',
      name: 'cancel-offer-subscription',
      meta: {
        layout: 'no-auth',
        title: { i18nKey: 'cancel-offer-subscription.title' },
      },
      component: CancelOfferSubscription,
    },
    {
      path: '/offers/',
      name: 'offers',
      meta: {
        title: { i18nKey: 'new-offers' },
        auth: {
          contactProfiles: ['admin', 'disposition'],
        },
      },
      component: Offers,
      props: true,
    },
    {
      path: '/offers/:job_id/',
      name: 'offer',
      meta: {
        auth: {
          contactProfiles: ['admin', 'disposition'],
        },
      },
      component: Offer,
      props: true,
    },
    {
      path: '/orders/',
      name: 'orders-home',
      redirect: '/orders/open/',
    },
    {
      path: '/orders/completed/',
      name: 'orders-completed',
      meta: {
        auth: {
          contactProfiles: ['admin', 'accounting', 'disposition', 'installer'],
          accountStatuses: [
            AccountStatusEnum.ACTIVE,
            AccountStatusEnum.BEGINNER_ONBOARDING,
            AccountStatusEnum.REPEATED_ONBOARDING,
          ],
        },
      },
      component: Orders,
      props: { type: 'completed' },
    },
    {
      path: '/orders/waiting/',
      name: 'orders-waiting',
      meta: {
        auth: {
          contactProfiles: ['admin', 'disposition'],
          accountStatuses: [
            AccountStatusEnum.ACTIVE,
            AccountStatusEnum.BEGINNER_ONBOARDING,
            AccountStatusEnum.REPEATED_ONBOARDING,
          ],
        },
      },
      component: Orders,
      props: { type: 'waiting' },
    },
    {
      path: '/orders/waiting-action/',
      name: 'orders-waiting-action',
      meta: {
        auth: {
          contactProfiles: ['admin', 'disposition'],
          accountStatuses: [
            AccountStatusEnum.ACTIVE,
            AccountStatusEnum.BEGINNER_ONBOARDING,
            AccountStatusEnum.REPEATED_ONBOARDING,
          ],
        },
      },
      component: Orders,
      props: { type: 'waiting-action' },
    },
    {
      path: '/orders/:type(open|scheduled|at-work)/',
      name: 'orders',
      meta: {
        auth: {
          contactProfiles: ['admin', 'disposition', 'installer'],
          accountStatuses: [
            AccountStatusEnum.ACTIVE,
            AccountStatusEnum.BEGINNER_ONBOARDING,
            AccountStatusEnum.REPEATED_ONBOARDING,
          ],
        },
      },
      component: Orders,
      props: true,
    },
    {
      path: '/orders/final-confirmation/',
      name: 'orders-final-confirmation',
      meta: {
        auth: {
          contactProfiles: ['admin'],
          accountStatuses: [
            AccountStatusEnum.ACTIVE,
            AccountStatusEnum.BEGINNER_ONBOARDING,
            AccountStatusEnum.REPEATED_ONBOARDING,
          ],
        },
      },
      component: Orders,
      props: { type: 'final-confirmation' },
    },
    {
      path: '/orders/customer-signature/',
      name: 'orders-customer-signature',
      meta: {
        auth: {
          contactProfiles: ['admin', 'disposition', 'installer'],
          accountStatuses: [
            AccountStatusEnum.ACTIVE,
            AccountStatusEnum.BEGINNER_ONBOARDING,
            AccountStatusEnum.REPEATED_ONBOARDING,
          ],
        },
      },
      component: Orders,
      props: { type: 'customer-signature' },
    },
    {
      path: '/orders/final-confirmation/:job_id/',
      name: 'order-final-confirmation',
      meta: {
        auth: {
          contactProfiles: ['admin'],
          accountStatuses: [
            AccountStatusEnum.ACTIVE,
            AccountStatusEnum.BEGINNER_ONBOARDING,
            AccountStatusEnum.REPEATED_ONBOARDING,
          ],
        },
      },
      component: Order,
      props: true,
    },
    {
      path: '/orders/customer-signature/:job_id/',
      name: 'order-customer-signature',
      meta: {
        auth: {
          contactProfiles: ['admin', 'disposition', 'installer'],
          accountStatuses: [
            AccountStatusEnum.ACTIVE,
            AccountStatusEnum.BEGINNER_ONBOARDING,
            AccountStatusEnum.REPEATED_ONBOARDING,
          ],
        },
      },
      component: OrderCustomerSignature,
      props: true,
    },
    {
      path: '/orders/:type(waiting|waiting-action|open|scheduled|at-work)/:job_id/',
      name: 'order',
      meta: {
        auth: {
          contactProfiles: ['admin', 'installer'],
          accountStatuses: [
            AccountStatusEnum.ACTIVE,
            AccountStatusEnum.BEGINNER_ONBOARDING,
            AccountStatusEnum.REPEATED_ONBOARDING,
          ],
        },
      },
      component: Order,
      props: true,
    },
    {
      path: '/order/:job_id/create',
      name: 'create',
      meta: {
        auth: {
          contactProfiles: ['admin'],
          accountStatuses: [
            AccountStatusEnum.ACTIVE,
            AccountStatusEnum.BEGINNER_ONBOARDING,
            AccountStatusEnum.REPEATED_ONBOARDING,
          ],
        },
      },
      component: JobCreate,
      props: (route) => ({ job_id: route.params.job_id, context: 'partner' }),
    },
    {
      path: '/order/:job_id/',
      redirect: '/order-single/:job_id/',
    },
    {
      path: '/order-single/:job_id/',
      name: 'order-single',
      meta: {
        auth: {
          contactProfiles: ['admin', 'installer'],
          accountStatuses: [
            AccountStatusEnum.ACTIVE,
            AccountStatusEnum.BEGINNER_ONBOARDING,
            AccountStatusEnum.REPEATED_ONBOARDING,
          ],
        },
      },
      component: Order,
      props: true,
    },
    {
      path: '/orders/completed/:job_id/',
      name: 'order-complete',
      meta: {
        auth: {
          contactProfiles: ['admin', 'accounting', 'disposition', 'installer'],
          accountStatuses: [
            AccountStatusEnum.ACTIVE,
            AccountStatusEnum.BEGINNER_ONBOARDING,
            AccountStatusEnum.REPEATED_ONBOARDING,
          ],
        },
      },
      component: OrderComplete,
      props: true,
    },
    {
      path: '/order-access/:id',
      name: 'order-access',
      meta: {
        layout: 'no-auth',
      },
      component: OrderAccess,
      props: true,
    },
    {
      path: '/invoices',
      name: 'invoices',
      meta: {
        title: { i18nKey: 'invoices.name' },
        auth: {
          contactProfiles: ['admin', 'accounting'],
          accountStatuses: [
            AccountStatusEnum.ACTIVE,
            AccountStatusEnum.BEGINNER_ONBOARDING,
            AccountStatusEnum.REPEATED_ONBOARDING,
          ],
        },
      },
      component: InvoicesList,
      props: true,
    },
    {
      path: '/company',
      name: 'company',
      redirect: { name: 'company-home' },
      component: Company,
      meta: {
        title: { i18nKey: 'company-page.company' },
        auth: {
          contactProfiles: ['admin', 'company owner'],
        },
      },
      children: [
        {
          path: '',
          name: 'company-home',
          component: Home,
          meta: {
            title: { i18nKey: 'company-page.company' },
            auth: {
              contactProfiles: ['admin', 'company owner'],
            },
          },
        },
        {
          path: 'qualifications',
          name: 'qualifications',
          component: Qualifications,
          meta: {
            title: { i18nKey: 'qualifications.title' },
            auth: {
              contactProfiles: ['admin', 'company owner'],
            },
          },
        },
        {
          path: 'payment',
          name: 'payment',
          component: Payment,
          meta: {
            title: { i18nKey: 'company-page.payment.title' },
            auth: {
              contactProfiles: ['admin', 'company owner'],
            },
          },
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          component: MetabaseEmbed,
          props: { dashboard: 3 },
          meta: {
            auth: {
              contactProfiles: ['admin', 'company owner', 'accounting'],
            },
            title: { i18nKey: 'dashboard' },
          },
        },
        {
          path: 'gid-badge',
          name: 'gid-badge',
          meta: {
            auth: {
              contactProfiles: ['admin', 'company owner'],
            },
            title: { i18nKey: 'company-page.gid-badge' },
          },
          component: Badge,
        },
      ],
    },
    {
      path: '*',
      meta: { layout: 'no-auth' },
      component: NotFound,
    },
  ],
});

export function initRouter(router, i18n, store) {
  router.beforeEach((to, from, next) => {
    const matchedRoutes = to.matched.slice(0).reverse();
    const allowGuests = matchedRoutes.some((route) => !route.meta?.auth);
    let titleMeta = matchedRoutes.find((route) => route.meta.title);
    if (titleMeta) {
      const title = titleMeta.meta.title;
      store.commit('title', title.i18nKey ? i18n.t(title.i18nKey) : title);
    } else {
      store.commit('title', null);
    }

    if (!store.getters.isAuthenticated && to.name != 'login' && !allowGuests) {
      next({ name: 'login' });
    } else {
      next();
    }
  });
}
