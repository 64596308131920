
import axios from 'axios';
import _ from 'lodash';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { PartnerCompanyDocs, PartnerCompanyDocTypesEnum } from '@gid/models';
import { BIconPenFill, BIconTrashFill, BIconX } from 'bootstrap-vue';
//@ts-ignore
import FilesSelector from '@gid/vue-common/components/inputs/FilesSelector.vue';
import { mapGetters } from 'vuex';
import CompanyDocsModule from '@/store/company-docs.module';
import { handleFetchError } from '@gid/vue-common/utils';

type InputFile = {
  data: string;
  mimetype: string;
  name: string;
  size: number;
};

@Component({
  components: {
    BIconPenFill,
    BIconTrashFill,
    BIconX,
    'files-selector': FilesSelector,
  },
  computed: mapGetters(['locale']),
})
export default class DocumentsView extends Vue {
  @Ref('observer') readonly observer: any;

  tableFields = [
    {
      key: 'path',
      label: this.$t('company-page.documents.name'),
    },
    {
      key: 'type',
      label: this.$t('company-page.documents.document-type'),
      sortable: true,
      formatter: (value) => this.$t(`company-page.documents.types.${value}`),
    },
    {
      key: 'lifetimeStart',
      label: this.$t('company-page.documents.lifetime-start'),
      sortable: true,
      formatter: (value) =>
        value
          ? //@ts-ignore
            this.$moment(value).format('MMMM Do YYYY')
          : this.$t('company-page.documents.no-start-date'),
    },
    {
      key: 'lifetimeEnd',
      label: this.$t('company-page.documents.lifetime-end'),
      sortable: true,
      formatter: (value) =>
        value
          ? //@ts-ignore
            this.$moment(value).format('MMMM Do YYYY')
          : this.$t('company-page.documents.no-expiration-date'),
    },
    {
      key: 'actions',
      label: this.$t('company-page.documents.actions'),
    },
  ];
  loading = false;
  modalShow = false;
  modalMode: 'create' | 'update' | 'delete' = 'create';
  form: {
    id: string | null;
    name: string | null;
    type: PartnerCompanyDocTypesEnum | null;
    lifetimeStart: Date | null;
    lifetimeEnd: Date | null;
    files: InputFile[] | null;
    confirmedAt: Date | null;
  } = {
    id: null,
    name: null,
    type: null,
    lifetimeStart: null,
    lifetimeEnd: null,
    files: null,
    confirmedAt: null,
  };
  docOptions = [
    { value: null, text: this.$t('no-selection') },
    ...Object.values(PartnerCompanyDocTypesEnum).map((type) => ({
      value: type,
      text: this.$t(`company-page.documents.types.${type}`),
    })),
  ];

  get companyDocsStore() {
    return getModule(CompanyDocsModule, this.$store);
  }

  get user() {
    return this.$store.state.auth.user;
  }

  get modalOkTitle() {
    return this.modalMode === 'create'
      ? this.$t('company-page.documents.add-document')
      : this.$t('company-page.documents.update-document', {
          document: this.form.name,
        });
  }

  get modalTitle() {
    return this.modalMode === 'create'
      ? this.$t('company-page.documents.add-document')
      : this.$t('company-page.documents.update-document', {
          document: this.$t('company-page.documents.document'),
        });
  }

  get isDateRequired() {
    if (!this.form.type) {
      return false;
    }
    return [PartnerCompanyDocTypesEnum.TAX_EXEMPTION_ORDER].includes(
      this.form.type,
    );
  }

  get formattedDocs() {
    return this.docs.map((doc) => {
      let _rowVariant: string | null = null;
      if (
        (doc.lifetimeStart && new Date(doc.lifetimeStart) > new Date()) ||
        (doc.lifetimeEnd && new Date(doc.lifetimeEnd) < new Date()) ||
        doc.confirmedAt === null
      ) {
        _rowVariant = 'danger';
      }
      return {
        ...doc,
        _rowVariant,
      };
    });
  }

  get errors() {
    return this.companyDocsStore.missingDocumentErrors.map((error) =>
      this.$t(error.name, { type: this.$t(error.type) }),
    );
  }

  get docs() {
    return this.companyDocsStore.allItems;
  }

  async reloadDocs() {
    this.companyDocsStore.ITEMS_RELOAD();
  }

  async handleModalOk(e) {
    if (this.modalMode === 'create') {
      await this.createDoc();
    } else {
      await this.updateDoc();
    }
  }

  prepareModal(
    mode: 'create' | 'update' | 'delete',
    target?: { id: string; name: string | null },
  ) {
    this.modalMode = mode;
    let doc: PartnerCompanyDocs | null = null;
    if (target) {
      doc = this.docs.find((d) => d.id === target.id) || null;
    }
    switch (mode) {
      case 'create': {
        this.resetForm();
        this.modalShow = true;
        break;
      }
      case 'update': {
        if (!doc) {
          return;
        }
        this.form = {
          id: doc.id,
          name: target?.name || null,
          type: doc.type,
          lifetimeStart: doc.lifetimeStart,
          lifetimeEnd: doc.lifetimeEnd,
          confirmedAt: null,
          files: null,
        };
        this.modalShow = true;
        break;
      }
      case 'delete': {
        if (!doc) {
          return;
        }
        this.form = {
          id: doc.id,
          name: target?.name || null,
          type: doc.type,
          lifetimeStart: doc.lifetimeStart,
          lifetimeEnd: doc.lifetimeEnd,
          confirmedAt: null,
          files: null,
        };
        this.deleteDoc();
        break;
      }
    }
  }

  resetForm() {
    this.form = {
      id: null,
      name: null,
      type: null,
      lifetimeStart: null,
      lifetimeEnd: null,
      confirmedAt: null,
      files: null,
    };

    this.observer.reset();
  }

  getValidationState({ dirty, validated, valid = null }) {
    return dirty || validated ? valid : null;
  }

  async createDoc() {
    this.loading = true;
    try {
      const body = {
        lifetimeStart: this.form.lifetimeStart,
        lifetimeEnd: this.form.lifetimeEnd,
        type: this.form.type,
        confirmedAt: this.form.confirmedAt,
        account: this.user.account,
        path: {
          name: this.form.files?.[0].name,
          mimeType: this.form.files?.[0].mimetype,
          size: this.form.files?.[0].size,
          data: this.form.files?.[0].data,
        },
      };
      await axios.post(`/data-api/partner-company-docs/`, body);
      this.modalShow = false;
      this.$bvToast.toast(
        this.$t('company-page.documents.success.add') as string,
        {
          title: this.$t('company-page.documents.success.title') as string,
          variant: 'success',
          solid: true,
        },
      );
      await this.reloadDocs();
    } catch (error) {
      this.$bvToast.toast(
        handleFetchError(error) || (this.$t('_errors.general.title') as string),
        {
          title: this.$t('_errors.general.title') as string,
          variant: 'danger',
          solid: true,
        },
      );
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  async updateDoc() {
    this.loading = true;
    try {
      const body = {
        lifetimeStart: this.form.lifetimeStart,
        lifetimeEnd: this.form.lifetimeEnd,
        confirmedAt: this.form.confirmedAt,
        type: this.form.type,
        id: this.form.id,
      };
      await axios.patch(`/data-api/partner-company-docs/${this.form.id}`, body);
      this.modalShow = false;
      this.$bvToast.toast(
        this.$t('company-page.documents.success.update') as string,
        {
          title: this.$t('company-page.documents.success.title') as string,
          variant: 'success',
          solid: true,
        },
      );
      await this.reloadDocs();
    } catch (error) {
      this.$bvToast.toast(
        handleFetchError(error) || (this.$t('_errors.general.title') as string),
        {
          title: this.$t('_errors.general.title') as string,
          variant: 'danger',
          solid: true,
        },
      );
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  async deleteDoc() {
    this.loading = true;
    try {
      const doc = this.docs.find((doc) => doc.id === this.form.id);
      if (!doc) {
        throw new Error(
          this.$t('company-page.documents.error.not-found') as string,
        );
      }
      const confirm = await this.$bvModal.msgBoxConfirm(
        this.$t('company-page.documents.prompt.delete.description', {
          document: this.form.name,
          type: this.$t(`company-page.documents.types.${doc.type}`),
        }) as string,
        {
          title: this.$t('company-page.documents.prompt.delete.title', {
            document: this.form.name,
          }) as string,
          okVariant: 'danger',
          okTitle: this.$t(
            'company-page.documents.prompt.delete.confirm',
          ) as string,
          cancelTitle: this.$t('cancel') as string,
          centered: true,
          hideHeaderClose: false,
        },
      );
      if (!confirm) {
        return;
      }
      await axios.delete(`/data-api/partner-company-docs/${this.form.id}`);
      this.$bvToast.toast(
        this.$t('company-page.documents.success.delete') as string,
        {
          title: this.$t('company-page.documents.success.title') as string,
          variant: 'success',
          solid: true,
        },
      );
      await this.reloadDocs();
    } catch (error) {
      this.$bvToast.toast(
        handleFetchError(error) || (this.$t('_errors.general.title') as string),
        {
          title: this.$t('_errors.general.title') as string,
          variant: 'danger',
          solid: true,
        },
      );
      console.error(error);
    } finally {
      this.loading = false;
      this.resetForm();
    }
  }
}
