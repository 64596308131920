<template>
  <div>
    <b-modal
      ref="bv-modal-account-activation"
      :header-text-variant="'primary'"
      :body-text-variant="'black'"
      :title="$t('company-page.payment.activation')"
      @hide.prevent
      hide-footer
      hide-header-close
    >
      <div class="d-block text-center">
        <h4
          v-if="!user.account.iban || !user.account.bic || !user.account.vatId"
        >
          {{ $t('company-page.payment.success.missing-info') }}
        </h4>
        <p v-if="!user.account.iban">
          {{ $t('company-page.payment.inputs.enter-iban') }}
        </p>
        <p v-if="!user.account.bic">
          {{ $t('company-page.payment.inputs.enter-bic') }}
        </p>
        <p v-if="!user.account.vatId">
          {{ $t('company-page.payment.inputs.enter-taxid') }}
        </p>
        <router-link to="/company/payment" class="my-5 position-relative">
          <b-button variant="primary" class="text-white position-relative">
            {{ $t('company-page.payment.complete') }}
          </b-button>
        </router-link>
      </div>
    </b-modal>
    <div
      v-if="user"
      class="my-2 float-left"
      v-html="
        $t('we-would-like-to-offer', {
          intro: $t(getSalutationIntro(user.contact.salutation)),
          name: fullName,
        })
      "
    ></div>
    <div class="sticky-top float-right pt-2 pl-2 gid-results-count__wrapper">
      <div class="rounded-pill gid-results-count">
        <span :class="{ 'gid-results-count--loading': loading }">
          <template v-if="totalRows !== null">
            {{ $t('results') }}: {{ offers.length }} / {{ totalRows || 0 }}
          </template>
          <BIconThreeDots v-else />
        </span>
      </div>
    </div>
    <div class="clearfix"></div>

    <messages-list
      v-if="shouldDisplayMessages"
      :error-messages="errorsList"
      :success-messages="successList"
    />
    <b-container fluid class="border" ref="wrapper">
      <b-row class="py-4 d-none d-lg-flex border-bottom text-muted">
        <b-col>{{ $t('order-price-place') }}</b-col>
        <b-col>{{ $t('possible-appointments') }}</b-col>
        <b-col>{{ $t('action') }}</b-col>
      </b-row>
      <div v-if="!loading && offers.length == 0">
        <p class="text-muted text-center m-5">{{ $t('offers-list-empty') }}</p>
      </div>
      <offer-in-list
        v-else
        v-for="(offer, index) in offers"
        :offer="offer"
        :key="index"
        @accept="acceptOffer"
      />

      <template v-if="loading">
        <b-row
          v-for="i in loading"
          :key="`skeleton-${i}`"
          :class="['py-4', { 'border-bottom': i != loading }]"
        >
          <b-col cols="12" lg="4" class="my-2 my-lg-0">
            <b-skeleton></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
          </b-col>
          <b-col cols="12" lg="4" class="my-2 my-lg-0">
            <b-skeleton></b-skeleton>
            <b-skeleton width="50%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
          </b-col>
          <b-col cols="12" lg="4" class="my-2 my-lg-0">
            <b-skeleton type="button" width="100%"></b-skeleton>
          </b-col>
        </b-row>
      </template>
    </b-container>

    <div class="text-primary my-5" v-if="offers.length">
      <i>
        <b>{{ $t('note') }}:</b>
        {{ $t('offers-list-note') }}
      </i>
    </div>
    <additional-offers ref="additionalOffers" @accept="acceptOffer" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { BIconThreeDots } from 'bootstrap-vue';
import { getSalutationIntro } from '@/app/utils';

import AcceptMixin from './AcceptMixin';
import AdditionalOffers from './AdditionalOffers.vue';
import OfferInList from './OfferInList.vue';
import { COUNTS_SET_SINGLE } from '@/store/sidebar.module';
import InfiniteScrollMixin from '@gid/vue-common/components/mixins/InfiniteScrollMixin';
import Api from '@gid/vue-common/api';
import { AccountStatusEnum } from '@gid/models';

export default {
  name: 'offers',
  mixins: [AcceptMixin, InfiniteScrollMixin],
  components: {
    AdditionalOffers,
    OfferInList,
    BIconThreeDots,
  },
  data() {
    return {
      offers: [],
      errorsList: [],
      successList: [],
      loading: 0,
      shouldDisplayMessages: false,
      perPage: 10,
      totalRows: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    fullName() {
      const { first_name, last_name } = this.user.contact;
      return first_name ? `${first_name} ${last_name}` : last_name;
    },
    accountActive() {
      return [
        AccountStatusEnum.ACTIVE,
        AccountStatusEnum.NEW,
        AccountStatusEnum.BEGINNER_ONBOARDING,
        AccountStatusEnum.REPEATED_ONBOARDING,
      ].includes(this.user.account.account_status);
    },
  },
  async created() {
    if (this.accountActive) {
      this.loadData();
    }
  },
  mounted() {
    if (this.accountNotActive) {
      this.$refs['bv-modal-account-activation'].show();
    }

    const routerSuccessMessage = this.$route?.params?.successMessage;
    if (routerSuccessMessage) {
      this.shouldDisplayMessages = true;
      this.successList.push(routerSuccessMessage);
    }
  },
  methods: {
    loadData(append = false) {
      if (!append) {
        this.totalRows = null;
        this.offers = [];
      }
      if (this.totalRows !== null && this.offers.length >= this.totalRows) {
        return;
      }
      this.loading = (this.totalRows ?? this.perPage) - this.offers.length;
      if (this.loading > this.perPage) {
        this.loading = this.perPage;
      }
      Api.post(
        `/api/partner/offers`,
        {
          page_size: this.perPage,
          page_number: 0,
          excluding: this.offers.map(({ api_id }) => api_id),
        },
        {
          autoCancelScope: this,
        },
      ).then((response) => {
        this.totalRows =
          this.offers.length + response.data.pagination.total_count;
        this.offers.push(...response.data.data);
        this.loading = 0;
        this.$store.commit(COUNTS_SET_SINGLE, {
          type: 'offers',
          count: this.totalRows,
        });
      });
    },
    getSalutationIntro,
  },
};
</script>
