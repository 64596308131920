
import axios from 'axios';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
  CreateQueryParams,
  SConditionAND,
  SFields,
} from '@dataui/crud-request';
import DatePicker from '@gid/vue-common/components/DatePicker.vue';
import CrudListPagination from '@gid/vue-common/components/crud-list/CrudListPagination.vue';
import CrudListTable from '@gid/vue-common/components/crud-list/CrudListTable.vue';
import CrudListInfiniteScroll from '@gid/vue-common/components/crud-list/CrudListInfiniteScroll.vue';
import CrudListErrorMessage from '@gid/vue-common/components/crud-list/CrudListErrorMessage.vue';

import {
  CreditNotesModule,
  CreditNotesActionsEnum,
} from '../store/credit-notes.module';

enum CreditNotesStatusEnum {
  PAID = 'paid',
  SENT = 'sent',
  RECEIVED = 'received',
  CANCELLED = 'cancelled',
  CANCELLATION_SENT = 'cancellation_sent',
}

@Component({
  components: {
    DatePicker,
    CrudListPagination,
    CrudListTable,
    CrudListInfiniteScroll,
    CrudListErrorMessage,
  },
})
export default class InvoicesList extends Vue {
  documentStatuses = CreditNotesStatusEnum;
  downloadStarted = false;
  downloadDisabled = false;

  created() {
    this.fetchCreditNotes();
  }

  downloadReport() {
    axios
      .get(`/api/partner/invoices/export`, {
        params: {
          from_date: this.store.filters.dateFrom,
          to_date: this.store.filters.dateTo,
          status: this.store.filters.status || undefined,
        },
      })
      .then((response) => {
        this.downloadStarted = true;
      });
  }

  get store(): CreditNotesModule {
    return getModule(CreditNotesModule, this.$store);
  }

  get filterStatusOptions() {
    return Object.values(CreditNotesStatusEnum);
  }

  get columns() {
    return [
      {
        label: this.$t('invoices.table.heading.issuing-date'),
        key: 'issueDate',
        sortable: true,
      },
      { label: this.$t('invoices.table.heading.status'), key: 'status' },
      {
        label: this.$t('invoices.table.heading.grand-total'),
        key: 'totalAmount',
        sortable: true,
      },
      {
        label: this.$t('invoices.table.heading.download'),
        key: 'download',
      },
    ];
  }

  @Watch('store.filters', {
    deep: true,
  })
  searchChange(value) {
    this.fetchCreditNotes();
  }

  buildSearch() {
    const search: Array<SFields | SConditionAND> = [];

    if (this.store.filters.dateFrom) {
      search.push({
        issueDate: {
          $gte: this.store.filters.dateFrom,
        },
      });
    }
    if (this.store.filters.dateTo) {
      search.push({
        issueDate: {
          $lte: this.store.filters.dateTo,
        },
      });
    }
    if (this.store.filters.docNumber) {
      search.push({
        number: {
          $contL: this.store.filters.docNumber.trim(),
        },
      });
    }
    if (this.store.filters.status) {
      search.push({
        status: {
          $eq: this.store.filters.status,
        },
      });
    } else {
      search.push({
        status: {
          $in: this.filterStatusOptions,
        },
      });
    }
    if (this.store.filters.docType) {
      search.push({
        type: {
          $eq: 'credit_note',
        },
      });
    }

    if (this.store.filters.search) {
      search.push({
        $or: [
          {
            number: {
              $contL: this.store.filters.search,
            },
          },
          {
            'jobs.name': {
              $contL: this.store.filters.search,
            },
          },
          {
            outsideCommunicationNumber: {
              $contL: this.store.filters.search,
            },
          },
          {
            'target.name': {
              $contL: this.store.filters.search,
            },
          },
        ],
      });
    }

    return {
      search: {
        $and: search,
      },
    };
  }

  fetchCreditNotes() {
    const { search } = this.buildSearch();

    const query: CreateQueryParams = {
      page: this.store.pagination.currentPage,
      limit: this.store.pagination.perPage,
      sort: {
        field: this.store.pagination.sortBy,
        order: this.store.pagination.sortDir ? 'DESC' : 'ASC',
      },
    };
    this.store[CreditNotesActionsEnum.ITEMS_FETCH]({
      query,
      search,
    });
  }
}
