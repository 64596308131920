<template>
  <b-spinner v-if="loading" />
  <div v-else>
    <h2 class="my-3">{{ $t('qualifications.title') }}</h2>
    <b-table :fields="fields" :items="items" stacked="md">
      <template #cell(field)="{ item }">
        {{ $t(`qualifications.${item[0]}`) }}
      </template>
      <template #cell(qualification)="{ item }">
        <b-form-tags
          :placeholder="$t('qualifications.add-options-placeholder')"
          input-id="tags-separators"
          tag-variant="primary"
          v-model="model[item[0]]"
          separator=" ,;"
          class="mb-2"
          v-if="item[0] === 'unlisted_qualifications'"
        ></b-form-tags>
        <multiselect
          :placeholder="$t('qualifications.placeholder')"
          v-else
          v-model="model[item[0]]"
          :multiple="true"
          :disabled="item[0] == 'weiterbildung'"
          :options="item[1]"
        />
      </template>
    </b-table>
    <div v-if="formDirty">
      <p>{{ $t('qualifications.confirm-qualifications') }}</p>
      <button
        type="button"
        class="btn btn-primary"
        @click="saveQualifications()"
      >
        <i class="material-icons">save</i>
        {{ $t('qualifications.save-qualifications') }}
      </button>
    </div>
    <b-alert :show="success" variant="success" dismissible>
      <p>{{ $t('qualifications.success') }}</p>
      <b-button variant="secondary" to="/offers/">
        {{ $t('qualifications.new-offers') }}
      </b-button>
    </b-alert>
    <b-alert :show="error.occured" variant="danger" dismissible>
      {{ error.message }}
    </b-alert>
    <b-modal v-model="showModal">
      <h4>Do you want to save the changes?</h4>
      <button
        type="button"
        class="btn btn-secondary"
        @click="showModal = false"
      >
        {{ $t('qualifications.cancel') }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click="saveQualifications()"
      >
        <i class="material-icons">save</i>
        {{ $t('qualifications.save') }}
      </button>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import _ from 'lodash';
export default {
  components: { Multiselect },
  props: {
    nonSelectablePrefix: {
      type: String,
      default: 'internal',
    },
  },
  async beforeRouteLeave(to, from, next) {
    const h = this.$createElement;

    const titleVNode = h('div', {
      domProps: { innerHTML: this.$t('qualifications.save-before-leaving') },
    });
    const messageVNode = h('div', { class: ['foobar'] }, [
      h('p', { class: ['text-center'] }, [
        this.$t('qualifications.leave-message'),
      ]),
    ]);
    if (!_.isEqual(this.model, this.toSave)) {
      const result = await this.$bvModal.msgBoxConfirm([messageVNode], {
        title: [titleVNode],
        cancelTitle: [this.$t('qualifications.cancel')],
        buttonSize: 'sm',
        centered: true,
        size: 'sm',
      });
      if (result) {
        this.saveQualifications();
        next();
      }
    }
    next();
  },
  data() {
    return {
      fields: [
        { label: this.$t('qualifications.field'), key: 'field' },
        {
          label: this.$t('qualifications.qualification'),
          key: 'qualification',
        },
      ],
      items: [],
      model: {
        hwo_a: [],
        hwo_b1: [],
        hwo_b2: [],
        transporter: [],
        weiterbildung: [],
        zusatzqualifikation: [],
        kein_hwo: [],
        unlisted_qualifications: [],
      },
      toSave: {
        hwo_a: [],
        hwo_b1: [],
        hwo_b2: [],
        transporter: [],
        weiterbildung: [],
        zusatzqualifikation: [],
        kein_hwo: [],
        unlisted_qualifications: [],
      },
      showModal: false,
      loading: false,
      success: false,
      error: {
        occured: false,
        message: '',
      },
    };
  },
  computed: {
    ...mapGetters(['locale', 'user']),
    formDirty() {
      return JSON.stringify(this.model) != JSON.stringify(this.toSave);
    },
  },
  async created() {
    this.loading = true;
    await this.getAllOptions();
    await this.getUserQualifications();
  },
  methods: {
    preventSpacePress($event) {
      $event.preventDefault();
    },
    async saveQualifications() {
      const data = _.transform(this.model, (result, val, key) => {
        let transformedKey = key.toLowerCase();

        result[transformedKey] = val;
      });
      try {
        await axios.post('/api/partner/qualifications/', {
          ...data,
          id: this.user?.sfid,
        });
        this.toSave = { ...this.model };
        this.success = true;
      } catch (error) {
        this.error.occured = true;
        this.error.message = error.message;
      }
    },
    async getAllOptions() {
      const response = await axios.get(
        '/api/partner/qualifications/all-values/',
      );
      const tempRespObject = _.transform(response.data, (result, val, key) => {
        let transformedKey = key.toLowerCase();
        if (transformedKey == 'nicht_hwo') transformedKey = 'kein_hwo';
        if (transformedKey == 'zertifikat_zusatzqualifikation') {
          transformedKey = 'zusatzqualifikation';
          result[transformedKey] = val.filter(
            (entry) =>
              !entry.toLowerCase().startsWith(this.nonSelectablePrefix),
          );
        } else {
          result[transformedKey] = val;
        }
      });
      this.items = [
        ...Object.entries(tempRespObject),
        ['unlisted_qualifications', []],
      ];
    },
    async getUserQualifications() {
      const response = await axios.get(`/api/partner/current-qualifications`);
      for (let key in this.model) {
        this.model[key] = [...response.data[key]];
        this.toSave[key] = [...response.data[key]];
      }
      if (this.toSave['unlisted_qualifications'][0] == '')
        this.toSave['unlisted_qualifications'].pop();
      this.loading = false;
    },
  },
};
</script>
