import Vue from 'vue';
import VueI18 from 'vue-i18n';
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from 'vee-validate';
import {
  required,
  email,
  confirmed,
  min,
  max,
  regex,
  alpha,
  alpha_spaces,
  integer,
} from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import de from 'vee-validate/dist/locale/de.json';
import i18nDE from '@/i18n/de.json';
import i18nEN from '@/i18n/en.json';

import * as ibantools from 'ibantools';

const mergedDE = {
  code: de.code,
  messages: {
    ...de.messages,
    ...i18nDE['vee-validate-error-messages'],
  },
};
const mergedEN = {
  code: en.code,
  messages: {
    ...en.messages,
    ...i18nEN['vee-validate-error-messages'],
  },
};
// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// Add the required rule
extend('required', required);
extend('email', email);
extend('confirmed', confirmed);
extend('min', min);
extend('max', max);
extend('regex', regex);
extend('password_regex', {
  validate: (value) =>
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{6,}$/.test(value),
});
extend('phone_regex', {
  validate: (value) =>
    /^(?:\+49\s?|0049\s?|0)(?:\s?[1-9][0-9\s]{8,})[0-9]$/.test(value),
});
extend('integer', integer);
extend('alpha', alpha);
extend('alpha_spaces', alpha_spaces);
extend('iban', { validate: (value) => ibantools.isValidIBAN(value) });
extend('bic', { validate: (value) => ibantools.isValidBIC(value) });
// Custom rules
export function initVeeValidateRulesWithI18n(i18n: VueI18) {
  extend('phone', {
    message: i18n.t('validation.phone') as string,
    validate: (value) => /^[0-9][0-9 +-/()]+$/.test(value),
  });
}

export function setVeeValidateLocale(code: string): void {
  if (code == 'de') {
    localize('de', mergedDE);
  } else {
    localize('en', mergedEN);
  }
}
