import axios from 'axios';
import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import BootstrapVue from 'bootstrap-vue';
import { stringify } from 'qs';
export {
  initVeeValidateRulesWithI18n,
  setVeeValidateLocale,
} from './vee-validate';

import MessagesList from '@/components/MessagesList.vue';
import ValidatingGroup from '@gid/vue-common/components/ValidatingGroup';
import AddToHomeScreen from '@gid/vue-common/components/AddToHomeScreen';
import Offline from '@gid/vue-common/components/Offline';
import BackButton from '@gid/vue-common/components/BackButton';
import RefreshButton from '@gid/vue-common/components/RefreshButton';

Vue.use(BootstrapVue);

Vue.component('messages-list', MessagesList);
Vue.component('validating-group', ValidatingGroup);
Vue.component('multiselect', Multiselect);
Vue.component('add-to-home-screen', AddToHomeScreen);
Vue.component('offline', Offline);
Vue.component('back-button', BackButton);
Vue.component('refresh-button', RefreshButton);

axios.defaults.paramsSerializer = (params) => {
  return stringify(params, {
    arrayFormat: 'repeat',
    skipNulls: true,
  });
};
